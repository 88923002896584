import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { useGetCompanyProductMutation } from "../../../Redux/Api/Admin/Company";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,
  clearCompany,
  resetCompany,
} from "../../../Redux/reducer/Filter";
import { RootState } from "../../../Redux/store";

interface Comapnies {
  basicRate: number;
  description: string;
  id: string;
  image: string;
  name: string;
  productCount: number;
  publish: boolean;
  srNo: number;
}

const Companies = () => {
  const [dispalycount, setDisplayCount] = useState<number>(7);
  const selectedCategories = useSelector((state: RootState) => state.selection.selectedCategories);
  const [mutation, { data: CompaniesResponse, isLoading }] = useGetCompanyProductMutation();
  const [companyLength, setCompanyLength] = useState(0);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    mutation({ category: [...selectedCategories] });
    if (selectedCategories?.length > 0) {
      dispatch(resetCompany());
    }
  }, [mutation, selectedCategories]);

  useEffect(() => {
    if (CompaniesResponse) {
      setCompanyLength(CompaniesResponse?.data?.length);
      setShowSeeMore(
        CompaniesResponse?.data?.length > dispalycount &&
        CompaniesResponse?.data?.length > 7
      );
    }
  }, [CompaniesResponse, dispalycount]);

  const [form, setForm] = useState({
    search: "",
    checkboxes: {} as { [key: string]: boolean },
  });

  const selectedCompanies = useSelector(
    (state: RootState) => state.selection.selectedCompanies
  );

  useEffect(() => {
    const updatedCheckboxes = selectedCompanies.reduce(
      (acc: any, category: any) => {
        acc[category] = true;
        return acc;
      },
      {}
    );
    setForm((prevFormData) => ({
      ...prevFormData,
      checkboxes: updatedCheckboxes,
    }));
  }, [selectedCompanies]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, companyId: string) => {
    const { checked } = e.target;

    if (checked) {
      dispatch(addCompany(companyId));
    } else {
      dispatch(clearCompany(companyId));
    }

    const updatedCheckboxes = { ...form.checkboxes };
    updatedCheckboxes[companyId] = checked;

    if (checked) {
      updatedCheckboxes[companyId] = true;
    } else {
      delete updatedCheckboxes[companyId];
    }
    const updatedFormData = { ...form, checkboxes: updatedCheckboxes };
    setForm(updatedFormData);
  };

  const filteredCompanies = CompaniesResponse?.data
    ? CompaniesResponse?.data?.filter((company: any) => {
      const regex = new RegExp(form.search, "i");
      return regex.test(company?.name);
    })
    : [];

  const clickedCompanies = filteredCompanies.filter((company: any) => form.checkboxes[company?.id]);
  const unClickedCompanies = filteredCompanies.filter((company: any) => !form.checkboxes[company?.id]);

  const allCompanies = [...clickedCompanies, ...unClickedCompanies];

  const handleMoreClick = () => {
    setDisplayCount((prevCount) => prevCount + 5);
  };

  const companiesToDisplay = allCompanies.slice(0, dispalycount);

  useEffect(() => {
    if (CompaniesResponse) {
      setShowSeeMore(
        companiesToDisplay?.length < companyLength &&
        CompaniesResponse?.data?.length > 7
      );
    }
  }, [companiesToDisplay?.length, companyLength]);



  return (
    <div className="font-inter">
      <div className="flex flex-col gap-[0.6rem]">
        <h1 className="font-[700] text-[14px] leading-[16px]">Companies</h1>

        <div className="flex border items-center bg-[#F8FAFC] mr-3 gap-1 pl-1 py-1 rounded-[7px]">
          <IoSearch className="text-[#9CA3AF] h-[22px] w-[22px] pl-[4px]" />
          <input
            type="text"
            name="search"
            placeholder="Search"
            value={form?.search}
            onChange={handleInputChange}
            className="w-full font-inter text-[#9CA3AF] text-sm font-[500] leading-6 bg-[#F8FAFC] border-none focus:outline-none focus:ring-2 focus:ring-[#D1D5DB] rounded-md"
            style={{ outline: "none", boxShadow: "none" }}
          />
        </div>

        <div className="pl-[8px]">
          <div className="font-[500] text-[14px] leading-[16px] flex flex-col gap-[5px]">
            {isLoading ? (
              "loading"
            ) : companiesToDisplay.length > 0 ? (
              companiesToDisplay.map((company: Comapnies, index: number) => (
                <div
                  key={company?.id}
                  className="font-[500] text-[14px] leading-[16px]"
                >
                  <input
                    type="checkbox"
                    id={`myCheckbox${index}`}
                    checked={form.checkboxes[company?.id] || false}
                    onChange={(e) => handleCheckboxChange(e, company?.id)}
                    className="mr-2"
                  />
                  {company?.name}
                </div>
              ))
            ) : (
              <div>No Companies Available</div>
            )}
          </div>
        </div>

        {showSeeMore && (
          <div
            className="flex items-center gap-[3px] cursor-pointer"
            onClick={handleMoreClick}
          >
            <IoIosArrowDown />
            <div className="text-[#007185] font-[500] text-[14px] leading-[16px] cursor-pointer">
              See More
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Companies;

// import React, { useEffect, useState } from "react";
// import { IoIosArrowDown } from "react-icons/io";
// import { IoSearch } from "react-icons/io5";
// import { useGetCompanyProductMutation } from "../../../Redux/Api/Admin/Company";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   addCompany,
//   clearCompany,
//   resetCompany,
// } from "../../../Redux/reducer/Filter";
// import { RootState } from "../../../Redux/store";

// interface Comapnies {
//   basicRate: number;
//   description: string;
//   id: string;
//   image: string;
//   name: string;
//   productCount: number;
//   publish: boolean;
//   srNo: number;
// }

// const Companies = () => {
//   const [dispalycount, setDisplayCount] = useState<number>(7);
//   const selectedCategories = useSelector((state: RootState) => state.selection.selectedCategories);
//   const [mutation, { data: CompaniesResponse, isLoading }] =  useGetCompanyProductMutation();
//   const [companyLength, setCompanyLength] = useState(0);
//   const [showSeeMore, setShowSeeMore] = useState(false);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     mutation({ category: [...selectedCategories] });
//     if (selectedCategories?.length > 0) {
//       dispatch(resetCompany());
//     }
//   }, [mutation, selectedCategories]);

//   useEffect(() => {
//     if (CompaniesResponse) {
//       setCompanyLength(CompaniesResponse?.data?.length);
//       setShowSeeMore(
//         CompaniesResponse?.data?.length > dispalycount &&
//           CompaniesResponse?.data?.length > 7
//       );
//     }
//   }, [CompaniesResponse, dispalycount]);

//   const [form, setForm] = useState({
//     search: "",
//     checkboxes: {} as { [key: string]: boolean },
//   });

//   const selectedCompanies = useSelector(
//     (state: RootState) => state.selection.selectedCompanies
//   );

//   useEffect(() => {
//     const updatedCheckboxes = selectedCompanies.reduce(
//       (acc: any, category: any) => {
//         acc[category] = true;
//         return acc;
//       },
//       {}
//     );
//     setForm((prevFormData) => ({
//       ...prevFormData,
//       checkboxes: updatedCheckboxes,
//     }));
//   }, [selectedCompanies]);

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setForm({ ...form, [name]: value });
//   };

//   const handleCheckboxChange = (
//     e: React.ChangeEvent<HTMLInputElement>,
//     companyId: string
//   ) => {
//     const { checked } = e.target;

//     if (checked) {
//       dispatch(addCompany(companyId)); // Dispatch action with the ID of the checked category
//     } else {
//       dispatch(clearCompany(companyId));
//     }

//     const updatedCheckboxes = { ...form.checkboxes };
//     updatedCheckboxes[companyId] = checked;

//     // Update the categoryLocation state when the categoryName checkbox is checked or unchecked
//     if (checked) {
//       updatedCheckboxes[companyId] = true;
//     } else {
//       delete updatedCheckboxes[companyId];
//     }
//     const updatedFormData = { ...form, checkboxes: updatedCheckboxes };
//     setForm(updatedFormData);
//   };

//   const filteredCompanies = CompaniesResponse?.data
//     ? CompaniesResponse?.data?.filter((company: any) => {
//         const regex = new RegExp(form.search, "i"); // 'i' flag for case-insensitive matching
//         return regex.test(company?.name);
//       })
//     : [];

//   const clickedCompanies = filteredCompanies.filter(
//     (company: any) => form.checkboxes[company?.id]
//   );
//   const unClickedCompanies = filteredCompanies.filter(
//     (company: any) => !form.checkboxes[company?.id]
//   );

//   const allCompanies = [...clickedCompanies, ...unClickedCompanies];

//   const handleMoreClick = () => {
//     setDisplayCount((prevCount) => prevCount + 5);
//   };

//   const companiesToDisplay = allCompanies.slice(0, dispalycount);

//   useEffect(() => {
//     if (CompaniesResponse) {
//       setShowSeeMore(
//         companiesToDisplay?.length < companyLength &&
//           CompaniesResponse?.data?.length > 7
//       );
//     }
//   }, [companiesToDisplay?.length, companyLength]);

//   // console.log(companiesToDisplay)

//   return (
//     <div className="font-inter">
//       <div className="flex flex-col gap-[0.6rem]">
//         <h1 className="font-[700] text-[14px] leading-[16px]">Companies</h1>

//         {/* Wrapper div with consistent styles */}
//         <div className="flex border items-center bg-[#F8FAFC] mr-3 gap-1 pl-1 py-1 rounded-[7px]">
//           <IoSearch className="text-[#9CA3AF] h-[22px] w-[22px] pl-[4px]" />
//           <input
//             type="text"
//             name="search"
//             placeholder="Search"
//             value={form?.search}
//             onChange={handleInputChange}
//             className="w-full font-inter text-[#9CA3AF] text-sm font-[500] leading-6 bg-[#F8FAFC] border-none focus:outline-none focus:ring-2 focus:ring-[#D1D5DB] rounded-md"
//             style={{ outline: "none", boxShadow: "none" }}
//           />
//         </div>

//         <div className="pl-[8px]">
//           <div className="font-[500] text-[14px] leading-[16px] flex flex-col gap-[5px]">
//             {isLoading ? (
//               "loading"
//             ) : companiesToDisplay.length > 0 ? (
//               companiesToDisplay.map((company: Comapnies, index: number) => (
//                 <div
//                   key={company?.id}
//                   className="font-[500] text-[14px] leading-[16px]"
//                 >
//                   <input
//                     type="checkbox"
//                     id={`myCheckbox${index}`}
//                     checked={form.checkboxes[company?.id] || false}
//                     onChange={(e) => handleCheckboxChange(e, company?.id)}
//                     className="mr-2"
//                   />
//                   {company?.name}
//                 </div>
//               ))
//             ) : (
//               <div>No Companies Available</div>
//             )}
//           </div>
//         </div>

//         {showSeeMore && (
//           <div
//             className="flex items-center gap-[3px] cursor-pointer"
//             onClick={handleMoreClick}
//           >
//             <IoIosArrowDown />
//             <div className="text-[#007185] font-[500] text-[14px] leading-[16px] cursor-pointer">
//               See More
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Companies;
