import { Accordion } from './Components/Accordian'
import { chawlaispat } from '../../Redux/apiRoutes'
import usePageMeta from '../../common/hooks/usePageMeta';

export const Faq = () => {
  const title = "FAQ | Building Material Supplier | Chawla Ispat";
  const description = "Find answers to frequently asked questions about Chawla Ispat's products, services, and policies on our FAQ page.";
  const canonicalUrl = chawlaispat;

  usePageMeta(title, description, canonicalUrl);
  return (
    <div className='flex flex-col my-2 md:my-5 px-4 md:px-8 '>
    

     <div className="py-[24px] px-[24px] md:py-8 md:px-8 flex flex-col sm:flex-row gap-6 sm:gap-0 sm:justify-between bg-[#F2F2F2] rounded-[12px]">
          <div className="font-[700] text-[24px] md:text-[32px] leading-[35px] md:leading-[50px] ">
            Looking For help? Here are Our most Frequently asked Questions.
          </div>
        </div>  

        <Accordion />

    </div>
  )
}
