import kamdhenu from "../../assets/certificate/kamdhenu1.png";
import indostar from "../../assets/certificate/indostar1.png";
import ambuja from "../../assets/certificate/ambuja1.png";
import everest from "../../assets/certificate/everest1.png";
import panther from "../../assets/certificate/panther1.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { useState } from "react";
import backgroundWall from "../../assets/backgroundwall.png";

export const Certificates = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const certificates = [
    { src: ambuja, alt: "Ambuja Certificate" },
    { src: everest, alt: "Everest Certificate" },
    { src: panther, alt: "Panther Certificate" },
    { src: indostar, alt: "Indostar Certificate" },
    { src: kamdhenu, alt: "Kamdhenu Certificate" },
  ];


  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? certificates.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === certificates.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div
      className="flex flex-col my-10 gap-1 sm:gap-[36px] certificates"
      style={{ backgroundImage: `url(${backgroundWall})` }}
    >
      <h1
        className="font-[700] text-[30px] sm:text-[48px] leading-[58px] text-center text-[#5097A4]"
        style={{
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        Our Certifications
      </h1>

      {/* Large screen display */}
      <div className="hidden sm:flex flex-wrap justify-around gap-[21px]">
        {certificates.map((cert, index) => (
          <div
            key={index}
            className="w-[346px] h-[205px] md:w-[396px] md:h-[205px] pb-5"
          >
            <img
              src={cert.src}
              alt={cert.alt}
              className="w-full h-full "
            />
          </div>
        ))}
      </div>

      {/* Small screen display with slider */}
      <div className="flex flex-col items-center sm:hidden">
        <section className="my-2">
          <div className="relative w-full  h-[300px] mx-auto">
            <div className="relative w-full">
              <img
                src={certificates[currentIndex].src}
                alt={certificates[currentIndex].alt}
                className="w-full h-[300px]  transition-transform duration-500 ease-in-out"
              />
            </div>

            <button
              onClick={prevSlide}
              className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-white shadow-md rounded-full text-gray-800 hover:text-[#5097a4]"
            >
              <GoArrowLeft className="w-6 h-6" />
            </button>


            <button
              onClick={nextSlide}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-white shadow-md rounded-full text-gray-800 hover:text-[#5097a4]"
            >
              <GoArrowRight className="w-6 h-6" />
            </button>


            <div className="flex justify-center mt-4 space-x-2">
              {certificates.map((_, index) => (
                <div
                  key={index}
                  className={`w-3 h-3 rounded-full ${currentIndex === index ? "bg-[#5097a4]" : "bg-gray-300"
                    }`}
                />
              ))}
            </div>
          </div>
        </section>
      </div>

    </div>
  );
};


// import kamdhenu from "../../assets/assets/kamdhenucer.png";
// import indostar from "../../assets/assets/indostarcer.png";
// import ambuja from "../../assets/assets/ambujacer.png";
// import everest from "../../assets/assets/everestcer.png";
// import panther from "../../assets/assets/panthercer.png";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { GoArrowLeft, GoArrowRight } from "react-icons/go";
// import { useState } from "react";
// import backgroundWall from "../../assets/backgroundwall.png"

// export const Certificates = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const certificates = [
//     { src: ambuja, alt: "Ambuja Certificate" },
//     { src: everest, alt: "Everest Certificate" },
//     { src: panther, alt: "Panther Certificate" },
//     { src: indostar, alt: "Indostar Certificate" },
//     { src: kamdhenu, alt: "Kamdhenu Certificate" },
//   ];

//   const prevSlide = () => {
//     const isFirstSlide = currentIndex === 0;
//     const newIndex = isFirstSlide
//       ? certificates.length - 1
//       : currentIndex - 1;
//     setCurrentIndex(newIndex);
//   };

//   const nextSlide = () => {
//     const isLastSlide = currentIndex === certificates.length - 1;
//     const newIndex = isLastSlide ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//   };

//   return (
//     <div className="flex flex-col my-10 gap-[36px]">
//       <h1 className="font-[700] text-[36px] md:text-[48px] leading-[58px]">
//         Our Certifications
//       </h1>

//       <div className="hidden sm:flex flex-wrap justify-between gap-[21px]  bg-cover bg-no-repeat "
//         style={{ backgroundImage: `url(${backgroundWall})` }}
//       >
//         {certificates?.map((cert, index) => (
//           <div
//             key={index}
//             className="w-[346px] h-[205px] md:w-[396px] md:h-[205px]">
//             <img
//               src={cert?.src}
//               alt={cert?.alt}
//               className="w-full h-full"
//               loading="lazy"
//             />
//           </div>
//         ))}
//       </div>

//       <div className="flex flex-col items-center sm:hidden">
//         <section className="my-2">
//           <div className="relative w-full  h-[300px] mx-auto">
//             <div className="relative w-full">
//               <img
//                 src={certificates[currentIndex].src}
//                 alt={certificates[currentIndex].alt}
//                 className="w-full h-[300px]  transition-transform duration-500 ease-in-out"
//               />
//             </div>

//             <button
//               onClick={prevSlide}
//               className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-white shadow-md rounded-full text-gray-800 hover:text-[#5097a4]">
//               <GoArrowLeft className="w-6 h-6" />
//             </button>

//             <button
//               onClick={nextSlide}
//               className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-white shadow-md rounded-full text-gray-800 hover:text-[#5097a4]">
//               <GoArrowRight className="w-6 h-6" />
//             </button>

//             <div className="flex justify-center mt-4 space-x-2">
//               {certificates.map((_, index) => (
//                 <div
//                   key={index}
//                   className={`w-3 h-3 rounded-full ${currentIndex === index
//                       ? "bg-[#5097a4]"
//                       : "bg-gray-300"
//                     }`}
//                 />
//               ))}
//             </div>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// };
