import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '../../../../../Redux/store'
import BlueBox from './BlueBox'
import RewardComponent from '../rewardComponent'

export const CategoriesComponent = () => {
  const reward = useSelector((state: RootState) => state.admin.currentReward)
  const location = useLocation()

  const showRewardComponent = location.pathname.startsWith('/admin/category/view/')
  const showCategoryItem = location.pathname.startsWith('/admin/category/item/')


  if (showCategoryItem) {
    return null 
  }

  return (
    <div>
      <div className="flex flex-row justify-between p-5 m-1">
        <h1 className="text-[#475569] font-inter font-semibold text-lg">
          Inventory Summary
        </h1>
      </div>

      {reward && showRewardComponent && (
        <RewardComponent id={reward.id} name={reward.name} />
      )}

      <BlueBox />
    </div>
  )
}