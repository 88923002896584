import { api } from "../rtkAPI";
import { Rewards } from "../types";

export const RewardsQuery: any = api.injectEndpoints({
	endpoints: (builder) => ({
		getAdminRewards: builder.query<Rewards, string>({
			query: () => {
				return {
					url: `/global/rewards/admin/all`,
					method: "GET",
				};
			},
			providesTags: ["Rewards"],
		}),

		getRewards: builder.query<Rewards, string>({
			query: () => {
				return {
					url: `/global/rewards/all`,
					method: "GET",
				};
			},
			providesTags: ["Rewards"],
		}),

		createReward: builder.mutation<any, any>({
			query: (couponData) => {
				const headers: Record<string, string> = {};
				headers["Content-Type"] = "multipart/form-data";

				return {
					url: `global/rewards/create`,
					method: "POST",
					body: couponData,
					invalidatesTags: ["Rewards"],
				};
			},
			invalidatesTags: ["Rewards"],
		}),

		editReward: builder.mutation<any, any>({
			query: ({ id, body }) => {
				// console.log(id,body,"openup")
				return {
					url: `global/rewards/edit/${id}`,
					method: "PATCH",
					body,
				};
			},
			invalidatesTags: ["Rewards"],
		}),

		deleteReward: builder.mutation<any, any>({
			query: (id) => {
				return {
					url: `/global/rewards/admin/${id}`,
					method: "DELETE",
				};
			},
			invalidatesTags: ["Rewards"],
		}),

		changeImage: builder.mutation<any, any>({
			query: ({ couponData, id }) => {
				console.log("change image data", couponData, id);
				return {
					url: `/global/rewards/image/upload/${id}`,
					method: "PATCH",
					body: couponData,
				};
			},
			invalidatesTags: ["Rewards"],
		}),
		claimReward: builder.mutation<any, any>({
			query: (rewardId) => {
				console.log("change image data", rewardId);
				return {
					url: `/global/rewards/approve/claim`,
					method: "POST",
					body: rewardId,
				};
			},
			invalidatesTags: ["Rewards"],
		}),
		// getRequestClaimRewards: builder.query<any, string>({
		//   query: () => {
		//     return {
		//       url: `/global/rewards/claim`,
		//       method: "GET",
		//     };
		//   },
		//   providesTags: ["Rewards"],
		// }),
		getRequestClaimRewards: builder.mutation<
			any,
			{ limit: number | "All"; page: number; search: string }
		>({
			query: ({ search = "", page = 1, limit = 10 }) => {
				return {
					url: `/global/rewards/claim`,
					method: "POST",
					body: { search, page, limit },
				};
			},
			// Invalidating the "Rewards" tag, which will trigger a re-fetch or state update if needed.
			invalidatesTags: [{ type: "Rewards" }],
		}),

		approveClaimRewards: builder.mutation<any, any>({
			query: (id) => {
				console.log(`console the data ${id}`);
				return {
					url: `/global/rewards/admin/changestatus/${id}`,
					method: "POST",
				};
			},
			invalidatesTags: ["Rewards"],
		}),
		// GetClaimedRewards: builder.query<any, string>({
		//   query: () => {
		//     return {
		//       url: `/global/rewards/claimed/reward`,
		//       method: "GET",
		//     };
		//   },
		//   providesTags: ["Rewards"],
		// }),
		GetClaimedRewards: builder.mutation<
			any,
			{
				search?: string;
				adminName?: string;
				page?: number;
				limit?: number;
				startDate?: string;
				endDate?: string;
			}
		>({
			query: ({
				search = "",
				adminName = "",
				page = 1,
				limit = 10,
				startDate,
				endDate,
			}) => {
				return {
					url: `/global/rewards/claimed/reward`,
					method: "POST",
					body: {
						search,
						adminName,
						page,
						limit,
						startDate,
						endDate,
					},
				};
			},
			invalidatesTags: ["Rewards"],
		}),
	}),
});

export const {
	useCreateRewardMutation,
	useGetRewardsQuery,
	useGetAdminRewardsQuery,
	useEditRewardMutation,
	useDeleteRewardMutation,
	useClaimRewardMutation,
	useChangeImageMutation,
	useGetRequestClaimRewardsMutation,
	useApproveClaimRewardsMutation,
	useGetClaimedRewardsMutation,
} = RewardsQuery;
