import { motion } from "framer-motion";
import {
  useGetContractorWebsiteViewQuery,
  useGetPagesViewQuery,
  useGetProductsQuery,
  useLazyGetTotalContractorWebsiteViewQuery,
} from "../../../Redux/Api/Admin/Dashboard";
import BarChartComponent from "./charts/LineChartComponent";
import { DonutChart } from "./charts/piechart/DonutChart";
import { VisitChartComponent } from "./charts/visitChartComponent";
import { DonutChartPageViews } from "./charts/piechart/DonutCharPageView";
import { Barplot } from "./charts/barplot/BarPlot";
import { exportToExcel } from "../../../utils/exportToExcel";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import HorizontalBarChart from "./charts/HorizontalBarChart/HorizontalBarChart";

const slideInVariants = {
  hidden: { x: "-100%", scaleY: 0.8, opacity: 0 },
  visible: { x: "0%", scaleY: 1, opacity: 1 },
};

const bounceVariants = {
  hidden: { scale: 0 },
  visible: {
    scale: [0, 1.2, 1],
    transition: { type: "spring", stiffness: 300, damping: 20 },
  },
};

const fadeInVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
interface Product {
  name: string;
  value: number;
  PublishProductList?: string[];
  UnPublishProductList?: string[];
}
interface ProductData {
  message: string;
  data: Product[];
  totalProduct: number;
}
export const Analytics = () => {
  const { data: productData } = useGetProductsQuery();
  // console.log("Product", productData);
  const { data: pagesView } = useGetPagesViewQuery();
  const { data: contractorWebsite } = useGetContractorWebsiteViewQuery();

  const [
    triggerGetTotalContractorWebsiteView,
    { data: totalContractorWebsiteData, isFetching, isError },
  ] = useLazyGetTotalContractorWebsiteViewQuery();

  const validNames = [
    "items",
    "Home",
    "company",
    "categories",
    "contactus",
    "order",
    "subItems",
    "singleproduct",
    "aboutus",
  ];
  const pageData = pagesView?.pages.filter((item: any) =>
    validNames.includes(item.name)
  );

  // const exportProductDataToExcel = (): void => {
  //   if (productData?.data?.length > 0) {
  //     const exportData = productData.data.map((item: any, index: number) => ({
  //       SN: index + 1,
  //       Name: item?.name,
  //       Count: item?.value,
  //       PublishProductList: item?.PublishProductList
  //         ? item.PublishProductList.slice(0, 20).map((product: any) => product.name).join("\n")
  //         : "",
  //       UnPublishProductList: item?.UnPublishProductList
  //         ? item.UnPublishProductList.slice(0, 20).map((product: any) => product.name).join("\n")
  //         : "",
  //     }));

  //     exportToExcel(exportData, `Product Data`);
  //   } else {
  //     toast.info("No data available to export to Excel.", {
  //       position: "top-center",
  //     });
  //   }
  // };

  const exportProductDataToExcel = (): void => {
    if (productData?.data?.length > 0) {
      const worksheetData: any[] = [];
      worksheetData.push([
        "SN",
        "Publish Product List",
        "Unpublish Product List",
      ]);
      let globalIndex = 1;

      productData.data.forEach((item: any) => {
        const publishCount = item?.PublishProductList?.length || 0;
        const unpublishCount = item?.UnPublishProductList?.length || 0;
        const maxCount = Math.max(publishCount, unpublishCount);

        for (let i = 0; i < maxCount; i++) {
          const row: any = [];
          row[0] = globalIndex++;

          if (i < publishCount) {
            row[1] = item.PublishProductList[i].name;
          } else {
            row[1] = "";
          }

          if (i < unpublishCount) {
            row[2] = item.UnPublishProductList[i].name;
          } else {
            row[2] = "";
          }

          worksheetData.push(row);
        }
      });

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Product Data");
      XLSX.writeFile(workbook, "ProductData.xlsx");
    } else {
      toast.error("No data available to export to Excel.", {
        position: "top-center",
      });
    }
  };

  const exportPageViewDataToExcel = () => {
    if (pageData?.length > 0) {
      const exportData = pageData?.map((item: any, index: number) => ({
        SN: index + 1,
        Name: item?.name,
        Count: item?.value,
      }));

      exportToExcel(exportData, `Page view data`);
    } else {
      toast.error("No data available to export to Excel.", {
        position: "top-center",
      });
    }
  };

  const exportContractorWebsiteCountDataToExcel = () => {
    if (contractorWebsite?.lastContractor?.length > 0) {
      const exportData = contractorWebsite?.lastContractor?.map(
        (item: any, index: number) => ({
          SN: index + 1,
          Name: item?.name,
          Count: item?.value === 2 ? 0 : item?.value - 2,
        })
      );
      exportToExcel(exportData, `Contractor Website Count Data`);
    } else {
      toast.error("No data available to export to Excel.", {
        position: "top-center",
      });
    }
  };

  const exportTotalContractorWebsiteCountDataToExcel = async () => {
    const response = await triggerGetTotalContractorWebsiteView();

    console.log("response", response.data.contractor.length);

    if (response?.data?.contractor?.length > 0) {
      const exportData = response.data.contractor?.map(
        (item: any, index: number) => ({
          SN: index + 1,
          Name: item?.name[0],
          date: item?.date.split("T")[0],
          time: item?.date.split("T")[1].split(".")[0],
        })
      );
      exportToExcel(exportData, `Contractor Website Count Data`);
    } else {
      toast.error("No data available to export to Excel.", {
        position: "top-center",
      });
    }
  };

  const newProductData: any = productData?.data
    .slice(0, 2)
    ?.map((item: any) => ({
      name: item.name,
      value: item.value,
    }));

  return (
    <>
      <div className="grid grid-cols-12 gap-4 px-8 py-4">
        <div className="col-span-12 lg:col-span-5 ">
          <div className="grid grid-col-12 gap-4">
            <motion.div
              className="col-span-12 rounded-2xl overflow-hidden"
              style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: 0.5, delay: 1 }}>
              <div className="w-full h-full bg-white flex-grow p-5">
                <div className="flex justify-between items-center  ">
                  <span className="font-bold">
                    Contractor Website Visit
                  </span>
                  <span className="text-green-500 font-bold flex ml-5">
                    <span className="text-red-600 font-bold">
                      Total :{" "}
                    </span>
                    <span className="ml-1">{`( ${contractorWebsite?.websiteView} )`}</span>
                  </span>
                  <button
                    onClick={
                      exportTotalContractorWebsiteCountDataToExcel
                    }
                    className="text-sm bg-green-100 rounded-md ml-auto py-1 px-3 border-2 border-green-600 text-green-600 hover:bg-green-600 hover:text-white duration-200 hover:scale-105">
                    Export
                  </button>
                </div>
                {contractorWebsite?.lastContractor && (
                  // <Barplot
                  //   width={400}
                  //   height={630}
                  //   data={contractorWebsite?.lastContractor}
                  // />
                  <HorizontalBarChart
                    data={contractorWebsite?.lastContractor}
                  />
                )}
              </div>
            </motion.div>
            <div
              className="col-span-12 rounded-2xl overflow-hidden"
              style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
              <motion.div
                className="w-full h-full  bg-white flex-grow p-4"
                initial="hidden"
                animate="visible"
                variants={slideInVariants}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                  delay: 0.6,
                }}>
                <div className="flex justify-between items-start px-2 pt-2 ">
                  <div className="flex flex-col gap-2  ">
                    <span className="font-bold text-xl">
                      Products Chart
                    </span>
                    <span className="text-green-500 font-bold flex">
                      <span className="text-red-600 font-bold ">
                        Total Products :{" "}
                      </span>
                      <span className="ml-1">{`( ${productData?.totalProduct} )`}</span>
                    </span>
                  </div>
                  <button
                    onClick={() =>
                      exportProductDataToExcel()
                    }
                    className="text-sm bg-green-100 rounded-md ml-auto py-1  px-3 border-2 border-green-600 text-green-600 hover:bg-green-600 hover:text-white duration-200 hover:scale-105">
                    Export
                  </button>
                </div>
                <div className="flex flex-col items-center">
                  {productData && (
                    <DonutChart
                      data={newProductData}
                      width={370}
                      height={405}
                    />
                  )}
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        <div className="col-span-7 ">
          <div className="grid grid-col-12 gap-4">
            <motion.div
              className="col-span-12 rounded-2xl overflow-hidden  bg-white p-5"
              style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              initial="hidden"
              animate="visible"
              variants={bounceVariants}
              transition={{ duration: 0.5, delay: 0.2 }}>
              <div className="mb-8 w-full h-full   flex-col items-center">
                <VisitChartComponent />
              </div>
            </motion.div>
            <motion.div
              className="col-span-12  rounded-2xl overflow-hidden"
              style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              initial="hidden"
              animate="visible"
              variants={fadeInVariants}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <div
                className="w-full p-4 h-full shadow-lg bg-white"
              >
                <div className="flex justify-between items-center ">
                  <span className="font-bold text-xl">
                    Page Views
                  </span>
                  <span className="text-green-500 font-bold flex ml-5">
                    <span className="text-red-600 font-bold">
                      Total Pages View :
                    </span>
                    <span className="ml-1">{` ( ${productData?.totalProduct} )`}</span>
                  </span>
                  <button
                    onClick={exportPageViewDataToExcel}
                    className="text-sm bg-green-100 rounded-md ml-auto py-1  px-3 border-2 border-green-600 text-green-600 hover:bg-green-600 hover:text-white duration-200 hover:scale-105">
                    Export
                  </button>
                </div>
                {pagesView && (
                  <DonutChartPageViews
                    data={pageData}
                    width={650}
                    height={560}
                  />
                )}
              </div>
          </motion.div>
        </div>
      </div>

      <div className="col-span-12">
        <motion.div
          className="mb-8 "
          initial="hidden"
          animate="visible"
          variants={bounceVariants}
          transition={{ duration: 0.5, delay: 1.4 }}>
          <BarChartComponent />
        </motion.div>
      </div>
    </div >

    {/* <div className="flex flex-col bg-gray-100 p-6">
        <div className="flex w-full gap-5 h-full">
          <motion.div
            className="mb-8 w-[60%] shadow-lg bg-white p-3"
            initial="hidden"
            animate="visible"
            variants={bounceVariants}
            transition={{ duration: 0.5, delay: 0.2 }}>
            <VisitChartComponent />
          </motion.div>

          <motion.div
            className="w-[37%] shadow-lg bg-white h-full flex-grow p-1"
            initial="hidden"
            animate="visible"
            variants={slideInVariants}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
              delay: 0.6,
            }}>
            <div className="flex justify-between items-start px-2 pt-2 ">
              <div className="flex flex-col items-start ">
                <span className="font-bold text-xl">
                  Products Chart
                </span>
                <span className="text-green-500 font-bold flex">
                  <span className="text-red-600 font-bold">
                    Total Products :
                  </span>
                  <span>{productData?.totalProduct}</span>
                </span>
              </div>
              <button
                onClick={() => exportProductDataToExcel()}
                className="text-sm bg-green-100 rounded-md ml-auto py-1  px-3 border-2 border-green-600 text-green-600 hover:bg-green-600 hover:text-white duration-200 hover:scale-105">
                Export
              </button>
            </div>
            {productData && (
              <DonutChart
                data={newProductData}
                width={400}
                height={440}
              />
            )}
          </motion.div>
        </div>
        <div className="flex w-full gap-5 h-full">
          <motion.div
            className="w-[60%] p-6 h-full shadow-lg bg-white"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: 0.5, delay: 1 }}>
            <div className="flex justify-between items-center ">
              <span className="font-bold text-xl">Page Views</span>
              <span className="text-green-500 font-bold flex ml-5">
                <span className="text-red-600 font-bold">
                  Total Pages View :
                </span>
                <span>{productData?.totalProduct}</span>
              </span>
              <button
                onClick={exportPageViewDataToExcel}
                className="text-sm bg-green-100 rounded-md ml-auto py-1  px-3 border-2 border-green-600 text-green-600 hover:bg-green-600 hover:text-white duration-200 hover:scale-105">
                Export
              </button>
            </div>
            {pagesView && (
              <DonutChartPageViews
                data={pageData}
                width={700}
                height={600}
              />
            )}
          </motion.div>
          <motion.div
            className="w-[60%]  h-full shadow-lg bg-white flex-grow"
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: 0.5, delay: 1 }}>
            <div className="flex justify-between items-center px-6 pt-6 ">
              <span className="font-bold ">
                Contractor Website Visit
              </span>
              <span className="text-green-500 font-bold flex ml-5">
                <span className="text-red-600 font-bold">
                  Total :
                </span>
                <span>{contractorWebsite?.websiteView}</span>
              </span>
              <button
                onClick={exportTotalContractorWebsiteCountDataToExcel}
                className="text-sm  bg-green-100 rounded-md ml-auto py-1  px-3 border-2 border-green-600 text-green-600 hover:bg-green-600 hover:text-white duration-200 hover:scale-105">
                Export
              </button>
            </div>
            {contractorWebsite?.lastContractor && (
              <Barplot
                width={400}
                height={630}
                data={contractorWebsite?.lastContractor}
              />
            )}
          </motion.div>
        </div>
        <motion.div
          className="mb-8 py-5"
          initial="hidden"
          animate="visible"
          variants={bounceVariants}
          transition={{ duration: 0.5, delay: 1.4 }}>
          <BarChartComponent />
        </motion.div>
      </div> */}
		</>
	);
};
