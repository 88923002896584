
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children?: React.ReactNode;
    width?: string;
}

const CustomModal = ({
    isOpen,
    onClose,
    title,
    width,
    children,
}: ModalProps) => {
    const modalRaf = useRef<HTMLDivElement | null>(null);

    const handleOutsideClick = (event: MouseEvent): void => {
        if (
            modalRaf.current &&
            !modalRaf.current.contains(event.target as Node)
        ) {
            onClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
            document.body.classList.remove("overflow-hidden");
        };
    }, [isOpen]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    id="progress-modal"
                    tabIndex={-1}  // Allows the modal to be focused when opened
                    className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}>

                    <motion.div
                        className={`relative w-[95%] md:w-full max-w-md h-auto max-h-[95%] overflow-auto ${width || "md:max-w-lg"}`}
                        initial={{ rotateY: 90, opacity: 0 }}
                        animate={{ rotateY: 0, opacity: 1 }}
                        exit={{ rotateY: -90, opacity: 0 }}
                        transition={{
                            duration: 0.6,
                            ease: [0.42, 0, 0.58, 1],
                        }}
                        ref={modalRaf}
                        style={{ transformOrigin: "left center" }}>

                        <div className="bg-white rounded-lg shadow dark:bg-gray-700">
                            {/* Modal Close Button */}
                            <button
                                type="button"
                                className="absolute top-2 right-2 text-gray-400 hover:bg-[#5097A4] hover:text-white rounded-lg text-sm w-8 h-8 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white transition-all duration-300 ease-in-out"
                                onClick={onClose}>
                                <RxCross2 className="h-5 w-5" />
                            </button>

                            {/* Modal Content */}
                            <div className="p-5 flex flex-col h-full max-h-[100%] overflow-y-auto">
                                <h3 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">
                                    {title}
                                </h3>
                                <div className="flex-grow">{children}</div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
export default CustomModal;

interface IProfileProps {
    imageUrl: any;
    altText: string;
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
}

export const ZoomInImage = ({ isOpen, setIsOpen, imageUrl, altText }: IProfileProps) => {
    const zoomInRef = useRef<HTMLDivElement | null>(null);

    const handleOutsideClick = (event: MouseEvent): void => {
        if (zoomInRef.current && !zoomInRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
            document.body.classList.remove("overflow-hidden");
        };
    }, [isOpen]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    id="progress-modal"
                    tabIndex={-1}
                    className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: 'easeOut' }}
                >
                    <motion.div
                        className={`relative w-[80%] md:w-full max-w-md md:max-w-lg h-auto max-h-[95vh] overflow-hidden`} // Set max height for modal
                        initial={{ scale: 0.95 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.95 }}
                        transition={{ duration: 0.2, ease: 'easeOut' }}
                        ref={zoomInRef}
                    >
                        <div className="rounded-lg shadow dark:bg-gray-700">
                            {/* Modal Close Button */}
                            <button
                                type="button"
                                className="absolute top-1 right-1 text-white hover:text-[#5097A4] rounded-lg text-sm w-7 h-7 flex justify-center items-center transition-all duration-300 ease-in-out"
                                onClick={() => setIsOpen(false)}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M1 1l6 6m0 0l6 6M7 7L1 13M7 7l6-6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>

                            {/* Modal Content */}
                            <div className="p-6 flex flex-col h-full max-h-[90vh] overflow-hidden">
                                <div className="rounded-2xl w-full max-h-full overflow-auto  scrollbar-customXy">
                                    <img
                                        src={imageUrl}
                                        alt={altText}
                                        className="object-contain object-center cursor-pointer rounded-2xl w-full h-full"
                                        onClick={() => setIsOpen(false)}
                                    />
                                </div>
                            </div>

                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};


interface IConfirmProps {
    isOpen: boolean;
    handleConfirm: () => void;
    handleClose: () => void;
    text: string,
    buttonText?: string,
    heading?: string,
    buttonBgColor?: string
}

export const ConfirmModal = ({ isOpen, handleConfirm, text, handleClose, buttonText, heading, buttonBgColor }: IConfirmProps) => {
    const zoomInRef = useRef<HTMLDivElement | null>(null);

    const handleOutsideClick = (event: MouseEvent): void => {
        if (zoomInRef.current && !zoomInRef.current.contains(event.target as Node)) {
            handleClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
            document.body.classList.remove("overflow-hidden");
        };
    }, [isOpen]);
    console.log("data", buttonText);
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    id="progress-modal"
                    tabIndex={-1}
                    className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: 'easeOut' }}
                >
                    <motion.div
                        className={`relative w-[80%] md:w-full max-w-md md:max-w-sm h-auto max-h-[95vh] overflow-hidden`} // Set max height for modal
                        initial={{ scale: 0.95 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.95 }}
                        transition={{ duration: 0.2, ease: 'easeOut' }}
                        ref={zoomInRef}
                    >
                        <div className="relative px-4 py-10 text-center bg-white rounded-lg shadow dark:bg-gray-800 ">
                            {/* <button
                                type="button"
                                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-toggle="deleteModal"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button> */}
                            <h3 className="text-lg font-semibold mb-4">{heading}</h3>
                            <p className="mb-4 text-gray-500 dark:text-gray-300 text-lg text-wrap w-[90%] mx-auto">
                                {text}
                            </p>
                            <div className="flex justify-center items-center space-x-4">
                                <button
                                    onClick={handleClose}
                                    data-modal-toggle="deleteModal"
                                    type="button"
                                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 "
                                >
                                    cancel
                                </button>
                                <button
                                    onClick={handleConfirm}
                                    className={`py-2 px-3 text-sm font-medium text-center text-white rounded-lg focus:outline-none min-w-[70px] ${buttonBgColor ? buttonBgColor : "bg-[#5097A4] hover:bg-[#4d8e9a]"}`}
                                >
                                    {buttonText || "Yes, I'm sure"}


                                </button>
                            </div>
                        </div>

                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

interface CustomPopupModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children?: React.ReactNode;
    width?: string;
}

export const CustomPopupModal = ({
    isOpen,
    onClose,
    title,
    width,
    children,
}: CustomPopupModalProps) => {
    const modalRaf = useRef<HTMLDivElement | null>(null);

    const handleOutsideClick = (event: MouseEvent): void => {
        if (
            modalRaf.current &&
            !modalRaf.current.contains(event.target as Node)
        ) {
            onClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
            document.body.classList.remove("overflow-hidden");
        };
    }, [isOpen]);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    id="progress-modal"
                    tabIndex={-1}  // Allows the modal to be focused when opened
                    className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2, ease: 'easeOut' }}
                >

                    <motion.div
                        className={`relative w-[95%] md:w-full max-w-md h-auto max-h-[95%] overflow-auto ${width || "md:max-w-lg"}`}
                        initial={{ scale: 0.95 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.95 }}
                        transition={{ duration: 0.2, ease: 'easeOut' }}
                    >

                        <div className="bg-white rounded-lg shadow ">
                            {/* Modal Close Button */}
                            <button
                                type="button"
                                className="absolute top-2 right-2 text-gray-400 hover:bg-[#5097A4] hover:text-white rounded-lg text-sm w-5 h-5 flex justify-center items-center  transition-all duration-300 ease-in-out"
                                onClick={onClose}>
                                <RxCross2 className="h-4 w-4" />
                            </button>

                            {/* Modal Content */}
                            <div className="p-5 flex flex-col h-full max-h-[100%] overflow-y-auto font-inter">
                                <h3 className="mb-3 text-lg font-bold text-gray-900  text-center ">
                                    {title}
                                </h3>
                                <div className="flex-grow">{children}</div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

