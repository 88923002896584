import { useCallback, useEffect, useMemo, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import SearchBox from "./SearchBox";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchDataMutation } from "../../Redux/Api/priceAPI";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../Redux/reducer/Filter";
import { RootState } from "../../Redux/store";
import useDebounce from "../../common/hooks/useDebounce";

interface searchResults {
   name: string;
   _id: string;
}

const Dropdown = () => {
  const navigate = useNavigate();
  let getMainSearch = useSelector((state: RootState) => state.selection.selectedSearch );
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string | null>("");
  const [search, setSearchValue] = useState<string>(getMainSearch || "");
  const [searchResults, setSearchResults] = useState<searchResults[] | null >(null);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [searchAllProducts] = useSearchDataMutation();
  const location = useLocation();
  // Memoize special option to avoid unnecessary re-renders
  const specialOption = useMemo(() => ({ value: "special", label: "All" }), []);
  const searchDebounce = useDebounce(search, 500);

  // Handle the selection of an option from the dropdown
  const handleSelectOption = useCallback((value: any) => {
    setSelectedOption(value);
  }, []);

  useEffect(() => {
    setSearchValue(getMainSearch || "");
  }, [getMainSearch]);

  // Handle changes in the search input field
  const handleSearch = (value: string) => {
    setSearchValue(value);
    setShowSearchBox(true);
  };

  // Memoize the search function to avoid unnecessary re-renders
  const memoizedSearchAllProducts = useCallback(
    async (searchDebounce: string) => {
      if(searchDebounce?.trim() === ""){
        setSearchResults([]);
        return;
      }
   
      setProductLoading(true);
      try {
        const response = await searchAllProducts(searchDebounce);
          if (response?.data){
            setSearchResults(response?.data);
              return  response; 
          }  else{
               console.log("error in search response",response.error)
              setSearchResults(null)
          }
      } catch (error) {
        console.error("Error searching products:", error);
          throw error;
      } finally {
        setProductLoading(false);
      }
    },
    [searchAllProducts]
  );



  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchDebounce && searchDebounce.trim() !== "") {
         await memoizedSearchAllProducts(searchDebounce);
      } else {
        setSearchResults([]);
      }
    };
    fetchSearchResults();
  }, [searchDebounce, memoizedSearchAllProducts]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const searchBox = document.getElementById("search-box");
      if (searchBox && !searchBox.contains(event.target as Node)) {
        setShowSearchBox(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setShowSearchBox(false);
  }, [location.pathname]);

  const showSearchBoxOnClick = (event: any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      dispatch(setSearch(search));
      navigate(`/items`);
      setShowSearchBox(false);
    }
  };

  const closeSuggestionBox = () => {
    setShowSearchBox(false)
  }

  const onGlassClick = () => {
    if(search.trim() !== ""){
      dispatch(setSearch(search));
      navigate(`/items`);
      setShowSearchBox(false);
    }
  };

  const handleInput = () =>{
    if(window.innerWidth< 650){
       navigate("/searchpage");
    }
}

  return (
    <section className="hidden sm:flex">
     <select
        onChange={(e) => handleSelectOption(e.target.value)}
        value={selectedOption || ""}
        className="focus:outline-none bg-[#F3F3F3] p-1"
      >
        <option value={specialOption?.value} className="bg-[#F3F3F3] h-full m-2">
          {specialOption?.label}
        </option>
      </select>

      <div className="relative flex w-full items-center h-[40px]">
        {showSearchBox && (
          <div id="search-box" className="absolute top-[2.5rem] w-full">
            <SearchBox
              searchResults={searchResults}
              isLoading={productLoading}
              close={closeSuggestionBox}
              search={search}
              onGlassClick={onGlassClick}
            />
          </div>
        )}

        <div className="relative lg:static rounded-md focus:outline-none flex-1 mx-1 md:mx-3">
          <input
            type="text"
            placeholder="Search"
            value={search || ""}
            onChange={(e) => handleSearch(e.target.value)}
            onClick={handleInput}
            onKeyDown={(e) => showSearchBoxOnClick(e)}
            className="rounded-md focus:outline-none w-full lg:w-[30rem] xl:w-[45rem] "
          />
        </div>

        <div className="bg-[#FEBD69]  w-[45px] h-full flex justify-center items-center">
          <IoSearchOutline size={25} className="w-[24px] h-[24px] cursor-pointer" onClick={onGlassClick} />
        </div>
      </div>
    </section>
  );
};

export default Dropdown;