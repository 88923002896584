import React, { useState } from 'react';

interface Data {
    contractorId: string;
    contractorName: string;
    createdAt: string;
    date: string; // Use `Date` if you plan to handle date objects in the future
    message: string;
    name: string;
    _id: string;
}

interface AccordionProps {
    title: string;
    content: Data;
    isOpen: boolean;
    onToggle: () => void;
}

const generateColorFromName = (name: string) => {
    const colors = [
        '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FFBB33', '#6B33FF', '#33FFDB', '#FF3333',
        '#33FFA8', '#FFD433', '#33D4FF', '#FF33E1', '#A833FF', '#FF5B33', '#33FFA5', '#FFD433',
    ];
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const colorIndex = Math.abs(hash) % colors.length;
    return colors[colorIndex];
};


export const Accordion: React.FC<AccordionProps> = ({ title, content, isOpen, onToggle }) => {
    const formattedDate = new Date(content.date).toLocaleDateString();
    const formattedTime = new Date(content.date).toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    });
    const firstLetter = content.contractorName.charAt(0).toUpperCase();
    return (
        <div className="mb-2 rounded-2xl">
            <div
                onClick={onToggle}
                className="w-full flex justify-between text-black transition-all duration-500 ease-in-out transform items-center cursor-pointer    py-2 px-3   text-lg font-semibold rounded-2xl border border-gray-200 bg-white group hover:bg-[#334155] hover:text-white "
            >
                <div className="h-full w-full flex items-center justify-between">
                    <div className="flex items-center h-full">

                        <div className='w-[50px] h-[50px] rounded-full flex items-center justify-center bg-white border'>
                            <span className='text-blue-500 text-2xl font-bold'>{firstLetter}</span>
                        </div>
                        <span className='text-sm font-medium  ml-2'>{content.contractorName}</span>

                    </div>

                    <div className="text-gray-500 group-hover:text-white text-xs font-normal leading-4 py-1 mr-14  ">
                        <span className='font-semibold'>Date : </span>
                        <span>{formattedDate}</span>


                    </div>



                </div>
                <svg
                    className={`w-4 h-4 transition-transform ease-in-out transform ${isOpen ? 'rotate-180' : 'rotate-0'
                        }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </div>
            {/* <div
                className={`transition-all duration-700 flex flex-col h-[136px] items-center overflow-hidden ${isOpen ? 'max-h-screen' : 'max-h-0'
                    }`}
            >
                <div className="p-4 bg-gray-100 w-[95%] rounded-b-xl h-full">{content}</div>
            </div> */}
            <div
                className={`transition-all duration-500 ease-in-out transform flex flex-col h-auto  items-center ${isOpen ? 'scale-100 opacity-100 max-h-screen' : 'scale-95 opacity-0 max-h-0'
                    }`}
                style={{ transformOrigin: 'top' }}
            >
                <div className="p-4 bg-gray-100 w-[95%] rounded-b-xl h-full">
                    {/* <div className="text-sm font-medium"><span className='font-semibold'>Name : </span><span className='ml-2 text-blue-700'>{content.name || "-"}</span></div>
                    <div className="text-sm font-medium"><span className='font-semibold'>Message : </span><span className='ml-2 text-blue-700'>{content.message || "-"}</span></div>
                    <div className="text-sm font-medium"><span className='font-semibold'>Name : </span><span className='ml-2 text-blue-700'>{content.name || "-"}</span></div>
                    <div className="text-sm font-medium"><span className='font-semibold'>Name : </span><span className='ml-2 text-blue-700'>{content.name || "-"}</span></div> */}
                    <div className="flex items-start gap-2.5 w-full">

                        <div className="flex flex-col gap-1 w-full">
                            <div className="flex flex-col w-full  leading-1.5 p-4 border-gray-200 bg-white rounded-e-xl rounded-es-xl dark:bg-gray-700">
                                <div className="flex items-center space-x-2 rtl:space-x-reverse mb-2">
                                    <span className="text-sm font-semibold text-gray-900 dark:text-white">
                                        <span className='font-semibold'>Name : </span>  <span>{content.name}</span>
                                    </span>
                                </div>
                                <>
                                    <div className="text-sm">
                                        Message : <div className="px-3.5 py-2 bg-gray-100 rounded justify-start items-center gap-3 inline-flex w-fit max-w-full">
                                            <h5 className="text-gray-900 text-sm font-normal leading-snug break-words">
                                                {content.message}
                                            </h5>
                                        </div>
                                    </div>

                                    <div className="justify-end items-start mt-1 mb-2.5 flex flex-col">
                                        <h6 className="text-gray-500 text-xs font-normal leading-4 py-1">
                                            <span className='font-semibold'>Date : </span>
                                            <span>{formattedDate}</span>


                                        </h6>
                                        <h6 className="text-gray-500 text-xs font-normal leading-4 py-1">
                                            <span className='font-semibold'>Time : </span>
                                            <span>{formattedTime}</span>


                                        </h6>
                                    </div>
                                </>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    );
};