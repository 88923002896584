import { memo, useState } from "react";
import defaultImage from "../../assets/defaultImage.svg";
import { useNavigate } from "react-router-dom";
import { BsTelephone } from "react-icons/bs";
import { Company } from "../Components/types";

interface CompanyCardProps {
  company: Company;
}


const CompanyCard: React.FC<CompanyCardProps> = memo(({ company }) => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (itemName: any) => {
    setActiveItem(itemName);
    navigate("/items", { state: { company: itemName } });
  };

  const handleWhatsappClick = () => {
    const phoneNumber = "9258294692";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  return (
    <div
      className="bg-white flex flex-col justify-between rounded-lg hover:shadow-2xl hover:shadow-[#5097A4] gap-3 p-[12px] cursor-pointer border w-[16rem] h-[348px]"

    >
      <div   onClick={() => handleItemClick(company?.id)}  className="w-[232px] h-[144px] flex justify-center mx-aouto  overflow-hidden group border">
        <img
          className="relative md:h-[14rem] h-[11.1rem] w-[17rem] py-1 group-hover:scale-110 transition-transform duration-300"
          src={company?.image ? company?.image : defaultImage}
          alt="company image"
        />
      </div>
      <div className="flex flex-col justify-between " onClick={() => handleItemClick(company?.id)} >
        <div className="text-lg font-bold leading-[28px]  flex overflow-hidden" >{company?.name}</div>
        <div className="text-gray-600 ">Company {company?.productCount}</div>
      </div>

      <div className="relative w-full h-11 group ">
        {/* Default state */}
        <div className="w-full h-full px-3 py-3 bg-white rounded-lg border border-slate-200 flex justify-center gap-3 items-center  transition-opacity duration-300 absolute inset-0 group-hover:opacity-0">
          <div className="text-[#5097A4] flex whitespace-nowrap text-sm font-semibold font-['Inter'] leading-tight">
            Contact For Better Pricing
          </div>
          <BsTelephone className="w-4 h-4 relative text-[#5097A4]" />
        </div>
        {/* Hover state */}
        <div onClick={handleWhatsappClick} className="w-full h-full px-4 py-3 bg-[#5097A4] rounded-lg shadow flex items-center gap-3 justify-center transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-2xl absolute inset-0 overflow-hidden">
          <BsTelephone className="w-4 h-4 relative text-white opacity-0 transition-opacity duration-100 delay-300 group-hover:opacity-100" />
          <div className="text-white  flex whitespace-nowrap text-sm font-semibold font-['Inter'] leading-tight transition-transform duration-300 transform translate-x-full group-hover:translate-x-0">
            Contact For Better Pricing
          </div>
        </div>
      </div>
    </div>
  );
});

export default CompanyCard;