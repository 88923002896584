import { FaStar } from "react-icons/fa6";
import { FaStarHalfStroke } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import icon from "../assets/Icon.png";
import whatsapp from "../assets/whatsappicon.svg";
import sheild from "../assets/Shield Done.png";
import item from "../assets/item.png";
import Shield from "../assets/Shield.png";
import Verification from "../assets/Verification.png";
import Unchecked from "../assets/Unchecked.png";

interface AssignedRewardCardProps {
	product: {
		id: string;
		productName: string;
		finalPrice: number;
		rewards: number;
		status: boolean;
		unit: string;
		images: string;
	};
}

const AssignedRewardCard: React.FC<AssignedRewardCardProps> = ({ product }) => {
	const navigate = useNavigate();

	const handleWhatsappClick = () => {
		const phoneNumber = "9258294692";
		const url = `https://wa.me/${phoneNumber}`;
		window.open(url, "_blank");
	};

	return (
		<div
			className="flex gap-[2rem] py-3 px-3  w-full rounded-2xl"
			style={{ boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.25)" }}>
			<div className="flex gap-3 sm:gap-[2rem]  w-full">
				<div
					onClick={() =>
						navigate(`/singleproduct/${product.id}`, {
							state: { id: product.id },
						})
					}
					className="cursor-pointer relative w-[180px] h-[180px]   md:w-[197px] md:h-[197px] rounded-[4px] overflow-hidden">
					<img
						src={product.images || item}
						alt="item"
						className="w-full h-full object-cover rounded-2xl items-stretch"
					/>

					<div className=" hidden sm:flex w-[50px] h-[50px] border-[3px]  z-10 border-[#24FF00] absolute bottom-1 right-1 rounded-full bg-green-100 lg:bg-green-100   flex-col items-center justify-center gap-1">
						<img
							src={Shield}
							alt="Shield Icon"
							className="w-3 h-3"
						/>
						<div className="text-[6.5px] text-wrap text-center font-medium text-[#047857]">
							Lohawalla Assured
						</div>
					</div>
					{product.status ? (
						<div className="flex sm:hidden items-center absolute bottom-1 right-2">
							<img
								src={Verification}
								className="h-7 w-7 z-20"
								alt=""
							/>{" "}
							<div className=" ml-[-3px] px-1 py-[2px] text-xs rounded-r-lg border-green-300 bg-green-100 text-green-700">
								{" "}
								Active
							</div>
						</div>
					) : (
						<div className="flex sm:hidden  items-center absolute bottom-1 right-2">
							<img
								src={Unchecked}
								className="h-7 w-7 z-20"
								alt=""
							/>{" "}
							<div className=" ml-[-3px] px-1 py-[2px] text-xs rounded-r-lg border-[#931515] bg-red-100 text-red-700">
								{" "}
								InActive
							</div>
						</div>
					)}
				</div>

				<div className="flex w-full justify-between sm:py-3 relative">
					<div className="flex flex-col gap-[8px]">
						<div
							className="font-[500] text-[12px] sm:text-[18px] leading-[21px] cursor-pointer"
							onClick={() =>
								navigate(`/singleproduct/${product.id}`, {
									state: { id: product.id },
								})
							}>
							{product.productName}
						</div>

						<div className="flex flex-col gap-[8px]">
							<div className="font-[500] text-[14px] leading-[17px] flex items-center gap-[9px]">
								{product.finalPrice
									? `₹${product.finalPrice.toFixed(2)}/${
											product.unit
									  }`
									: "Price not available"}
							</div>

							<div className="font-400 text-[10px] sm:text-[14px] leading-[22px] text-blue-400 flex items-center gap-2">
								<span>(For Best Rate, Please Contact Us)</span>
								<img
									src={whatsapp}
									className="w-[22px] h-[22px] cursor-pointer"
									onClick={handleWhatsappClick}
									alt="whatsapp"
								/>
							</div>
						</div>

						{product.rewards ? (
							<div className="flex  flex-col items-start sm:flex-row sm:items-center">
								<span className="text-[15px] pb-1 sm:pb-0">
									You will earn{" "}
								</span>
								<div className=" flex items-center">
									<img
										className="mr-2 sm:mx-2"
										src={icon}
										alt="icon"
									/>{" "}
									<span className="text-[15px] sm:text-[20px] mr-2 text-nowrap">
										{product.rewards} super coins
									</span>
								</div>
							</div>
						) : null}

						<div className="flex text-center text-[#007185] items-center text-[10px]">
							<div className="flex pr-[12px] font-[400] text-[10px] sm:text-[14px] leading-[16px] text-[#007185]">
								<FaStar className="text-yellow-600" />
								<FaStar className="text-yellow-600" />
								<FaStar className="text-yellow-600" />
								<FaStar className="text-yellow-600" />
								<FaStarHalfStroke className="text-yellow-600" />
							</div>
							<IoIosArrowDown className="text-black" />
							<span className="text-[10px] sm:text-[14px]">
								1,084
							</span>
						</div>
					</div>

					{product.status ? (
						<div className="hidden sm:flex items-center absolute right-1 top-0">
							<img
								src={Verification}
								className="h-7 w-7 z-20"
								alt=""
							/>{" "}
							<div className=" ml-[-3px] px-1 py-[2px] text-xs rounded-r-lg border-green-300 bg-green-100 text-green-700">
								Active
							</div>
						</div>
					) : (
						<div className="hidden sm:flex items-center absolute right-1 top-0">
							<img
								src={Unchecked}
								className="h-7 w-7 z-20"
								alt=""
							/>{" "}
							<div className=" ml-[-3px] px-1 py-[2px] text-xs rounded-r-lg border-[#931515] bg-red-100 text-red-700">
								InActive
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default AssignedRewardCard;
