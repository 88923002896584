import { motion } from 'framer-motion';
import tataImage from "../../../assets/tata-logo-3d-model-max-obj-3ds-fbx-c4d-lwo-lw-lws-removebg-preview 1.png";
import VolTasImage from "../../../assets/Mask group.png";
import Haier from "../../../assets/Screenshot_2024-09-23_150751-removebg-preview 1.png";

const OurClients = () => {

    const fadeInFromLeft = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
    };

    const fadeInFromRight = {
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
    };

    return (
        <>
            <div className="text-center hidden lg:block">
                <h1
                    className="text-5xl text-[#484A4A] font-medium"
                    style={{
                        textShadow: "0px 4px 4px #00000040",
                    }}>
                    Our Clients
                </h1>
            </div>
            <div className="w-full mx-auto hidden lg:flex flex-col space-y-3 ">
                {/* First Card */}
                <motion.div
                    className="w-full h-auto flex flex-col md:flex-row items-center"
                    initial="hidden"
                    whileInView="visible"
                    variants={fadeInFromLeft}
                    viewport={{ once: false }}  
                >
                    <img
                        src={tataImage}
                        className="h-full w-[50%] md:w-[20%] object-contain"
                        alt="Tata Logo"
                    />
                    <div
                        className="bg-[#5097A41A] hover:bg-[#5097A480] duration-150   flex-1 m-5 flex justify-center items-center text-xl py-8 px-5 font-medium text-[#404040] rounded-3xl cursor-pointer"
                        style={{
                            boxShadow: "0px 4px 4px 0px #00000040",
                        }}>
                        "We have been working with the Chawla Auto Components
                        since its launch and have been very impressed with the
                        quality of the fabrication, the lead times and ease of
                        use of the platform."
                    </div>
                </motion.div>

                {/* Second Card */}
                <motion.div
                    className="w-full h-auto flex flex-col-reverse md:flex-row items-center"
                    initial="hidden"
                    whileInView="visible"
                    variants={fadeInFromRight}
                    viewport={{ once: false }}  
                >
                    <div
                        className="bg-[#5097A41A] hover:bg-[#5097A480] duration-150  flex-1 m-5 flex justify-center items-center text-xl py-10 px-5 font-medium text-[#404040] rounded-3xl cursor-pointer"
                        style={{
                            boxShadow: "0px 4px 4px 0px #00000040",
                        }}>
                        "It's amazing you can engineer any special items for us
                        so quickly it is even faster than writing a few words on
                        a piece of paper! Thank you for your support!"
                    </div>
                    <img
                        src={Haier}
                        className="h-full w-[50%] md:w-[20%] object-contain"
                        alt="Haier Logo"
                    />
                </motion.div>

                {/* Third Card */}
                <motion.div
                    className="w-full h-auto flex flex-col md:flex-row items-center"
                    initial="hidden"
                    whileInView="visible"
                    variants={fadeInFromLeft}
                    viewport={{ once: false }}  
                >
                    <img
                        src={VolTasImage}
                        className="h-full w-[50%] md:w-[20%] object-contain"
                        alt="Voltas Logo"
                    />
                    <div
                        className="bg-[#5097A41A] hover:bg-[#5097A480] duration-150 flex-1 m-5 flex justify-center items-center text-xl py-8 px-5 font-medium text-[#404040] rounded-3xl cursor-pointer"
                        style={{
                            boxShadow: "0px 4px 4px 0px #00000040",
                        }}>
                        "Best contract manufacturing in India, professional and
                        diligent management. Feel safe and taken care by its
                        CEO's experience in business and integrity. Chawla Auto
                        Components is a secured bet."
                    </div>
                </motion.div>
            </div>
        </>
    );
};

export default OurClients;


// import { motion } from 'framer-motion';
// import tataImage from "../../../assets/tata-logo-3d-model-max-obj-3ds-fbx-c4d-lwo-lw-lws-removebg-preview 1.png";
// import VolTasImage from "../../../assets/Mask group.png";
// import Haier from "../../../assets/Screenshot_2024-09-23_150751-removebg-preview 1.png";

// const OurClients = () => {

    
//     const fadeInFromLeft = {
//         hidden: { opacity: 0, x: -100 },
//         visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
//     };

//     const fadeInFromRight = {
//         hidden: { opacity: 0, x: 100 },
//         visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
//     };

//     return (
//         <>
//             <div className="text-center hidden lg:block">
//                 <h1
//                     className="text-5xl text-[#484A4A] font-medium"
//                     style={{
//                         textShadow: "0px 4px 4px #00000040",
//                     }}>
//                     Our Clients
//                 </h1>
//             </div>
//             <div className="w-full mx-auto hidden lg:flex flex-col space-y-3 ">
//                 {/* First Card */}
//                 <motion.div
//                     className="w-full h-auto flex flex-col md:flex-row items-center"
//                     initial="hidden"
//                     whileInView="visible"
//                     variants={fadeInFromLeft}
//                     viewport={{ once: true }}
//                 >
//                     <img
//                         src={tataImage}
//                         className="h-full w-[50%] md:w-[20%] object-contain"
//                         alt="Tata Logo"
//                     />
//                     <div
//                         className="bg-[#5097A41A] hover:bg-[#5097A480] duration-150   flex-1 m-5 flex justify-center items-center text-xl py-8 px-5 font-medium text-[#404040] rounded-3xl cursor-pointer"
//                         style={{
//                             boxShadow: "0px 4px 4px 0px #00000040",
//                         }}>
//                         "We have been working with the Chawla Auto Components
//                         since its launch and have been very impressed with the
//                         quality of the fabrication, the lead times and ease of
//                         use of the platform."
//                     </div>
//                 </motion.div>

//                 {/* Second Card */}
//                 <motion.div
//                     className="w-full h-auto flex flex-col-reverse md:flex-row items-center"
//                     initial="hidden"
//                     whileInView="visible"
//                     variants={fadeInFromRight}
//                     viewport={{ once: true }}
//                 >
//                     <div
//                         className="bg-[#5097A41A] hover:bg-[#5097A480] duration-150  flex-1 m-5 flex justify-center items-center text-xl py-10 px-5 font-medium text-[#404040] rounded-3xl cursor-pointer"
//                         style={{
//                             boxShadow: "0px 4px 4px 0px #00000040",
//                         }}>
//                         "It's amazing you can engineer any special items for us
//                         so quickly it is even faster than writing a few words on
//                         a piece of paper! Thank you for your support!"
//                     </div>
//                     <img
//                         src={Haier}
//                         className="h-full w-[50%] md:w-[20%] object-contain"
//                         alt="Haier Logo"
//                     />
//                 </motion.div>

//                 {/* Third Card */}
//                 <motion.div
//                     className="w-full h-auto flex flex-col md:flex-row items-center"
//                     initial="hidden"
//                     whileInView="visible"
//                     variants={fadeInFromLeft}
//                     viewport={{ once: true }}
//                 >
//                     <img
//                         src={VolTasImage}
//                         className="h-full w-[50%] md:w-[20%] object-contain"
//                         alt="Voltas Logo"
//                     />
//                     <div
//                         className="bg-[#5097A41A] hover:bg-[#5097A480] duration-150 flex-1 m-5 flex justify-center items-center text-xl py-8 px-5 font-medium text-[#404040] rounded-3xl cursor-pointer"
//                         style={{
//                             boxShadow: "0px 4px 4px 0px #00000040",
//                         }}>
//                         "Best contract manufacturing in India, professional and
//                         diligent management. Feel safe and taken care by its
//                         CEO's experience in business and integrity. Chawla Auto
//                         Components is a secured bet."
//                     </div>
//                 </motion.div>
//             </div>
//         </>
//     );
// };

// export default OurClients;
