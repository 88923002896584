import { useLocation, useNavigate } from "react-router-dom";
import { useGetAllContractorQuery } from "../../../../../Redux/Api/Contractor";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setContractorData } from "../../../../../Redux/reducer/adminReducer";

export const ContractorsNavbar = () => {
  const navigate = useNavigate();
  const { data: ContractorResponse } = useGetAllContractorQuery();
  const dispatch = useDispatch();
  const location = useLocation();

  const customerProfile = location.pathname.startsWith(
    "/admin/contractors/profile"
  );

  const contractor = ContractorResponse?.data?.totalContractor;

  useEffect(() => {
    if (ContractorResponse) {
      dispatch(setContractorData(ContractorResponse?.data));
    }
  }, [contractor, ContractorResponse]);

  // const getNavLinkClass = (path: any) => {
  // 	return location.pathname === path ? "border-b-2 border-b-black" : "";
  // };

  const navLinksClasses = (path: any) => {
    return location.pathname === path
      ? "border-[#334155] bg-[#334155] text-white"
      : "";
  };
  const hideTabs = ![
    "/admin/contractors",
    "/admin/contractors/request",
    "/admin/contractors/delete"
  ].includes(location.pathname);
  return (

    <div className="flex flex-col gap-[10px]  mb-[10px] box-border">
      <div className="w-full bg-white p-[10px] font-[600] text-[24px] leading-[28px] pl-3">
        Contractors
      </div>
      {
        !hideTabs && (
          <div className="mx-3">
            <div
              className="flex items-center gap-2 h-[45px]  bg-white text-black text-[14px] rounded-xl mb-3 w-fit p-[4px] font-medium "
              style={{ boxShadow: "0px 4px 4px 0px #00000040" }}>
              <div
                onClick={() => navigate("/admin/contractors")}
                className={`rounded-xl cursor-pointer h-full flex items-center justify-center  px-3 py-1 ${navLinksClasses(
                  "/admin/contractors"
                )}`}>
                Contractors
              </div>
              <div
                onClick={() =>
                  navigate("/admin/contractors/request")
                }
                className={`rounded-xl cursor-pointer h-full flex items-center justify-center   px-3 py-1 ${navLinksClasses(
                  "/admin/contractors/request"
                )}`}>
                {" "}
                Contractors Request
              </div>
              <div
                onClick={() =>
                  navigate("/admin/contractors/delete")
                }
                className={`rounded-xl cursor-pointer h-full flex items-center justify-center   px-3 py-1 ${navLinksClasses(
                  "/admin/contractors/delete"
                )}`}>
                Contractors History
              </div>
            </div>
            {!customerProfile && (
              <div
                className="w-full flex flex-col md:flex-row justify-around sm:justify-around sm:gap-x-6 sm:pl-3 h-auto rounded-xl bg-white p-4"
                style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                <div className="flex flex-col justify-center h-full font-medium items-center gap-2">
                  <div className="text-[#8B8D97]">Total Contractors</div>
                  <div className="text-[#F59E0B]">{contractor}</div>
                </div>

                <div className="flex flex-col justify-center h-full font-medium items-center gap-2">
                  <div className="text-[#8B8D97]">Approve Contractors</div>
                  <div className="text-[#F59E0B]">{ContractorResponse?.data?.approveContractor}</div>
                </div>

                <div className="flex flex-col justify-center h-full font-medium items-center gap-2">
                  <div className="text-[#8B8D97]">Pending Contractors Requests</div>
                  <div className="text-[#F59E0B]">{ContractorResponse?.data?.pendingContractor}</div>
                </div>

                <div className="flex flex-col justify-center h-full font-medium items-center gap-2">
                  <div className="text-[#8B8D97]">Deleted Contractors</div>
                  <div className="text-[#F59E0B]">{ContractorResponse?.data?.deletedContractor}</div>
                </div>
              </div>

            )}
          </div>
        )
      }

      {/* <div className=" w-fit h-[30px] inline-flex items-center border-b border-b-slate-400 font-[400] text-[15px] leading-[22px] px-2 whitespace-nowrap">
					<div
						className={` inline-flex justify-center items-center h-full px-2 cursor-pointer ${getNavLinkClass(
							"/admin/contractors"
						)}`}
						onClick={() => navigate("/admin/contractors")}>
						Contractors
					</div>
					<div
						className={` inline-flex justify-center items-center h-full px-2 cursor-pointer ${getNavLinkClass(
							"/admin/contractors/request"
						)}`}
						onClick={() => navigate("/admin/contractors/request")}>
						Contractors Request
					</div>
					<div
						className={` inline-flex justify-center items-center h-full px-2 cursor-pointer ${getNavLinkClass(
							"/admin/contractors/delete"
						)}`}
						onClick={() => navigate("/admin/contractors/delete")}>
						Contractor History
					</div>
				</div>

				{!customerProfile && (
					<div className="h-[70px] white  bg-white flex gap-[5rem] border rounded-[12px] pt-2 pl-[2rem]">
						<div>
							<div className="font-[400] text-[16px] leading-[20px] text-[#8B8D97]">
								Total Contractors
							</div>
							<div className="font-[700] text-[24px] leading-[28px] text-amber-500">
								{contractor}
							</div>
						</div>

						<div>
							<div className="font-[400] text-[16px] leading-[20px] text-[#8B8D97]">
								Approve Contractors
							</div>
							<div className="font-[700] text-[24px] leading-[28px] text-amber-500">
								{ContractorResponse?.data?.approveContractor}
							</div>
						</div>

						<div>
							<div className="font-[400] text-[16px] leading-[20px] text-[#8B8D97]">
								Pending Contractors Requests
							</div>
							<div className="font-[700] text-[24px] leading-[28px] text-amber-500">
								{ContractorResponse?.data?.pendingContractor}
							</div>
						</div>
						<div>
							<div className="font-[400] text-[16px] leading-[20px] text-[#8B8D97]">
								Deleted Contractors
							</div>
							<div className="font-[700] text-[24px] leading-[28px] text-amber-500">
								{ContractorResponse?.data?.deletedContractor}
							</div>
						</div>
					</div>
				)} */}
    </div>

  );
};
