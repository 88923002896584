import { AllCategoriesResponse, InventoryCategoryProduct, InventoryProduct, InventoryResponse, changeStatusResponse, updatePincode } from "../../../types/api-types";
import { Inventory, Product, Products } from "../../../types/types";
import { lhwpath } from "../../apiRoutes";
import { api } from "../rtkAPI";
import { CompanySearchResponse } from "../types";

export const InventoryQuery: any = api.injectEndpoints({
    endpoints: (builder) => ({  
   
       changeStatus: builder.mutation<changeStatusResponse, {id:string ,status: string}>({
          
           query: ({id,status}) => {
            // console.log("fgrds",id,status);
            return {
               url: `${lhwpath}/global/customer/admin/publish/category/${id}`,
               method: "PATCH",
               body: {
                publish: status 
               }
            }
        },
          invalidatesTags: ["inventory"],
       }),      

       changeProductStatus: builder.mutation<changeStatusResponse,{_id:string,status:string} >({
        query: ({_id,status}) => {
            // console.log("fgrds",itemCode,status);
              return {
            url: `${lhwpath}/global/customer/admin/publish/item?id=${_id}`,
            method: "PATCH",
            body: {
             publish: status 
            }
        }
        },
        invalidatesTags:["category"],
    }),


    changeCompanyStatus: builder.mutation<any,{id:string,status:string} >({
        query: ({id,status}) => {
            // console.log("fgrds",id,status);
              return {
            url: `${lhwpath}/global/customer/admin/publish/company/${id}`,
            method: "PATCH",
            body: {
             publish: status 
            }
        }
        },
        invalidatesTags:["category"],
    }),


    adminCompanies : builder.query<any, { page?: number; limit?: string; search?: string }>({
        query: ({ page, limit, search = "" }) => {
            const constructUrl = (page?: number, limit?: string, search?: string): string => {
              const params = new URLSearchParams();
              if (page) params.append("page", page.toString());
              if (limit) params.append("limit", limit);
              if (search) params.append("search", search);
            
              return params.toString()
                ? `${lhwpath}/global/customer/admin/getCompanyList/?${params.toString()}`
                :   `${lhwpath}/global/customer/admin/getCompanyList/`;
            };
        
            return {
              url: constructUrl(page, limit, search),
              method: "GET",
            };
          },
    }),

    searchCompany : builder.query<CompanySearchResponse,  any>({
        query: (id) => ({
            url: `/global/searchAssists/${id}`,
            method: "GET",
        })
    }),
  


    }),
})

export const { useGetCategoriesQuery, useChangeStatusMutation, 
    useChangeProductStatusMutation, useAdminCompaniesQuery , useChangeCompanyStatusMutation ,
 useSearchCompanyQuery } = InventoryQuery;


