import { api } from "../rtkAPI";

export const ImageQuery: any = api.injectEndpoints({
  endpoints: (builder) => ({
    createImageURL: builder.mutation<any, string>({
      query: (body) => {
        return {
          url: `/imageService/upload`,
          method: "POST",
          body
        };
      },
    //   providesTags: ["Rewards"],
    }),

    uploadImage: builder.mutation<any, any>({
        query: ({formState,id}) => {
            console.log(formState,id,"datas")
          return {
            url: `/global/customer/admin/publish/category/addImageCarouselDetails/${id}`,
            method: "PATCH",
             body: formState
          };
        },
      //   providesTags: ["Rewards"],
      }),



  }),
});

export const {
  useCreateImageURLMutation,
  useUploadImageMutation
} = ImageQuery;
