import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Item, ItemData, Status } from "./types";
import { useOrderTrackerMutation } from "../../Redux/Api/Order";
import { toast } from "react-toastify";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";
import OrderDetailsComponent from "./OrderDetailsComponent";
import ProcessingOrder from "./ProcessingOrder";


export const TrackYourOrder = () => {
  const params = useParams<{ name?: string }>();
  const name = params.name || ""; // Default to empty string if not provided
  const decodedName = decodeURIComponent(name);


  const [mutation, { data, error }] = useOrderTrackerMutation();

  const location = useLocation();
  useEffect(() => {
    if (decodedName) {
      mutation(decodedName);
    }
  }, [decodedName, mutation]);


  useEffect(() => {
    if (data?.success) {
      setItemData(data);
    } else if (data && !data?.success) {
      toast.error("Invalid order number. Please enter a valid tracking number.");
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error(error);
      toast.error("There was an error processing your request. Please try again.");
    }
  }, [error]);

  useEffect(() => {
    document.title = "Order Tracking Page - Chawla Ispat";
  }, []);

  const [itemData, setItemData] = useState<ItemData | null>(null);

  const title = "Order Tracking | Building Material Supplier | Chawla Ispat";
  const description = "Track your orders and view status updates on the Tracking Page of Chawla Ispat.";
  const canonicalUrl = chawlaispat;

  usePageMeta(title, description, canonicalUrl);

  const salesOrderData = itemData?.salesOrder?.salesOrderData
  const lengthItem = itemData?.salesOrder?.items?.length
  const items: Item[] = itemData?.salesOrder?.items || []

  return (
    <>
      <div className="w-full max-w-[1200px] mx-auto mt-4 px-2 lg:px-5 mb-6">
        <OrderDetailsComponent salesOrderData={salesOrderData} decodedName={decodedName} lengthItem={lengthItem} />
        <ProcessingOrder items={items}/>
      </div>
    </>
  );
};

// <div className="bg-white">
// <div className="flex flex-col gap-[48px] py-[24px] bg-white h-full justify-center items-center pb-[200px] mt-[3.56rem]">
//   <div className="p-[24px] flex flex-col gap-[48px] border w-[80%] sm:w-[70%] rounded-lg">
//     {/* Order Number and Total Items Section */}
//     <div className="hidden md:flex justify-between">
//       <span className="font-bold text-[30px] leading-[41px]">
//         Order Number: {" "}<span className="text-turkishBlue-default">{decodedName}</span>
//       </span>
//       <span className="font-[400] text-[30px] leading-[41px]">
//         Total Items:
//         <b className="font-bold text-[30px] leading-[41px] text-turkishBlue-default">{itemData?.salesOrder?.items?.length}</b>
//       </span>
//     </div>

//     {/* Shipping Address Section */}
//     <div className="flex flex-col p-[12px] gap-[12px] border rounded-lg">
//       <div className="font-[700] text-[24px] leading-[32px]">
//         Shipping Address
//       </div>
//       <div className="justify-between flex flex-col md:flex-row">
//         <div className="font-[600] text-[16px] leading-[20px] text-[#5097A4]">
//           {itemData?.salesOrder?.salesOrderData?.customer?.customerName}
//         </div>
//         <div className="font-[700] text-[12px] leading-[20px] text-[#5097A4]">
//           Phone No:{" "}
//           <span className="text-black">
//             {itemData?.salesOrder?.salesOrderData?.customer?.phoneNumber}
//           </span>
//         </div>
//         <div className="font-[700] text-[12px] leading-[20px] text-[#5097A4]">
//           State:{" "}
//           <span className="text-black">
//             {itemData?.salesOrder?.salesOrderData?.shippingAddress?.state}
//           </span>
//         </div>
//         <div className="font-[700] text-[12px] leading-[20px] text-[#5097A4]">
//           City:{" "}
//           <span className="text-black">
//             {itemData?.salesOrder?.salesOrderData?.shippingAddress?.city}
//           </span>
//         </div>
//         <div className="font-[700] text-[12px] leading-[20px] text-[#5097A4]">
//           Postal:{" "}
//           <span className="text-black">
//             {
//               itemData?.salesOrder?.salesOrderData?.shippingAddress
//                 ?.pinCode
//             }
//           </span>
//         </div>
//       </div>
//     </div>
//   </div>

//   {/* Product Details Section */}
//   <div className="p-[24px] flex flex-col gap-[48px] border  w-[80%] sm:w-[70%] rounded-lg">
//     <div className="flex justify-between">
//       <span className="font-bold text-[30px] leading-[41px]">
//         Product Details
//       </span>
//     </div>

//     <div className=" gap-[12px] p-[12px] flex flex-col border rounded-lg">
//       {itemData &&
//         itemData?.salesOrder?.items.map((item: Item, index: number) => {
//           const taxableValue = item?.taxableValue || 0;
//           const gst = item?.gst || 0;
//           const gstValue = (taxableValue * gst) / 100;
//           const netValue = taxableValue + gstValue;

//           return (
//             <div
//               key={index}
//               className="grid grid-cols-1 md:grid-cols-3 gap-2 p-4 border rounded-lg"
//             >
//               <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
//                 Item Name:
//                 <span className="text-black font-[600] text-[12px] leading-[20px]">
//                   {item?.item?.name} {item?.item?.cid?.name}
//                 </span>
//               </div>
//               <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
//                 Item Code:
//                 <span className="text-black font-[600] text-[12px] leading-[20px]">
//                   {item?.item?.code}
//                 </span>
//               </div>
//               <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
//                 Dispatch Quantity:
//                 <span className="text-black font-[600] text-[12px] leading-[20px]">
//                   {item?.dispatchQuantity} {item?.unit}
//                 </span>
//               </div>
//               <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
//                 Quantity:
//                 <span className="text-black font-[600] text-[12px] leading-[20px]">
//                   {item?.quantity}
//                 </span>
//               </div>
//               <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
//                 Total Rate:
//                 <span className="text-black font-[600] text-[12px] leading-[20px]">
//                   ₹{netValue.toFixed(2)}
//                 </span>
//               </div>
//               <div className="text-[#5097A4] font-[600] text-[12px] leading-[20px]">
//                 Total Rate x Quantity:
//                 <span className="text-black font-[600] text-[12px] leading-[20px]">
//                   ₹{(netValue * item.quantity).toFixed(2)}
//                 </span>
//               </div>
//             </div>
//           );
//         })}
//     </div>
//   </div>

//   {/* Progress Bar Section */}
//   <div className="relative w-[70%] hidden md:flex border rounded-lg h-auto flex-col justify-center items-center px-2 py-2">
//     {itemData?.salesOrder?.items?.map((item: Item, itemIndex: number) => {
//       const completedStatus =
//         item.status?.map((status: Status) => status.value) || [];
//       // Map the values of the 'status' property of 'item' into an array, or use an empty array if 'status' is undefined

//       // Find the last valid status present in the steps array
//       const lastValidStatus = completedStatus.reduce((prev, curr) => {
//         // Reduce the 'completedStatus' array to find the last valid status
//         if (steps.some((step) => step.value === curr)) {
//           // Check if the current status is present in the 'steps' array
//           return curr; // If it is, return it as the new 'lastValidStatus'
//         }
//         return prev; // If not, keep the previous 'lastValidStatus'
//       }, "");

//       // Find the index of the last valid status in the 'steps' array
//       const lastCompletedIndex = steps.findIndex(
//         (step) => step.value === lastValidStatus
//       );

//       // Calculate the width of the progress bar based on the index of the last valid status
//       const progressBarWidth =
//         lastCompletedIndex >= 0
//           ? ((lastCompletedIndex + 1) / steps?.length) * 100 // If a valid status is found, calculate the width
//           : 0; // If no valid status is found, set the width to 0
//       // Output the calculated progress bar width

//       return (
//         <div
//           key={itemIndex}
//           className="relative flex flex-col justify-between items-start border rounded-lg w-full py-[13px] px-[13px] overflow-hidden mb-4"
//         >
//           <div className="w-full">
//             <span className="text-black font-[600] text-[16px] leading-[20px]">
//               {item?.item?.name} {item?.item?.cid?.name}
//             </span>
//           </div>

//           <div className="relative flex flex-col md:flex-row justify-between items-center w-full">
//             {/* Progress Bar */}
//             <div className="md:absolute top-1/4 transform -translate-y-1/2 w-[98%]  h-2 border rounded-lg bg-gray-200">
//               <div
//                 className="bg-[#5097a4] h-full border rounded-lg"
//                 style={{ width: `${progressBarWidth}%` }}
//               ></div>
//             </div>

//             {/* Circles and Steps */}
//             {steps.map((step, index) => {

//               const statusData = item?.status.find(
//                 (status) => status.value === step?.value
//               );
//               const statusDate = statusData ? statusData?.date : null;
//               const isCompleted = index <= lastCompletedIndex


//               return (
//                 <div
//                   key={index}
//                   className="flex flex-row md:flex-col items-center w-full relative"
//                 >
//                   {/* Circle for each step */}
//                   <div
//                     className={`w-8 h-8 rounded-full ${isCompleted ? "bg-[#5097a4]" : "bg-gray-300"
//                       } flex items-center justify-center`}
//                   ></div>
//                   <p className="text-xs font-semibold mt-2 text-center">
//                     {step.title}
//                   </p>
//                   {statusDate && (
//                     <p className="text-xs font-semibold">
//                       {new Date(statusDate).toLocaleString()}
//                     </p>
//                   )}
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       );
//     })}
//   </div>

//   <div className="relative w-[80%] rounded-lg flex flex-col md:hidden gap-[12px] h-full px-2 py-2 justify-center items-center">
//     {itemData?.salesOrder?.items?.map((item: Item, itemIndex: number) => {
//       const completedStatus =
//         item.status?.map((status: Status) => status.value) || [];

//       const lastValidStatus = completedStatus.reduce((prev, curr) => {
//         // Reduce the 'completedStatus' array to find the last valid status
//         if (steps.some((step) => step?.value === curr)) {
//           // Check if the current status is present in the 'steps' array
//           return curr; // If it is, return it as the new 'lastValidStatus'
//         }
//         return prev; // If not, keep the previous 'lastValidStatus'
//       }, "");

//       // Find the index of the last valid status in the 'steps' array
//       const lastCompletedIndex = steps?.findIndex(
//         (step) => step.value === lastValidStatus
//       );

//       // Calculate the width of the progress bar based on the index of the last valid status
//       const progressBarWidth =
//         lastCompletedIndex >= 0
//           ? ((lastCompletedIndex + 1) / steps?.length) * 100 // If a valid status is found, calculate the width
//           : 0; // If no valid status is found, set the width to 0


//       return (
//         <div
//           key={itemIndex}
//           className="relative flex gap-[8px] items-center w-full h-[270px] border rounded-lg px-2 py-2"
//         >
//           <div className="h-full border bg-gray-200 rounded-full dark:bg-gray-700 relative">
//             <div
//               className="bg-[#5097a4] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full absolute left-0 top-0"
//               style={{
//                 height: `${progressBarWidth
//                   }%`,
//               }}
//             ></div>
//           </div>

//           <div className="flex flex-col gap-[16px] w-full">
//             {steps.map((step, index) => {
//               const statusData =
//                 itemData?.salesOrder?.items?.[0]?.status?.find(
//                   (status: Status) => status.value === step.value
//                 );
//               const statusDate = statusData ? statusData.date : null;
//               const isCompleted = index <= lastCompletedIndex
//               return (
//                 <div
//                   key={index}
//                   className="flex flex-row gap-2 md:flex-col items-center w-full relative"
//                 >
//                   <div
//                     className={`w-4 h-4 relative rounded-full -ml-[0.93rem] ${isCompleted ? "bg-[#5097a4]" : "bg-gray-300"
//                       } flex items-center justify-center`}
//                   ></div>
//                   <p className="text-xs font-semibold mt-2 mx-[4px] text-center">
//                     {step?.title}
//                   </p>
//                   {statusDate && (
//                     <p className="text-xs font-semibold mt-2 text-[12px] padding-[16px]">
//                       {new Date(statusDate).toLocaleString()}
//                     </p>
//                   )}
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       );
//     })}
//   </div>
// </div>

// </div>