import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { useChangeProductStatusMutation } from "../../../Redux/Api/Admin/Inventory";
import ItemStatus from "./InventoryStatus2";
import { useDispatch } from "react-redux";
import { useGetAdminCategoryProductsQuery } from "../../../Redux/Api/Category";
import defaultImage from "../../../assets/item.png";
import { RewardData } from "../../../Redux/reducer/adminReducer";
import TableNoData from "../../../common/admin-common/TableNoData";
import TableLoading from "../../../common/admin-common/TableLoading";

const InventoryViewItem = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { name, id } = location.state || {};

	useEffect(() => {
		if (name && id) {
			dispatch(RewardData({ name, id }));
		}
	}, [name, id, dispatch]);

	const [search, setSearch] = useState("");
	// const [inputValue, setInputValue] = useState("");
    

	const {
		data: categoryProduct,
		refetch,
		isLoading,
	} = useGetAdminCategoryProductsQuery({ name, search }, { skip: !name });

	useEffect(() => {
		if (name) {
			// No need for the try/catch block since the hook handles error and loading states
		}
	}, [name]);

	const [changeStatusMutation] = useChangeProductStatusMutation();

	// Function to handle changes in the input field
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// setInputValue(event.target.value);
		setSearch(event.target.value);
	};

	const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);

	const handleTogglePopup = (index: number) => {
		setOpenPopupIndex((prevIndex) => (prevIndex === index ? null : index));
	};


	const handleStatusChange = async (
		status: string,
		_id: string,
		index: number
	) => {
		if (status === "publish") {
			await changeStatusMutation({ _id, status: true });
		}
		if (status === "unPublish") {
			await changeStatusMutation({ _id, status: false });
		}
		refetch();
		handleTogglePopup(index);
	};

	const handleItemName = (id: any) => {
		navigate(`/admin/category/item/${id}`, {
			state: { id: id },
		});
	};


	return (
		<>
			<div className="w-full h-auto px-3 mt-[13px] py-3">
				<div className="w-full  bg-white rounded-t-xl mb-4 pt-2">
					<div className="flex justify-between py-2 px-4">
						<div className="font-medium flex ">
							Inventory{" "}
							<span className="text-[#3B82F6] pl-[0.45rem]">
								({name})
							</span>
						</div>
						<div className="flex gap-2">
							<input
								type="text"
								value={search}
								onChange={handleChange}
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
								placeholder="Search.."
								style={{
									boxShadow:
										"0px 0px 7.6px 0px #00000040 inset",
								}}
							/>
						</div>
					</div>
					<div className="w-full overflow-x-auto">
						<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
							<thead className="bg-white divide-y divide-gray-200">
								<tr className="border-t">
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]"></th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">image</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
										Item
									</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
										Item code
									</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
										description
									</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
										Total Value
									</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
										Cash Margin
									</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
										Online Margin
									</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
										Active Company
									</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
										Status
									</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap  font-inter text-[#1E293B]"></th>
								</tr>
							</thead>
							<tbody className="bg-white ">
								{isLoading ? (
									<TableLoading colCount={9} />
								) : categoryProduct?.product?.length > 0 ? (
									categoryProduct?.product?.map(
										(i: any, index: any) => (
											<tr key={i?._id}>
												<td className="px-2 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]"></td>
												<td className="px-2 py-4 whitespace-nowrap text-nowrap font-inter text-sm text-[#6E7079]">
													<img
														className="w-[36px] h-[36px] rounded-full "
														alt="Category Product"
														src={
															i?.image
																? i?.image
																: defaultImage
														}
													/>
												</td>
												<td
													className="px-5 py-4  font-inter text-sm text-[#6E7079] cursor-pointer min-w-[300px] max-w-[300px]"
													onClick={() =>
														handleItemName(i._id)
													}>
													{i.name}
												</td>
												<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
													{i.itemCode}
												</td>
												<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
													{i.description}
												</td>
												<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
													{i.taxableValue.toFixed(2)}
												</td>
												<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
													<div className="flex gap-[5px]">
														{(
															(i.margin /
																i.taxableValue) *
															100
														).toFixed(2)}
														%{i.margin.toFixed(2)}
													</div>
												</td>
												<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
													<div className="flex gap-[5px]">
														{(
															(i.marginOnline /
																i.taxableValue) *
															100
														).toFixed(2)}
														%
														{i.marginOnline.toFixed(
															2
														)}
													</div>
												</td>
												<td
													className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]"
													onClick={() =>
														navigate(
															`/admin/company`
														)
													}>
													{i.companyName}
												</td>

												<ItemStatus
													key={index}
													i={i}
													index={index}
													handleStatusChange={
														handleStatusChange
													}
													openPopupIndex={
														openPopupIndex
													}
													handleTogglePopup={
														handleTogglePopup
													}
												/>
												<td className="px-2 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]"></td>
											</tr>
										)
									)
								) : (
									<TableNoData
										colCount={9}
										text={"No Data Avaiable"}
									/>
								)}
							</tbody>
						</table>
					</div>
					<div className="flex flex-row justify-between py-2 px-3 m-1 border-t-2 border-[#E1E2E9]">
						<div>
							<div className="flex flex-row gap-3">
								<p className="text-[#666666]">
									{categoryProduct?.product?.length} items
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="bg-white rounded-lg  mx-4 my-4 px-4 py-3 mb-2">
				<div className="flex flex-row justify-between">
					<div className="">
						<h1 className="font-inter text-sm">
							Category
							<span className="text-[#3B82F6] pl-[0.45rem]">
								({name})
							</span>
						</h1>
					</div>

					<div className="flex flex-row gap-3 ">
					
						<div className="w-full flex flex-row items-center p-1 gap-3 border border-[#CFD3D4] rounded-lg">
							<span>
								<FiSearch
									size={20}
									className="text-[#130F26]"
								/>
							</span>
							<input
								type="text"
								value={search}
								onChange={handleChange}
								placeholder="Search"
								className="outline-none focus:outline-none text-xs"
							/>
						</div>
					</div>
				</div>

				
				<div className="overflow-x-auto">
					<table className="table-auto min-w-full divide-y divide-gray-200 mt-3">
						<thead className="bg-gray-50">
							<tr>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Item
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Item code
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									description
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Total Value
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Cash Margin
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Online Margin
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Active Company
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Status
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{categoryProduct?.product?.map(
								(i: any, index: any) => (
									<tr key={i?._id}>
								
										<td className="px-2 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											<img
												className="w-[36px] h-[36px] "
												alt="Category Product"
												src={
													i?.image
														? i?.image
														: defaultImage
												}
											/>
										</td>
										<td
											className="px-5 py-4  font-inter text-sm text-[#6E7079] cursor-pointer width-[200px]"
											onClick={() =>
												handleItemName(i._id)
											}>
											{i.name}
										</td>
										<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.itemCode}
										</td>
										<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.description}
										</td>
										
										<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.taxableValue.toFixed(2)}
										</td>
										<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											<div className="flex gap-[5px]">
												{(
													(i.margin /
														i.taxableValue) *
													100
												).toFixed(2)}
												%{i.margin.toFixed(2)}
											</div>
										</td>
										<td className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											<div className="flex gap-[5px]">
												{(
													(i.marginOnline /
														i.taxableValue) *
													100
												).toFixed(2)}
												%{i.marginOnline.toFixed(2)}
											</div>
										</td>
										<td
											className="px-5 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]"
											onClick={() =>
												navigate(`/admin/company`)
											}>
											{i.companyName}
										</td>
										<ItemStatus
											key={index}
											i={i}
											index={index}
											handleStatusChange={
												handleStatusChange
											}
											openPopupIndex={openPopupIndex}
											handleTogglePopup={
												handleTogglePopup
											}
										/>
									</tr>
								)
							)}
						</tbody>
					</table>
				</div>

				<div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
					<div>
						<div className="flex flex-row gap-3">
							<p className="text-[#666666]">
								{categoryProduct?.product?.length} items
							</p>
						</div>
					</div>
				</div>
			</div> */}
		</>
	);
};

export default InventoryViewItem;
