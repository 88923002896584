import { ChangeEvent, useEffect, useRef, useState } from "react";
import Pagination from "../../ItemsPage/pagination";
import QuotationTrackerFilters from "./QuotationTrackerFilters";
import { toast } from "react-toastify";
import QuotationTabs from "./QuotationTabs";
import checkedPng from "../../../assets/profile/quotationTrackerImages/Checked.png";
import failedPng from "../../../assets/profile/quotationTrackerImages/Failed.png";
import pendingPng from "../../../assets/profile/quotationTrackerImages/Pending.png";
import whatshapPng from "../../../assets/profile/quotationTrackerImages/Whatsapp.png";
import { CustomPopupModal } from "../../../utils/CustomModal";


type FiltersTypes = { date: string; status: string };
const QuotationTracker = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenMpdal, setIsOpenModal] = useState<boolean>(false)
	const [search, setSearch] = useState<string>("");
	const [filters, setFilters] = useState<FiltersTypes>({
		date: "",
		status: "",
	});
	const [activeFilter, setActiveFilter] = useState("Pending (03)");
	const filterConditions = [
		"All (16)",
		"Resolved (12)",
		"Cancelled (01)",
		"Pending (03)",
	];

	const handleFilterClick = (condition: string) => {
		setActiveFilter(condition);
	};

	const dropDownRef = useRef<HTMLDivElement | null>(null);

	const handleOutSideClick = (event: MouseEvent): void => {
		if (
			dropDownRef.current &&
			!dropDownRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener("mousedown", handleOutSideClick);
		} else {
			document.removeEventListener("mousedown", handleOutSideClick);
		}
		return () => {
			document.removeEventListener("mousedown", handleOutSideClick);
		};
	}, [isOpen]);

	const handleFilterChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
		const { name, value } = e.target;
		setFilters((prev) => ({ ...prev, [name]: value }));
	};
	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setSearch(e.target.value);
	};

	const resetFilters = () => {
		setFilters({ date: "", status: "" });
	};

	const applyFilters = () => {
		toast.success("Filters Applied Succussfully !");
		console.log("Filters applied:", filters);
		setIsOpen(false);
	};

	const handleClose = () => {
		setIsOpenModal(false)
	}


	return (
		<div className="profile-container relative font-inter   ">
			<div className="flex flex-col xl:flex-row items-start  gap-5 xl:items-center xl:justify-between mb-3">
				<div className="flex flex-col sm:flex-row gap-2 w-full mr-auto xl:ml-auto ">
					<div className="relative w-full sm:w-auto">
						<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
							<svg
								aria-hidden="true"
								className="w-5 h-5 text-[#5097A4]"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
									clipRule="evenodd"
								/>
							</svg>
						</div>
						<input
							type="text"
							id="simple-search"
							className="border-2 border-[#5097A4] text-gray-900 text-sm rounded-xl block w-full pl-10 p-2 focus:outline-[#5097A4]"
							placeholder="Search"
							value={search}
							onChange={handleSearchChange}
						/>
					</div>

					<div className="flex flex-col md:flex-row items-center justify-between w-full md:w-auto space-y-2 md:space-y-0 md:space-x-3">
						<div
							ref={dropDownRef}
							className="relative flex items-center w-full md:w-auto">
							<button
								onClick={() => setIsOpen(!isOpen)}
								className="flex items-center w-full sm:w-auto justify-center py-2 px-4 text-sm font-medium bg-[#5097A4] text-white rounded-xl border"
								type="button">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
									className="h-4 w-4 mr-2"
									viewBox="0 0 20 20"
									fill="currentColor">
									<path
										fillRule="evenodd"
										d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
										clipRule="evenodd"
									/>
								</svg>
								Filter
								<svg
									className="-mr-1 ml-1.5 w-5 h-5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true">
									<path
										clipRule="evenodd"
										fillRule="evenodd"
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
									/>
								</svg>
							</button>
							{isOpen && (
								<QuotationTrackerFilters
									filters={filters}
									handleChange={handleFilterChange}
									applyFilters={applyFilters}
									resetFilters={resetFilters}
								/>
							)}
						</div>
					</div>
				</div>

				<div className="px-2 py-1 flex flex-col   sm:flex-row  gap-2 items-center  w-full md:w-fit rounded-xl  border-2 sm:border-2 border-[#5097A4] font-inter ">
					{filterConditions.map((condition) => (
						<QuotationTabs
							key={condition}
							condition={condition}
							isActive={activeFilter === condition}
							onClick={handleFilterClick}
						/>
					))}
				</div>
			</div>
			<div className="w-full h-auto rounded-xl border-2 border-gray-200  p-4 flex flex-col gap-4 py-5 mt-2">
				<div className="flex flex-col sm:flex-row w-full items-center sm:items-start  sm:justify-between">
					<h2 className="3 text-xl sm:text-2xl font-bold mb-2 sm:mb-0">
						Query Details
					</h2>
					<div className="  text-[10px] sm:text-xs flex flex-col items-center gap-1">
						<span className="text-[#7B7B7B]">
							{" "}
							<span className="font-inter font-bold text-[#141414]">
								Sales Person Name :
							</span>{" "}
							Not issue yet{" "}
						</span>
						<span className="text-[#7B7B7B] flex gap-1">
							<img
								src={whatshapPng}
								className="h-4 w-4 object-contain"
								alt=""
							/>
							Not issue yet{" "}
						</span>
					</div>
					<button onClick={() => setIsOpenModal(true)} className="text-sm font-medium bg-[#019D08] text-white px-3 py-2 rounded-xl focus:ring-2 ring-[#D9D9D9] hidden sm:flex">
						Price & Validity
					</button>
				</div>
				<div className="text-[#605959] font-medium text-xs sm:text-sm">
					Date : 29-10-2024
				</div>
				<p className="text-[#404040] text-xs sm:text-sm">
					During the Query Initialization phase, Looker is performing
					all of the tasks that are required before the query is sent
					to your database. The Query Initialization phase includes
					the following tasks:
				</p>

				<div className="w-full p-4 relative sm:p-4 sm:border border-gray-300 rounded-xl sm:h-[120px]">
					<ol className="hidden sm:flex items-center w-[80%] mx-auto justify-between">
						<li className="flex items-center w-full relative   after:content-[''] after:w-full after:h-1 after:border-b after:border-[#16A085] after:border-4 after:inline-block ">
							<span className="flex items-center justify-center  bg-blue-100 rounded-full h-8 w-8  shrink-0">
								<img
									src={checkedPng}
									alt=""
									className="h-full w-full"
								/>
							</span>
							<p className="absolute top-10 left-[-50px] flex flex-col gap-2 items-center">
								<span className="font-medium text-xs">
									Query Timestamp
								</span>
								<span className="text-xs text-[#868383]">
									29-10-2024, 10:02:50 AM
								</span>
							</p>
						</li>
						<li className="flex w-full relative items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-[#AEAEAE] after:border-4 after:inline-block ">
							<span className="flex items-center justify-center  bg-gray-100 rounded-full h-8 w-8 dark:bg-gray-700 shrink-0">
								<img
									src={checkedPng}
									alt=""
									className="h-full w-full"
								/>
							</span>
							<p className="absolute top-10 left-[-15px]  flex flex-col gap-2 items-center">
								<span className="font-medium text-xs">
									Status
								</span>
								<span className="text-xs text-[#868383]">
									( Pending )
								</span>
							</p>
						</li>
						<li className="flex items-center relative">
							<span className="flex items-center justify-center  bg-gray-100 rounded-full h-8 w-8 dark:bg-gray-700 shrink-0">
								<img
									src={checkedPng}
									alt=""
									className="h-full w-full"
								/>
							</span>
							<p className="absolute top-10 right-[-60px] flex flex-col gap-2 items-center w-[180px]">
								<span className="font-medium text-xs">
									Resolve Timestamp
								</span>
								<span className="text-xs text-[#868383]">
									29-10-2024, 10:02:50 AM
								</span>
							</p>
						</li>
					</ol>
					<ol className="relative block sm:hidden text-gray-500 border-s border-[#16A085]  ">
						<li className="mb-10 ms-6">
							<span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -start-4 ring-4 ring-white">
								<img
									src={checkedPng}
									alt=""
									className="h-full w-full"
								/>
							</span>
							<h3 className="font-medium leading-tight text-xs">
								Query Timestamp
							</h3>
							<p className="text-[10px]">
								( 29-10-2024, 10:02:50 AM )
							</p>
						</li>
						<li className="mb-10 ms-6">
							<span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
								<img
									src={checkedPng}
									alt=""
									className="h-full w-full"
								/>
							</span>
							<h3 className="font-medium leading-tight text-xs">
								Status
							</h3>
							<p className="text-[10px]">( Complete )</p>
						</li>
						<li className="ms-6">
							<span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
								<img
									src={checkedPng}
									alt=""
									className="h-full w-full"
								/>
							</span>
							<h3 className="font-medium leading-tight text-xs">
								Resolve Timestamp
							</h3>
							<p className="text-[10px]">
								( 29-10-2024, 10:02:50 AM )
							</p>
						</li>
					</ol>

					<div className="flex justify-center w-full">
						<button onClick={() => setIsOpenModal(true)} className=" text-[10px] sm:text-sm font-medium bg-[#019D08] text-white px-3 py-2 rounded-xl focus:ring-2 mt-6 mx-auto bottom-2 right-1 ring-[#D9D9D9] sm:hidden">
							Price & Validity
						</button>
					</div>
				</div>
			</div>

			<CustomPopupModal isOpen={isOpenMpdal} onClose={handleClose} title={"Price & Validity"} width="md:max-w-xs" >
				<div className="p-4 border rounded-xl ">
					<div className="flex justify-between mb-2">
						<span className="font-semibold">Price:</span>
						<span className="text-right text-green-500">{`₹${5000.00}`}</span> 
					</div>
					<div className="flex justify-between">
						<span className="font-semibold">Validity:</span>
						<span className="text-right">30 days</span> 
					</div>
				</div>
			</CustomPopupModal>


		</div>
	);
};

export default QuotationTracker;
