import React from "react";
import { FaHandshake } from "react-icons/fa";
import img1 from "../../../assets/homeImageSection1.jpeg";
import img2 from "../../../assets/homeImageSection2.jpeg";
import { useNavigate } from "react-router-dom";
const AboutSection = () => {
    const navigate = useNavigate();
    return (
        <div className="w-full  bg-[#5097A4] rounded-[14px] lg:rounded-[70px] px-3 py-3 sm:p-[30px]   flex flex-col justify-center  gap-[12px] my-3 lg:my-6 ">
            <div className="w-full  h-full    rounded-[14px]    flex flex-col justify-center lg:flex-row gap-[20px] my-4 text-justify ">
                <div
                    className=" 
                     hidden    lg:flex rounded-[14px]    lg:w-[300px] h-[300px] 2xl:h-[250px]  overflow-hidden ">
                    <img
                        src={img2}
                        className="w-full h-full object-cover hidden lg:block mt-2 rounded-[14px]"
                        alt="chaw"
                    />
                </div>

                <p className="font-[400] w-full lg:w-2/3 flex flex-wrap text-[16px] md:text-[17px] leading-[30px] text-white  ">
                    Chawla Ispat Pvt Ltd, a distinguished name in the iron and
                    steel industry, has a rich heritage dating back to its
                    inception as Chawla Enterprises in 2002. Rebranded in 2007,
                    we have consistently set benchmarks for quality and
                    reliability. Guided by our visionary chairman, Chaudhary Lal
                    Chawla, and supported by our five dedicated managing
                    directors—Gurbaksh Singh Chawla, Santokh Singh Chawla,
                    Mahendra Singh Chawla, Gurbaj Singh Chawla, and Simprabh
                    Singh Chawla—we have cemented our reputation as a trusted
                    provider of premium building materials.Our unwavering
                    commitment to excellence and innovation ensures that every
                    client receives exceptional service and products, making
                    Chawla Ispat Pvt Ltd a leader in the industry.

                </p>
            </div>
            <div className="flex  justify-center  w-full ">
                <div
                    className="group relative w-[151px] h-[48px] cursor-pointer"
                    onClick={() => navigate("/contactus")}>
                    {/* Default state */}
                    <div className="bg-[#EAEDED] flex h-[48px] w-[151px] justify-center items-center border rounded-tr-[50px] rounded-bl-[50px] gap-[4px] shadow-lg shadow-[#475569] transition-opacity duration-300 absolute inset-0 group-hover:opacity-0">
                        <FaHandshake className="text-[#FC5220] w-[19px] h-[18px]" />
                        <div className="text-slate-500 text-sm font-semibold font-['Inter'] leading-tight">
                            Get in touch
                        </div>
                    </div>
                    {/* Hover state */}
                    <div className="bg-[#FC5220] flex h-[48px] w-[151px] justify-center items-center border rounded-tr-[50px] rounded-bl-[50px] gap-[4px] shadow-lg transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-2xl absolute inset-0 overflow-hidden">
                        <FaHandshake className="text-white w-[19px] h-[18px] opacity-0 transition-opacity duration-100 delay-300 group-hover:opacity-100" />
                        <div className="text-white text-sm font-semibold font-['Inter'] leading-tight transition-transform duration-300 transform translate-x-full group-hover:translate-x-0">
                            Get in touch
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutSection;
