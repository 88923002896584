import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import ItemStatus from "./InventoryStatus2";
import defaultImage from "../../../assets/item.png";
import { useChangeProductStatusMutation } from "../../../Redux/Api/Admin/Inventory";
import { useGetCompanySearchesQuery } from "../../../Redux/Api/Admin/Company";
import TableNoData from "../../../common/admin-common/TableNoData";
import TableLoading from "../../../common/admin-common/TableLoading";

export const InventoryCompanyProduct = () => {
	const [inputValue, setInputValue] = useState("");
	// const [selectedOption, setSelectedOption] = useState<number>(10);
	// const [isChecked, setIsChecked] = useState(false);
	// const [selectedRows, setSelectedRows] = useState<number[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [limit, setLimit] = useState("");
	const itemperPage = 10;
	const { name } = useParams();
	const [search, setSearch] = useState("");
	const {
		data: ProductResponse,
		refetch,
		error,
		isLoading,
	} = useGetCompanySearchesQuery({ company: name, search });
	const [changeStatusMutation] = useChangeProductStatusMutation();

	// const totalPages = Math.ceil((ProductResponse?.length || 0) / selectedOption);
	// const [updateProductMutation] = useChangeCompanyStatusMutation();
	const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);

	const handleTogglePopup = (index: number) => {
		setOpenPopupIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	// Function to handle changes in the input field
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// setInputValue(event.target.value);
		setSearch(event.target.value);
	};

	// const toggleRow = (rowId: number) => {
	//   if (selectedRows.includes(rowId)) {
	//     setSelectedRows(selectedRows.filter((id) => id !== rowId));
	//   } else {
	//     setSelectedRows([...selectedRows, rowId]);
	//   }
	// };

	// const options = ["9", "8", "7"];

	// const handleSelect = (option: any) => {
	//   setSelectedOption(option);
	//   setLimit(option);
	// };

	// const handleCheckboxChange = () => {
	//   setIsChecked(!isChecked);
	// };

	const handleStatusChange = async (
		status: string,
		_id: string,
		index: number
	) => {
		if (status === "publish") {
			await changeStatusMutation({ _id, status: true });
		}
		if (status === "unPublish") {
			await changeStatusMutation({ _id, status: false });
		}
		refetch();
		handleTogglePopup(index);
	};

	const filteredItems = ProductResponse
		? ProductResponse.filter((i: any) =>
				i.name.toLowerCase().includes(inputValue.toLowerCase())
		  )
		: [];

	const indexOfLastItem = currentPage * itemperPage;
	const indexOfFirstItem = indexOfLastItem - itemperPage;

	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	if (error) {
		console.error("Error fetching data:", error);
		return <div>Error fetching data</div>;
	}

	return (
		<>
			<div className="w-full h-auto px-3 mt-[13px] py-3 mb-10">
				<div className="w-full  bg-white rounded-t-xl mb-4 pt-2">
					<div className="flex justify-between py-2 px-4">
						<div className="font-medium">Companies</div>
						<div className="flex gap-2">
							<input
								type="text"
								value={search}
								onChange={handleChange}
								className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
								placeholder="Search.."
								style={{
									boxShadow:
										"0px 0px 7.6px 0px #00000040 inset",
								}}
							/>
						</div>
					</div>
					<div className="w-full overflow-x-auto">
						<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
							<thead className="bg-white divide-y divide-gray-200">
								<tr className="border-t">
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]"></th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
										Image
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Product
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Price
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Description
									</th>
									<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
										Status
									</th>
									<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]"></th>
								</tr>
							</thead>
							<tbody className="bg-white ">
								{isLoading ? (
									<TableLoading colCount={7} />
								) : currentItems?.length > 0 ? (
									currentItems.map(
										(i: any, index: number) => (
											<tr key={i?.id}>
												<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]"></td>
												<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
													<img
														onError={(
															e: React.SyntheticEvent<
																HTMLImageElement,
																Event
															>
														) => {
															const target =
																e.target as HTMLImageElement;
															target.onerror =
																null;
															target.src =
																defaultImage;
														}}
														src={
															i?.image ||
															defaultImage
														}
														className="w-[30px] h-[30px] rounded-[12px]"
														alt="Product Image"
													/>
												</td>
												<td className="pl-4 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
													{i?.name}
												</td>
												<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
													{i?.netRate.toFixed()}
												</td>
												<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
													{i?.description}
												</td>
												<ItemStatus
													key={index}
													i={i}
													index={index}
													handleStatusChange={
														handleStatusChange
													}
													openPopupIndex={
														openPopupIndex
													}
													handleTogglePopup={
														handleTogglePopup
													}
												/>
											</tr>
										)
									)
								) : (
									<TableNoData
										colCount={7}
										text={"No Data Avaiable"}
									/>
								)}
							</tbody>
						</table>
					</div>
					<div className="flex flex-row justify-between p-3 m-1 border-t-2 border-[#E1E2E9]">
							<div className="flex flex-row gap-3">
								<p>{currentItems?.length} Company Products</p>
							</div>
					</div>
				</div>
			</div>

			{/* <div className="bg-[#FFFFFF] rounded-lg mx-4 my-4 px-4 py-3">
				<div className="flex flex-row justify-between">
					<div className="font-inter">
						<h1 className="text-[#4B5563]">Companies</h1>
					</div>
					<div className="flex flex-row gap-3 ">
		
						<div className="w-full flex flex-row items-center p-1 gap-3 border border-[#CFD3D4] rounded-lg">
							<span>
								<FiSearch
									size={20}
									className="text-[#130F26]"
								/>
							</span>
							<input
								type="text"
								value={search}
								onChange={handleChange}
								placeholder="Search"
								className="outline-none focus:outline-none text-xs"
							/>
						</div>
					</div>
				</div>


				<div className="overflow-x-auto">
					<table className="table-auto min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Product
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Price
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Description
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Status
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{isLoading ? (
								<tr>
									<td
										colSpan={5}
										className="px-6 py-4 text-center">
										Loading...
									</td>
								</tr>
							) : error ? (
								<tr>
									<td
										colSpan={5}
										className="px-6 py-4 text-center text-red-500">
										Error fetching data. Please try again.
									</td>
								</tr>
							) : !ProductResponse ||
							  ProductResponse.length === 0 ? (
								<tr>
									<td
										colSpan={5}
										className="px-6 py-4 text-center">
										No products currently available.
									</td>
								</tr>
							) : (
								currentItems.map((i: any, index: number) => (
									<tr key={i?._id}>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											<img
												onError={(
													e: React.SyntheticEvent<
														HTMLImageElement,
														Event
													>
												) => {
													const target =
														e.target as HTMLImageElement;
													target.onerror = null;
													target.src = defaultImage;
												}}
												src={i?.image || defaultImage}
												className="w-[30px] h-[30px] rounded-[12px]"
												alt="Product Image"
											/>
										</td>
										<td className="pl-4 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i?.name}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i?.netRate.toFixed()}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i?.description}
										</td>
										<ItemStatus
											key={index}
											i={i}
											index={index}
											handleStatusChange={
												handleStatusChange
											}
											openPopupIndex={openPopupIndex}
											handleTogglePopup={
												handleTogglePopup
											}
										/>
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>

				<div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
					<div>
						<div className="flex flex-row gap-3">
							<p>{currentItems?.length} Company Products</p>
						</div>
					</div>
					<div>
					</div>
				</div>
			</div> */}
		</>
	);
};
