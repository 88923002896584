import { useEffect, useState } from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import {
  useApproveClaimRewardsMutation,
  useGetRequestClaimRewardsMutation
} from "../../../Redux/Api/Admin/Reward";
import { LiaCoinsSolid } from "react-icons/lia";
import { ConfirmModal } from "../../../utils/CustomModal";
import { exportToExcel } from "../../../utils/exportToExcel";
import useDebounce from "../../../common/hooks/useDebounce";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Dropdown from "../Inventory/components/Dropdown";
import TableNoData from "../../../common/admin-common/TableNoData";
import TableLoading from "../../../common/admin-common/TableLoading";

export const RequestReward = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number | "All">(10);
  const [search, setSearch] = useState<string>("");
  const [requestClaimRewardMutation, { data, isLoading }] = useGetRequestClaimRewardsMutation();

  const [changeMutation] = useApproveClaimRewardsMutation();

  const [rewardId, setRewardId] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleApproveButton = (id: string) => {
    setRewardId(id);
    setShowPopup(true);
  };

  const confirmApproval = () => {
    if (rewardId) {
      console.log(rewardId);
      changeMutation(rewardId);
    }
    setShowPopup(false);
    setRewardId(null);
  };

  const closePopup = () => {
    setShowPopup(false);
    setRewardId(null);
  };

  const exportData = () => {
    const filteredData = (data?.rewardData || []).map(
      (item: any, i: number) => ({
        "S.No": i + 1,
        Name: item.contractorName,
        Contact: item.phoneNumber,
        "Assign Package": item.packageName,
        "Contractor Reward": item.contractorTotalReward,
        "Package Reward": item.packageCoin,
        "Claim Approval": item.claimStatus ? "Claimed" : "Pending",
      })
    );

    exportToExcel(filteredData, "RequestRewardData");
  };

  const debounceSearch = useDebounce(search, 300);
  const fetchClaimedRewards = () => {
    requestClaimRewardMutation({
      search: debounceSearch,
      page: currentPage,
      limit: limit === "All" ? data?.total : limit,
    });
  };
  useEffect(() => {
    fetchClaimedRewards();
  }, [debounceSearch, currentPage, limit]);


  const totalItems = data?.total || 0;
  const totalPages = limit === "All" ? 1 : Math.ceil(totalItems / Number(limit));
  const startItemIndex = (currentPage - 1) * (limit === "All" ? totalItems : limit) + 1;
  const endItemIndex = Math.min(currentPage * (limit === "All" ? totalItems : limit), totalItems);

  const options = ["10", "9", "8", "All"];

  const handleSelect = (option: string) => {
    const numericLimit = option === "All" ? "All" : parseInt(option);
    setLimit(numericLimit);
    setCurrentPage(1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const resetFilters = () => {
    setSearch("");
    setLimit(10);
    setCurrentPage(1);
  }
  return (
    <>
      <div className="w-full h-auto px-3 mt-3 pb-3">
        <div className="w-full bg-white rounded-t-xl mb-4 pt-2 z-[-1px]">
          <div className="flex justify-between py-2 px-4">
            <div className="flex items-center gap-2">
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full sm:w-[200px] py-2 px-3"
                placeholder="Search.."
                style={{
                  boxShadow: "0px 0px 7.6px 0px #00000040 inset",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="flex gap-2">
              <button onClick={resetFilters} className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
                Reset
              </button>
              <button
                onClick={exportData}
                className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
                Excel{" "}
                <RiFileExcel2Fill className="w-[20px] h-[20px]" />
              </button>
            </div>
          </div>

          <div className="w-full overflow-x-auto">
            <table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
              <thead className="bg-white divide-y divide-gray-200">
                <tr className="border-t">
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Contact
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Assign Package
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Contractor Reward
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Package Reward
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
                    Claim Approval
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {
                  isLoading ? (
                    <TableLoading colCount={6} />
                  ) :
                    data?.rewardData.length > 0 ? (
                      data?.rewardData.map((reward: any) => (
                        <tr
                          key={reward._id}
                          className="border-t">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B] flex items-center">
                            <img
                              src={reward.image}
                              alt={reward.image}
                              className="w-12 h-12 object-cover rounded-full mr-3"
                            />
                            {reward.contractorName}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
                            {reward.phoneNumber}
                            {/* {reward.contractorTotalReward} */}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B] flex items-center">
                            <img
                              src={reward.packageImage}
                              alt={reward.packageName}
                              className="w-12 h-12 object-cover rounded-full mr-3"
                            />
                            {reward.packageName}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
                            <span className="flex items-center">
                              <LiaCoinsSolid className="text-[#FF9900] text-3xl" />
                              {
                                reward.contractorTotalReward
                              }
                            </span>
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
                            <span className="flex items-center">
                              <LiaCoinsSolid className="text-[#FF9900] text-3xl" />
                              {reward.packageCoin}
                            </span>
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-[#1E293B]">
                            <button
                              className="bg-blue-100 border text-blue-700 border-blue-700  px-3 py-2  rounded-xl hover:text-white hover:bg-blue-700 duration-300 text-sm"
                              onClick={() =>
                                handleApproveButton(
                                  reward._id
                                )
                              }>
                              Approve
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <TableNoData colCount={6} text={"No rewards available."} />
                    )
                }
              </tbody>
            </table>
          </div>

          <div className="flex flex-row justify-between p-2 m-1 border-t-2 border-[#E1E2E9]">
            <div className="flex flex-row gap-3">
              <Dropdown options={options} onSelect={handleSelect} />
              <p className="text-[#A6A8B1]">Items per page</p>
              <p className="text-[#666666]">
                Displaying {limit === "All" ? "All" : `${startItemIndex} to ${endItemIndex}`} items
              </p>
            </div>
            <div className="flex flex-row gap-3">
              <p className="text-[#666666]">
                {currentPage} of {limit === "All" ? 1 : `${totalPages}`} pages
              </p>
              <div className="flex flex-row items-center gap-2 text-[#666666]">
                <span
                  onClick={prevPage}
                  className={`cursor-pointer ${currentPage === 1 && "text-gray-400 cursor-not-allowed"}`}>
                  <FaChevronLeft size={15} />
                </span>
                <span
                  onClick={nextPage}
                  className={`cursor-pointer ${currentPage === totalPages && "text-gray-400 cursor-not-allowed"}`}>
                  <FaChevronRight size={15} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        isOpen={showPopup}
        handleConfirm={confirmApproval}
        handleClose={closePopup}
        text={"Are you sure you want to approve this reward?"}
        heading="Confirm Approval ?"
        buttonBgColor="bg-[#334155] hover:bg-opacity-90"
      />
    </>
  );
};
