import { useLocation } from "react-router-dom";
import ItemRewardComponent from "./components/itemRewardComponent";

type Props = {};

const InventaoryItemPage = (props: Props) => {
	const location = useLocation();
	const { id } = location.state || {};

	return <ItemRewardComponent id={id} />;
};

export default InventaoryItemPage;
