
import { ClipLoader } from 'react-spinners'

interface LoadingProps {
    colCount: number
}
const TableLoading = ({ colCount }: LoadingProps) => {
    return (
        <td colSpan={colCount} className="text-center">
            <div className="w-full flex justify-center h-[100px] items-center">
                <ClipLoader />
            </div>
        </td>
    )
}

export default TableLoading