import React, { useEffect, useState } from "react";
import CustomModal from "../../../utils/CustomModal";
import { useCreateProductMutation, useGetUnitsQuery } from "../../../Redux/Api/priceAPI";
import { toast } from "react-toastify";

interface IQueryProps {
    onClose: () => void;
    isOpen: boolean;
    productId: string | undefined
}


const QuotationForm = ({ isOpen, onClose, productId }: IQueryProps) => {
    const { data } = useGetUnitsQuery();
    const [createProductQuery, { isLoading }] = useCreateProductMutation();
    const [formData, setFormData] = useState({
        quantity: "",
        unitId: "",
        contact: "",
        query: "",
        productId: "",
    });

    useEffect(() => {
        if (productId) {
            setFormData((prev) => ({ ...prev, productId }));
            localStorage.setItem("productId", productId);
        }

        return () => {
            localStorage.removeItem("productId");
        };
    }, [productId]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === "contact" || name === "quantity") {
            setFormData((prev) => ({
                ...prev,
                [name]: value ? Number(value) : "",
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const validateForm = () => {
        const { quantity, unitId, contact } = formData;
        if (!quantity) return "Quantity is required.";
        if (!unitId) return "Unit selection is required.";
        if (!contact) return "Mobile number is required.";
        if (!/^\d{10}$/.test(contact)) {
            return "Mobile number must be 10 digits.";
        }

        return null;
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const error = validateForm();
        if (error) {
            toast.error(error);
            return;
        }

        const convertedFormData = {
            ...formData,
            quantity: Number(formData.quantity),
            contact: Number(formData.contact),
        };
        try {
            await createProductQuery(convertedFormData).unwrap();
            toast.success("Product Quotation created successfully!");
            onClose();
        } catch (error) {
            toast.error("Error creating product quotation. Please try again.");
        }


        setFormData({
            quantity: "",
            unitId: "",
            contact: "",
            query: "",
            productId: formData.productId,
        });
    };

    const resetForm = () => {
        setFormData({
            quantity: "",
            unitId: "",
            contact: "",
            query: "",
            productId: productId || "",
        });
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };


    return (
        <CustomModal
            isOpen={isOpen}
            onClose={handleClose}
            title="Query Form"
        >
            <form className="flex flex-wrap w-full justify-between" onSubmit={handleSubmit}>
                <div className="mb-3 sm:w-[55%] w-full flex flex-col">
                    <label className="text-xs font-[400] mb-2" htmlFor="quantity">
                        Quantity<span className="text-red-500">*</span>
                    </label>
                    <div className="h-[40px] flex text-xs">
                        <input
                            type="number"
                            name="quantity"
                            value={formData.quantity === "" ? "" : Number(formData.quantity)}
                            onChange={handleChange}
                            className="rounded-l-lg border-[1.5px] border-[#5097A4] px-3 py-1 h-full focus:border-[#5097A4] outline-none transition duration-300 ease-in-out w-[60%]"
                            placeholder="Quantity (Number)"
                        />
                        <select
                            name="unitId"
                            value={formData.unitId}
                            onChange={handleChange}
                            className="bg-[#5097A4] text-white rounded-r-lg border-[1.5px] border-[#5097A4] pl-1 h-full focus:border-[#5097A4] outline-none transition duration-300 ease-in-out w-[40%] pr-3"
                        >
                            <option className="bg-white text-black hover:bg-[#5097A4]" value="">select..</option>
                            {data && data.map((item: any) => (
                                <option
                                    key={item._id}
                                    className="bg-white text-black hover:bg-[#5097A4]"
                                    value={item._id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="mb-3 sm:w-[40%] w-full flex flex-col text-xs">
                    <label className="text-xs font-[400] mb-2" htmlFor="mobileNumber">
                        Mobile Number<span className="text-red-500">*</span>
                    </label>
                    <div className="h-[40px] text-xs">
                        <input
                            type="number"
                            name="contact"
                            value={formData.contact === "" ? "" : Number(formData.contact)}
                            onChange={handleChange}
                            className="rounded-lg border-[1.5px] border-[#5097A4] w-full pl-3 py-1 h-full focus:border-[#5097A4] outline-none transition duration-300 ease-in-out"
                            placeholder="Enter mobile number"
                        />
                    </div>
                </div>

                <div className="mb-3 w-full flex flex-col">
                    <label className="text-xs font-[400] mb-2" htmlFor="query">
                        Query
                    </label>
                    <div className="h-auto text-xs">
                        <textarea
                            name="query"
                            rows={4}
                            value={formData.query}
                            onChange={handleChange}
                            className="rounded-lg border-[1.5px] border-[#5097A4] w-full pl-3 py-1 h-full focus:border-[#5097A4] outline-none transition duration-300 ease-in-out"
                            placeholder="Enter Query"
                        />
                        <span className="font-bold text-[11px] flex justify-end text-[#5097A4] text-wrap ">
                            “Your query will be resolved within 10 minutes, guaranteed assistance!”
                        </span>
                    </div>
                </div>

                <div className="flex justify-between mt-10 sm:mt-5 space-x-4 text-xs w-full">
                    <button
                        type="button"
                        className="px-3 py-2 text-white ml-auto bg-red-500 rounded-lg hover:bg-red-600 transition duration-300 ease-in-out w-1/2"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                    <button
                        type="submit"
                        className="px-3 py-2 text-white ml-auto bg-[#5097A4] rounded-lg  hover:bg-[#47848e] transition duration-300 ease-in-out w-1/2"
                    >
                        {isLoading ? "Submitting.." : "Submit"}
                    </button>
                </div>
            </form>
        </CustomModal>
    );
};

export default QuotationForm;
