import { ChangeEvent, useEffect, useState } from "react";
import { useGetReferralOrdersMutation } from "../../../Redux/Api/Order";
import { Link, useNavigate } from "react-router-dom";
import { RiFileExcel2Fill } from "react-icons/ri";
import TableLoading from "../../../common/admin-common/TableLoading";
import TableNoData from "../../../common/admin-common/TableNoData";
import { exportToExcel } from "../../../utils/exportToExcel";


const OrderRequest = () => {
  const navigate = useNavigate();
  const [salesPersonName, setSalesPersonName] = useState<string>("")
  const [orderNumber, setOrderNumber] = useState<string>("")
  const [mutation, { data: referralOrders, isLoading }] = useGetReferralOrdersMutation();

  useEffect(() => {
    const body = { referralOrder: false };
    mutation(body);
  }, [mutation]);


  const handleSalesPersonNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSalesPersonName(e.target.value.toLowerCase())
  }

  const handleOrderNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setOrderNumber(e.target.value.toLowerCase())
  }

  const filteredOrders = referralOrders?.orders?.filter((order: any) =>
    order?.orderNumber.toLowerCase().includes(orderNumber) &&
    order?.createdBy?.toLowerCase().includes(salesPersonName)
  );

  const resetAll = (): void => {
    setSalesPersonName("");
    setOrderNumber("");
  }

  const exportData = () => {
    const filteredData = (filteredOrders || []).map((item: any) => ({
      "Date": item?.createdAt?.split("T")[0] || "-",
      "Order Number": item?.orderNumber || "-",
      "Sales Person Name": item?.createdBy || "-",
      "Loading Point (Godown)": item?.godowns.join(', ') || "-",
      "Bill No": item?.billNumber || "-",
      "Order Status": item?.status || "-",
      "Referral By (Contractor)": item?.contractorName || "-",
    }));

    exportToExcel(filteredData, "orderdata")
  }


  return (
    <>
      <div className="flex justify-between py-2 px-4">
        <div className="flex items-center gap-2">
          <input
            type="text"
            onChange={handleSalesPersonNameChange}
            value={salesPersonName}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
            placeholder="Sales Person Name.."
            style={{
              boxShadow: "0px 0px 7.6px 0px #00000040 inset",
            }}
          />
          <input
            type="text"
            onChange={handleOrderNumberChange}
            value={orderNumber}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
            placeholder="Order Number.."
            style={{
              boxShadow: "0px 0px 7.6px 0px #00000040 inset",
            }}
          />

        </div>
        <div className="flex gap-2">
          <button
            onClick={resetAll}
            className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
            Reset
          </button>
          <button
            onClick={exportData}
            className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
            Excel{" "}
            <RiFileExcel2Fill className="w-[20px] h-[20px]" />
          </button>
        </div>

      </div>
      <div className="w-full overflow-x-auto pt-2">
        <table className="table-auto min-w-full divide-y divide-gray-200 relative">
          <thead className="bg-white divide-y divide-gray-200">
            <tr className="border-t">
              <th className="px-6 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                Date
              </th>
              <th className="px-6 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                Order Number
              </th>
              <th className="px-6 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                Sales Person Name
              </th>
              <th className="px-6 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                Loadin point(Godown)
              </th>
              <th className="px-6 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                Bill No
              </th>
              <th className="px-6 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                Order Status
              </th>
              <th className="px-6 py-3 text-nowrap text-left text-sm font-inter text-[#1E293B]">
                Referral By (Contractor)
              </th>
            </tr>
          </thead>
          <tbody className="bg-white ">
            {
              isLoading ? <tr>
                <TableLoading colCount={7} />
              </tr> : filteredOrders?.length > 0 ? filteredOrders?.map((i: any, index: number) => (
                <tr key={index}>
                  <td className="px-6 py-4 text-nowrap font-inter text-sm text-[#6E7079] ">
                    {i?.createdAt.split("T")[0]}
                  </td>
                  <td className="px-6 py-4 text-nowrap font-inter text-sm  text-[#0078FB] cursor-pointer">
                    <Link to={i._id}>{i?.orderNumber} </Link>
                  </td>
                  <td className="px-6 py-4 text-nowrap font-inter text-sm text-[#6E7079]">
                    <Link to="pending"> {i?.createdBy} </Link>
                  </td>
                  <td className="px-6 py-4 text-nowrap font-inter text-sm text-[#6E7079]">
                    {i?.godowns.map((el: any) => {
                      return <span>{el}</span>;
                    })}
                  </td>
                  <td className="px-6 py-4 text-nowrap font-inter text-sm text-[#6E7079]">
                    {i?.billNumber}
                  </td>
                  <td className="px-6 py-4 text-nowrap font-inter text-sm text-[#6E7079]">
                    {i?.status}
                  </td>
                  <td
                    className="px-6 py-4 text-nowrap font-inter text-sm text-[#0078FB] cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/admin/contractors/profile/${i?.contractorId}`
                      )
                    }>
                    {i?.contractorName}
                  </td>
                </tr>
              )) :
                <TableNoData colCount={7} text={"Data Not Available"} />

            }

          </tbody>
        </table>
      </div>
    </>

  );
};

export default OrderRequest;
