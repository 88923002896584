import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { lhwpath } from '../apiRoutes';

const usePageView = () => {
    const [views, setViews] = useState(0);
    const [websiteViews, setWebsiteViews] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const incrementViewCount = async () => {
            try {                
                const pathSegments = location.pathname.split('/').filter(Boolean);
                const pageName = pathSegments.length > 0 ? pathSegments[0] : 'Home';
                const hasVisited = sessionStorage.getItem(`hasVisited_${pageName}`);
                const hasVisitedWebsite= sessionStorage.getItem('hasVisitedWebsite'); 
                // console.log(hasVisitedWebsite)
                            
                if (!hasVisited) {                
                    const response = await axios.post(
                        `${lhwpath}/global/incrementview`,
                        { pageName },
                        { withCredentials: true }
                    );                                
                    setViews(response.data.totalViews);                
                    sessionStorage.setItem(`hasVisited_${pageName}`, 'true');
                }else{
                    const response = await axios.post(
                        `${lhwpath}/global/incrementview`,
                        { pageName:null },
                        { withCredentials: true }
                    );   
                    setViews(response.data.totalViews);  
                }

                if(!hasVisitedWebsite){
                    const websiteResponse = await axios.get(
                        `${lhwpath}/global/incrementwebsiteview`, 
                        { withCredentials: true }
                    );
                    setWebsiteViews(websiteResponse.data.totalWebsiteViews);
                    sessionStorage.setItem('hasVisitedWebsite', 'true'); 
                }

            } catch (error) {
                console.error('Error updating view count:', error);
            }
        };

        incrementViewCount();

        // Optional: Log the view count for debugging purposes
        // console.log("View Count:", views);
    }, [location]);

    return views;
};

export default usePageView;
