import React, { useEffect, useState } from 'react';
import ProductCard from '../../common/ProductCard';
import { useNavigate } from 'react-router-dom';
import { useCategoriesNameQuery } from '../../Redux/Api/NamesAPI';
import { CombinedState } from '../../types/api-types';
import { useSearchFiltersMutation } from '../../Redux/Api/ProductAPI';
import { usePriceMutation } from '../../Redux/Api/priceAPI';

interface Price {
  id: string;
  finalPrice: number;
  unit: string;
}

interface products {
  id: string;
  images: string;
  productName: string;
}

interface Data {
  id: string;
  image: string;
  itemCount: number;
  name: string;
}

interface Props {
  data: Data[];
}

const SecondCategory: React.FC<Props> = ({data}) => {
  const navigate = useNavigate();
  const [searchFilters, { data: SearchProductsResponse, isLoading }] = useSearchFiltersMutation();
  const [combinedState, setCombinedState] = useState<CombinedState>({
    company: [],
    category: [],
    limit: 12,
    page: 1,
  });
  const [priceMutation] = usePriceMutation();
  const [priceData, setPriceData] = useState<{ [key: string]: Price }>({});
  const [products, setProducts] = useState<products[]>([]);

  const id = data?.[1]?.id;
  const name = data?.[1]?.name;

  useEffect(() => {
    if (id) {
      setCombinedState((prevState) => ({
        ...prevState,
        category: [id],
        page: 1,
      }));
    }
  }, [id]);

  useEffect(() => {
    const fetchProducts = async () => {
       try {
          const productResponse = await searchFilters({combinedState})
          setProducts(productResponse?.data?.products);
       } catch (error) {
         console.log(error,"Error fetching products");        
       }
    }
   if(combinedState.category && combinedState.category?.length >0){
       fetchProducts()
    }
 },[combinedState,searchFilters])
 



  if (isLoading) {
    return <div className='bg-white px-8 py-4'>Loading...</div>;
  }

  return (
    <div className='bg-[#FFFFFF] w-full  mx-auto  py-5'>
      <div className='flex justify-between items-center p-3 mx-auto md:mx-24'>
      <div className='text-[#1E293B] font-inter text-[1.1rem] md:text-[24px] leading-[32px] font-semibold'>
        {name}
        </div>
        <div className='cursor-pointer' onClick={() => navigate("/items", { state: { id } })}>
          <span className='font-inter font-semibold text-[1rem] text-[#5097A4]'>
            Shop the collection →
          </span>
        </div>
      </div>
      <div className='flex flex-wrap justify-center md:justify-between gap-10 mx-auto md:mx-24 my-3 p'>
      {SearchProductsResponse?.products?.slice(0, 4).map((i: any) => {
       
       //  console.log(id,productPriceData)
      return (
       <ProductCard
       key={i?.id}
       data={i}
     />
       )        
   })}
      </div>
    </div>
  );
};

export default SecondCategory;
