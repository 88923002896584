import { useEffect, useState } from "react";
import { useOrderTrackerMutation } from "../../Redux/Api/Order";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image from "../../assets/letter_send 1.png";
import gif from "../../assets/truck-4147.gif"
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";



export const Tracking = () => {
  const [search, setSearch] = useState<string>("");
  const [mutation, { data: tracking, error }] = useOrderTrackerMutation();
  const navigate = useNavigate();
  const handleChange = () => {
    mutation(search);
  };


  useEffect(() => {
    if (tracking?.success) {
      toast.success("Order found! Redirecting...");
      const encodedSearch = encodeURIComponent(search);
      setTimeout(() => {
        navigate(`/tracking/${encodedSearch}`, {
          state: {
            data: tracking,
            search: search
          }
        });
      }, 1000);
    } else if (tracking && !tracking?.success) {
      toast.error("Invalid order number. Please enter a valid tracking number.");
    }
  }, [tracking, navigate, search]);


  useEffect(() => {
    if (error) {
      console.error(error);
      toast.error("There was an error processing your request. Please try again.");
    }
  }, [error]);

  const title = "Tracking Page | Building Material Supplier | Chawla Ispat";
  const description = "Track your orders and view status updates on the Tracking Page of Chawla Ispat.";
  const baseUrl = chawlaispat;
  usePageMeta(title, description, baseUrl);
  return (
    <div className="h-full bg-white">
      <div className="font-inter flex flex-col items-center justify-center w-full bg-white mt-5 gap-[40px] px-[12px] md:px-[80px] pt-[20px] pb-[80px] border-black">
        <div className="flex flex-col items-center justify-center gap-[12px] mt-[24px] ">
          <h2 className="text-[700] text-[20px] md:text-[36px] leading-[43px]">
            Track Your Order
          </h2>
          <h3 className="text-[500] text-[12px] md:text-[16px] leading-[14px] md:leading-[19px]">
            Have a question or need assistance?{" "}
            <span
              className="text-blue-500 underline cursor-pointer"
              onClick={() => navigate("/contactus")}
            >
              Contact us!
            </span>
          </h3>
        </div>

        <div className="relative w-full h-[500px] md:h-[478px]">
          <img
            src={image}
            className="w-full h-full object-cover hidden md:block"
            alt="Tracking illustration"

          />
          <img
            src={gif}
            className="w-[500px] lg:w-[40%] xl:w-[700px]  object-cover hidden lg:flex top-[20%] xl:top-[10%] left-6 absolute z-20  "
            alt="Tracking illustration"
          />
          <div className="absolute inset-0 rounded-[16px] bg-gradient-to-b from-[#5097A4] to-transparent shadow-xl"></div>
          <div className="absolute inset-0 md:top-10 md:right-10 flex justify-center md:justify-end items-center md:items-start">
            <div className="border  w-full md:w-auto md:max-w-[406px] h-full md:h-[398px] rounded-[16px] p-[20px] md:p-[40px] flex justify-center items-center relative z-30">
              <div className="w-full max-w-[326px] h-full md:h-[318px] flex flex-col justify-between">
                <h2 className="font-extrabold text-[20px] md:text-[32px] text-white leading-[38px]">
                  Track your Order
                </h2>

                <div>
                  <img src={gif} className="md:hidden" alt={"truck"} />
                </div>
                <div className="flex gap-[24px] md:gap-[48px] flex-col">
                  <div className="flex flex-col gap-[8px]">
                    <h3 className="font-extrabold text-[16px] md:text-[20px] text-white leading-[20px] flex whitespace-nowrap">
                      Enter Your Tracking Number
                    </h3>
                    <input
                      type="text"
                      className="bg-transparent text-white border-b-2 border-b-white p-2 rounded focus:outline-none w-full"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value.trim());
                      }}
                    />
                  </div>
                  <button
                    className="text-black bg-white py-[12px] flex justify-center items-center w-full border rounded-md font-extrabold text-[14px] leading-[20px]"
                    onClick={handleChange}
                  >
                    Track
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      {/* <ToastContainer /> */}
    </div>
  );
};
