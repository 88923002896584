import search from "../../assets/Search.png";

const NoResult = () => {
  return (
    <div className="w-full h-full flex justify-center items-center    flex-col">
      <div className=" flex justify-center items-center flex-col w-[200px] h-full mt-3">
        <div>
          <img src={search} alt="search"  className="object-contain w-16" />
        </div>

        <div className="flex flex-col">
          <span className="text-sm">No results found</span>
          <span className="font-[600] text-sm leading-[20px] text-[#6366F1] text-center  border-b-[#6366F1]">
            Search Again
          </span>
        </div>
      </div>
    </div>
  );
};

export default NoResult;
