import ImgCard from './ImgCard';
import {Link} from 'react-router-dom'
import { CardData } from './types';

interface Data {
  id: string;
  image: string;
  itemCount: number;
  name: string;
}

interface Props {
  data: Data[];
}

const CategoryCard: React.FC<Props> = ({data}) => {

  return (
    <div className='bg-[#ffffff] w-[23rem] h-[23rem] p-6'> 
      <div className='font-inter font-bold text-md pb-2'>Build your home With us</div>
      <div className='flex flex-col gap-4'>  
        <div className='flex gap-2 md:gap-4 flex-wrap cursor-pointer'>
          {data?.slice(4,8).map((category: CardData) => (   
            <ImgCard key={category?.id} id={category?.id} category={category}/>
          ))}
        </div>  
        <Link to='/items'>     
            <h2 className='text-[#007185] font-inter text-md font-semibold mt-4'>Browse all our products</h2>  
        </Link>  
      </div>
    </div>
  );
}

export default CategoryCard;
