import React from 'react';

interface FilterOptionProps {
    condition: string;
    isActive: boolean;
    onClick: (condition: string) => void;
}

const QuotationTabs = ({ condition, isActive, onClick }:FilterOptionProps) => {
    return (
        <span
            onClick={() => onClick(condition)}
            role="button"
            tabIndex={0}
            className={`py-1 px-2 sm:px-10 lg:px-6 font-semibold w-full text-center text-[14px] cursor-pointer rounded-lg transition-all duration-200 ease-in-out text-nowrap flex gap-1 ${
                isActive
                    ? "text-white bg-[#5097A4]"
                    : "text-gray-600 hover:text-[#5097A4] bg-transparent"
            }`}
        >
            {condition}
        </span>
    );
};

export default QuotationTabs;
