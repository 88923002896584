import { useEffect, useState } from "react";
import { useGetAdminContractorHistoryMutation } from "../../../Redux/Api/Order";
import { useNavigate, useParams } from "react-router-dom";

import { BiCoinStack } from "react-icons/bi";

const ContractorOrderHistory = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	const [mutation, { data: orderData }] =
		useGetAdminContractorHistoryMutation();

	useEffect(() => {
		mutation(id);
	}, [mutation, id]);

	// State to track expanded item by index
	const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

	// Function to toggle expanded state based on index
	const toggleExpanded = (index: number) => {
		setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const handleNavigateToCompleteOrderDetails = (orderId: string) => {
		navigate(`/admin/orders/complete/${orderId}`);
	};

	const handleNavigate = (e: any, orderId: any) => {
		console.log("testing", e, orderId);
		e.stopPropagation();
		handleNavigateToCompleteOrderDetails(orderId);
	};
	return (
		<section className="min-h-screen ">
			{orderData?.data?.length > 0 &&
				orderData?.data?.map((item: any, index: number) => {
					const isExpanded = expandedIndex === index;
					return (
						<div key={index}>
							{item.customerName ? (
								<div className="">
									<div
										className="relative w-full  justify-between text-[#7D7D7D] h-auto rounded-xl bg-white p-4 font-medium space-y-1  box-border"
										style={{
											boxShadow:
												"0px 4px 4px 0px #00000040",
										}}>
										<div className="flex justify-between items-center pb-3">
											<div>
												<div
													onClick={(e) =>
														handleNavigate(
															e,
															item.orderId
														)
													}
													className="text-lg font-semibold text-gray-800 cursor-pointer ">
													Order Number:{" "}
													<div
														className="text-blue-600 hover:underline "
														style={{
															display: "inline",
														}}>
														{item.orderNumber}
													</div>
												</div>
												<p className="text-[#888888]">
													Customer Name:{" "}
													{item?.customerName}
												</p>
											</div>
											<div className="flex items-center space-x-3">
												<div className="text-lg font-semibold">
													{item?.type === "add" ? (
														<span className="text-[#00CB4A]">
															+{item?.totalCoins}
														</span>
													) : (
														<span className="text-[#FF0000]">
															{item?.totalCoins}
														</span>
													)}
												</div>
												<BiCoinStack className="h-10 w-10 text-[#FF9900]" />
											</div>
										</div>

										<div className="mt-4 flex justify-between text-sm text-gray-500">
											<div>
												<span className="font-semibold">
													Edited By:{" "}
												</span>
												{item?.approveBy}
											</div>
											<div>
												<span className="font-semibold">
													Date:{" "}
												</span>
												{item?.createdAt.split(" ")[0]}{" "}
												{item?.createdAt.split(" ")[1]}
											</div>
											<div>
												<span className="font-semibold text-orange-500">
													Current Coins:{" "}
													{item?.currentCoins}
												</span>
											</div>
										</div>
										<div className="flex justify-center items-center">
											<div
												onClick={() =>
													toggleExpanded(index)
												}
												className=" flex justify-center items-center w-6 h-6 border rounded-full hover:bg-[#334155] hover:text-white  cursor-pointer">
												<svg
													className={`w-4 h-4 transition-transform ease-in-out transform ${isExpanded
														? "rotate-180"
														: "rotate-0"
														}`}
													fill="none"
													stroke="currentColor"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg">
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth={2}
														d="M19 9l-7 7-7-7"
													/>
												</svg>
											</div>
										</div>
									</div>
									<div
										className={`transition-all duration-500 ease-in-out transform flex flex-col h-auto  items-center mb-3 ${isExpanded
											? "scale-100 opacity-100 max-h-screen"
											: "scale-95 opacity-0 max-h-0"
											}`}
										style={{ transformOrigin: "top" }}>
										<div className="p-2 bg-[#F1F1F1] w-[95%] rounded-b-xl h-full shadow-lg">
											<div className="flex items-start gap-2.5 w-full">
												<div className="flex flex-col gap-1 w-full">
													<div className="mt-2 space-y-4">
														{item?.itemData?.map(
															(
																subItem: any,
																subIndex: number
															) => (
																<div
																	key={
																		subItem.combinedName
																	}
																	className="flex justify-between items-center p-4 bg-white rounded-lg   transition-all"
																	style={{
																		boxShadow:
																			"1px 2px 4px 0px #00000040 inset",
																	}}>
																	<div className="text-md font-semibold text-gray-700">
																		{subIndex +
																			1}
																		.{" "}
																		{
																			subItem.combinedName
																		}
																	</div>
																	<div className="flex items-center space-x-2 text-lg font-semibold text-yellow-500">
																		<BiCoinStack className="h-7 w-7 text-[#FF9900]" />
																		<span>
																			{
																				subItem.coins
																			}
																		</span>
																	</div>
																</div>
															)
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div>
									<div
										className="w-full  justify-between text-[#7D7D7D] h-auto rounded-xl bg-white p-4 font-medium space-y-1  box-border"
										style={{
											boxShadow:
												"0px 4px 4px 0px #00000040",
										}}>
										<div className="flex justify-between items-center">
											<div>
												<h3 className="text-lg font-semibold text-gray-700">
													Bonus Coins {item?.type === "add" ? "Awarded" : "Deducted"}
												</h3>
												<p className="text-gray-600">
													Approved By:{" "}
													{item?.approveBy}
												</p>
											</div>
											<div className="flex items-center space-x-3">
												<div className="text-lg font-semibold">
													{item?.type === "add" ? (
														<span className="text-green-600">
															+{item?.coins}
														</span>
													) : (
														<span className="text-red-500">
															-{item?.coins}
														</span>
													)}
												</div>
												<BiCoinStack className="h-10 w-10 text-[#FF9900]" />
											</div>
										</div>

										<div className="mt-4 flex justify-between text-sm text-gray-500">
											<div>
												<span className="font-semibold">
													Date:{" "}
												</span>
												{item?.createdAt.split(" ")[0]}{" "}
												{item?.createdAt.split(" ")[1]}
											</div>
											<div>
												<span className="font-semibold text-orange-500">
													Current Coins:{" "}
													{item?.currentCoins}
												</span>
											</div>
										</div>
										<div className="flex justify-center items-center">
											<div
												onClick={() =>
													toggleExpanded(index)
												}
												className=" flex justify-center items-center cursor-pointer w-6 h-6 border rounded-full hover:bg-[#334155] hover:text-white ">
												<svg
													className={`w-4 h-4 transition-transform ease-in-out transform ${isExpanded
														? "rotate-180"
														: "rotate-0"
														}`}
													fill="none"
													stroke="currentColor"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg">
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth={2}
														d="M19 9l-7 7-7-7"
													/>
												</svg>
											</div>
										</div>
									</div>
									<div
										className={`transition-all duration-500 ease-in-out transform flex flex-col h-auto  items-center mb-3 ${isExpanded
											? "scale-100 opacity-100 max-h-screen"
											: "scale-95 opacity-0 max-h-0"
											}`}
										style={{ transformOrigin: "top" }}>
										<div className="p-2 bg-[#F1F1F1] w-[95%] rounded-b-xl h-full shadow-lg">
											<div className="flex items-start gap-2.5 w-full">
												<div className="flex flex-col gap-1 w-full">
													<div className="mt-2 space-y-4">
														<div
															className="flex justify-between items-center p-4 bg-white rounded-lg   transition-all"
															style={{
																boxShadow:
																	"1px 2px 4px 0px #00000040 inset",
															}}>
															<div className="flex items-center space-x-2 text-lg font-semibold text-gray-700">
																<BiCoinStack className="h-7 w-7 text-[#FF9900]" />
																<span>
																	{
																		item?.coins
																	}{" "}
																	Coins
																	{" "} {item?.type === "add" ? "Awarded" : "Deducted"}
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					);
				})}
		</section>
	);
};

export default ContractorOrderHistory;
