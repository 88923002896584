import { useEffect, useState } from "react";
import Dropdown from "../Inventory/components/Dropdown";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { usePublishedContractorMutation } from "../../../Redux/Api/Contractor";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { closeProfile } from "../../../Redux/reducer/RequestReducer";
import { useNavigate } from "react-router-dom";
import { Contractor } from "./types";
import { RootState } from "../../../Redux/store";
import * as XLSX from "xlsx";
import useDebounce from "../../../common/hooks/useDebounce";
import { ClipLoader } from "react-spinners";

export const Contractors = () => {
	const dispatch = useDispatch();
	const [selectedOption, setSelectedOption] = useState<string | null>(null);
	const [limit, setLimit] = useState<string | number>("20");
	const [page, setPage] = useState<any>(1);
	const [name, setName] = useState<string>("");
	const [city, setCity] = useState<string>("");
	const [grade, setGrade] = useState<string>("");
	const [totalPages, setTotalPages] = useState(1);
	const [fetchContractors, { data, isLoading }] =
		usePublishedContractorMutation();
	const [visibleComponent, setVisibleComponent] =
		useState<string>("contractors");
	const [comp, setComp] = useState<string | null>(null);
	const [isOpen, setIsOpen] = useState(false);

	const options = ["9", "8", "7", "All"];

	const handleSelect = (option: string) => {
		setSelectedOption(option);
		setLimit(option === "All" ? "All" : parseInt(option));
	};
	const navigate = useNavigate();
	const Contractor = useSelector(
		(state: RootState) => state.admin.Contractor
	);

	const toggleComponent = (component: string) => {
		setComp(component);
		setVisibleComponent(component);
		dispatch(closeProfile());
	};
	

	const contractor = Contractor?.data?.approveContractor;

	const debouncedName = useDebounce(name, 500);
	const debouncedCity = useDebounce(city, 500);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const params: {
					limit?: string | number;
					page: number;
					name?: string;
					city?: string;
					grade?: string;
				} = { page, name: debouncedName, city: debouncedCity, grade };
				if (limit !== "All" && limit !== "all") {
					params.limit = limit;
				}
				const response = await fetchContractors(params).unwrap();
				setTotalPages(response.totalPages);
			} catch (error) {
				console.error("Error fetching contractors:", error);
			}
		};

		fetchData();
	}, [page, limit, debouncedName, debouncedCity, grade, fetchContractors]);

	useEffect(() => {
		setTotalPages(data?.totalPages);
	}, [data]);

	const prevPage = () => {
		if (page > 1) {
			setPage(page - 1);
		}
	};

	const nextPage = () => {
		if (page < totalPages) {
			setPage(page + 1);
		}
	};

	const exportToExcel = () => {
		const filteredData = (data?.contractors || []).map(
			(contractor: any, index: any) => {
				const dateObject = new Date(contractor.dateOfCreation); // Convert ISO string to Date object

				return {
					SerialNo: index + 1, // Adding SerialNo as an incremental number
					Name: contractor.name,
					Contact: contractor.phoneNumber,
					EmailID: contractor.email,
					City: contractor.city,
					GST: contractor.gst,
					Grade: contractor.contractorGrade,
					Date: dateObject.toLocaleDateString(),
					Time: dateObject.toLocaleTimeString([], {
						hour: "2-digit",
						minute: "2-digit",
					}),
				};
			}
		);

		// Create a worksheet from the filtered data
		const worksheet = XLSX.utils.json_to_sheet(filteredData);

		// Create a new workbook and append the worksheet
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Contractors");

		// Generate buffer and save the workbook
		const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
		const blob = new Blob([wbout], { type: "application/octet-stream" });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = "contractors.xlsx";
		link.click();
		window.URL.revokeObjectURL(url);
	};

	const startItem =
		(page - 1) * (typeof limit === "number" ? limit : parseInt(limit)) + 1;
	const endItem = Math.min(
		page * (typeof limit === "number" ? limit : parseInt(limit)),
		contractor || 0
	);

	const resetAll = () => {
		setPage(1);
		setLimit("20");
		setName("");
		setCity("");
		setGrade("");
	};

	return (
		<div className="w-full h-auto px-3 mt-3 pb-3">
			<div className="w-full  bg-white rounded-t-xl mb-4 pt-2">
				<div className="flex justify-between py-2 px-4">
					<div className="flex items-center gap-2">
						<input
							type="text"
							onChange={(e) => setName(e.target.value)}
							value={name}
							className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
							placeholder="Search.."
							style={{
								boxShadow: "0px 0px 7.6px 0px #00000040 inset",
							}}
						/>
						<input
							type="text"
							onChange={(e) => setCity(e.target.value)}
							value={city}
							className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
							placeholder="City.."
							style={{
								boxShadow: "0px 0px 7.6px 0px #00000040 inset",
							}}
						/>
						<select
							onChange={(e) => setGrade(e.target.value)}
							value={grade}
							className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500  block w-full py-2 pl-3 pr-4"
							style={{
								boxShadow: "0px 0px 7.6px 0px #00000040 inset",
							}}>
							<option value="">Select Grade</option>
							<option value="A">A</option>
							<option value="B">B</option>
							<option value="C">C</option>
						</select>
					</div>
					<div className="flex gap-2">
						<button
							onClick={resetAll}
							className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
							Reset
						</button>
						<button
							onClick={exportToExcel}
							className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
							Excel{" "}
							<RiFileExcel2Fill className="w-[20px] h-[20px]" />
						</button>
					</div>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
						<thead className="bg-white divide-y divide-gray-200">
							<tr className="border-t">
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]"></th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Name
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Contact
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B] whitespace-nowrap">
									Contractor Type
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									City
								</th>
								{/* <th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									GST
								</th> */}
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Grade
								</th>
							</tr>
						</thead>
						<tbody className="bg-white ">
							{/* Show loading spinner */}
							{isLoading ? (
								<tr>
									<td colSpan={7}>
										<div className="w-full flex justify-center h-[50px] items-center">
											<ClipLoader />
										</div>
									</td>
								</tr>
							) : data?.contractors?.length > 0 ? (
								data.contractors.map((i: Contractor) => (
									<tr key={i._id}>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{/* Placeholder for image */}
										</td>
										<td
											className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#0078FB] underline cursor-pointer"
											onClick={() =>
												navigate(
													`/admin/contractors/profile/${i._id}`
												)
											}>
											{i.name || "-"}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.phoneNumber}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.contractorType || "-"}
										</td>
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.city || "-"}
										</td>
										{/* <td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.gst || "-"}
										</td> */}
										<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											{i.contractorGrade || "-"}
										</td>
									</tr>
								))
							) : (
								// Show no data message
								<tr>
									<td colSpan={7}>
										<div className="w-full flex justify-center h-[50px] items-center">
											Data Not Available
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				{/* Pagination */}
				<div className="flex flex-row justify-between p-3 m-1 border-t-2 ">
					<div>
						<div className="flex flex-row gap-3">
							<Dropdown
								options={options}
								onSelect={handleSelect}
							/>
							<p className="font-semibold">
								Showing{" "}
								{limit === "All"
									? "All"
									: `${startItem || 0} - ${
											endItem || ""
									  }`}{" "}
								Contractors Request
							</p>
							<p className="text-[#A6A8B1]">
								Contractors Request
							</p>
						</div>
					</div>

					<div>
						<div className="flex flex-row gap-3">
							<Dropdown
								options={options}
								onSelect={handleSelect}
							/>
							<p className="text-[#A6A8B1]">
								{page}of {totalPages} pages
							</p>
							<div className="flex flex-row items-center gap-2 text-[#666666]">
								<span
									onClick={prevPage}
									className={`cursor-pointer ${
										page === 1 ? "opacity-50" : ""
									}`}>
									<FaChevronLeft size={15} />
								</span>
								<span
									onClick={nextPage}
									className={`cursor-pointer ${
										page === totalPages ? "opacity-50" : ""
									}`}>
									<FaChevronRight size={15} />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
