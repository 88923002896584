import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";
// import { useCategoriesNameQuery } from
import {
  useCategoriesNameQuery,
  useCompaniesNameQuery,
} from "../../Redux/Api/NamesAPI";
import { useNavigate } from "react-router-dom";
import { Category } from "./types";
import { setAllCategories } from "../../Redux/reducer/toStoreValues";
import { useDispatch } from "react-redux";

export const MobileFilter = ({
  smallFilter,
  smallCompanyFilter,
  closeSideBar,
}: any) => {
  const [formData, setFormData] = useState<any>({
    search: "",
  });
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [categories, setCategories] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [company, setCompany] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [result, setResult] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string[]>([]);


  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleItemClick = (itemName: string) => {
    setActiveItem(itemName);
    navigate("/items", { state: { id: itemName } });
    setCategories(false)
    closeSideBar(false)
   
  };

  const { data: categoriesResponse, isLoading: isCategoriesLoading } =
    useCategoriesNameQuery(
      {},
      { refetchOnMountOrArgChange: true, keepPreviousData: true }
    );

  //   useEffect(() => {
  //     if(categoriesResponse){
  //     dispatch(setAllCategories(categoriesResponse))
  //     }
  //  },[categoriesResponse])

 
  const filteredCategories = categoriesResponse
    ? categoriesResponse?.data?.filter((category: Category) => {
        const regex = new RegExp(formData.search, "i"); // 'i' flag for case-insensitive matching
        return regex.test(category.name);
      })
    : [];

  // console.log(filteredCategories, "filters");

  useEffect(() => {
    if(categoriesResponse){
    dispatch(setAllCategories(categoriesResponse?.data))
    }
 },[categoriesResponse])
 

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCategoryClick = (id: any) => {
    const categoryExists = filteredCategories.some(
      (category: any) => category.id === id
    );

    navigate(`/items`);
    // console.log(id);
    if (categoryExists) {
      if (selectedCategory.includes(id)) {
        setSelectedCategory(
          selectedCategory.filter((category: any) => category !== id)
        );
      } else {
        setSelectedCategory([...selectedCategory, id]);
      }
    }
  };

  //   const handleCompanyClick = (name: string) => {

  //       const companyExists = filteredCompanies.some((company:any) => company.name === name)

  //        if(companyExists){

  //             if(selectedCompany.includes(name)){
  //                 setSelectedCompany(selectedCompany.filter((company:any) => company !==name))
  //                    }  else{
  //               setSelectedCompany([...selectedCompany,name])
  //             }
  //        }

  //   };

  //   useEffect(() => {
  //     handleShowResults()
  //   }, [selectedCategory]);

  const handleShowResults = () => {
    smallFilter(selectedCategory);
    // smallCompanyFilter(selectedCompany);
  };

  const results = () => {
    setSelectedCategory([]);
    setSelectedCompany([]);
    smallFilter(selectedCategory);
    smallCompanyFilter(selectedCompany);
  };

  return (
    <div className="w-full pr-[4px]">
      <div className={`flex justify-between pr-[10px] py-1  items-center relative transition-all duration-300 ease-in-out z-50 ${categories ? "bg-[#5097A4] rounded-xl shadow-xl text-white pl-[10px]" : ""}`}>
        <div className="flex font-semibold text-[15px] leading-[24px] ">
          <div className={`flex gap-1 `}>
            <span>Category</span>
            <span> ({categoriesResponse?.totalCategory})</span>
          </div>
        </div>
        <div
          onClick={() => setCategories(!categories)}
          className="cursor-pointer "
        >
          {categories ? <IoChevronUp /> : <IoChevronDown />}
        </div>
      </div>

      {categories && (
        <>
          <div className="flex w-[100%] items-center bg-white gap-[3px] border rounded-xl px-[10px] py-[4px] relative my-2 transition-all duration-300 ease-in-out ">
            <input
              type="text"
              placeholder="Search Items and Categories"
              className="font-[300] text-[13px] leading-[20px] w-full focus:ring-0 focus:outline-none"
              value={formData.search}
              onChange={handleInputChange}
              name="search"
            />
            <IoSearch className="h-[13px] w-[13px]" />
          </div>
          <div className="max-h-[30vh]  overflow-auto bg-[#D0F8FF] flex flex-col px-[12px] gap-[3px] p-2">
            {filteredCategories.map((category: Category, index: any) => (
              <div
                key={index}
                className={`px-[12px]  font-[500] text-[13px] leading-[20px] cursor-pointer ${selectedCategory.includes(category.id)
                  ? "bg-red-500 text-white"
                  : ""
                  }`}
                onClick={() => handleItemClick(category.id)}
              >
                {category.name}
              </div>
            ))}
          </div>
        </>
      )}

      {/* <div className="flex justify-between px-[10px] h-[40px] border-t items-center">
          <div className="flex font-[400] text-[16px] leading-[24px]">Companies</div>
          <div onClick={() => setCompanies(!companies)} className="cursor-pointer">{companies ? <IoChevronDown /> : <IoChevronUp />}</div>
        </div>
        {companies && (
          <div className="h-[170px] bg-white overflow-hidden flex flex-wrap px-[12px] gap-[3px] py-[10px]">
            {filteredCompanies.map((company: any, index: any) => (
              <div
                key={index}
                className={`px-[12px] py-[4px] bg-[#F2F2F2] h-[30px] border rounded-[6px] font-[300] text-[12px] leading-[20px] cursor-pointer ${
                  selectedCompany.includes(company.name) ? "bg-red-500 text-white" : ""
                }`}
                onClick={() => handleCompanyClick(company.name)}
              >
                {company.name}
              </div>
            ))}
          </div>
        )} */}
      {/* </div> */}

      {/* <div className="p-[12px] flex justify-between">
        <div className="py-[4px] px-[12px] text-[#5097A4] border rounded-[6px] cursor-pointer" onClick={() => results()}>
          Clear Results
        </div>

        <div className="py-[4px] px-[12px] text-white bg-[#5097A4] border rounded-[6px] cursor-pointer" onClick={() => handleShowResults()}>
          Show Results
        </div>
      </div> */}
    </div>
    // <div className="w-full">
    //   <div className="flex justify-between px-[10px]  items-center relative z-50">
    //     <div className="flex font-semibold text-[16px] leading-[24px] ">
    //     <div className="flex gap-1">
    //           <span>Category</span>
    //           <span> ({categoriesResponse?.totalCategory})</span>
    //         </div>          
    //     </div>
    //     <div
    //       onClick={() => setCategories(!categories)}
    //       className="cursor-pointer "
    //     >
    //       {categories ? <IoChevronUp /> :  <IoChevronDown />}
    //     </div>
    //   </div>

    //   {categories && (
    //     <>
    //       <div className="flex w-[80%] items-center gap-[3px] border rounded-[6px] px-[10px] py-[4px] my-2">
    //         <IoSearch className="h-[13px] w-[13px]" />
    //         <input
    //           type="text"
    //           placeholder="Search Items and Categories"
    //           className="font-[300] text-[12px] leading-[20px] w-full"
    //           value={formData.search}
    //           onChange={handleInputChange}
    //           name="search"
    //         />
    //       </div>
    //       <div className="max-h-[50vh] bg-white overflow-auto flex shadow-sm border-2 flex-wrap px-[12px] gap-[3px] p-2">
    //         {filteredCategories.map((category: Category, index: any) => (
    //           <div
    //             key={index}
    //             className={`px-[12px] py-[4px] bg-[#F2F2F2] h-[30px] border rounded-[6px] font-[300] text-[12px] leading-[20px] cursor-pointer ${
    //               selectedCategory.includes(category.id)
    //                 ? "bg-red-500 text-white"
    //                 : ""
    //             }`}
    //             onClick={() => handleItemClick(category.id)}
    //           >
    //             {category.name}
    //           </div>
    //         ))}
    //       </div>
    //     </>
    //   )}

    //   {/* <div className="flex justify-between px-[10px] h-[40px] border-t items-center">
    //       <div className="flex font-[400] text-[16px] leading-[24px]">Companies</div>
    //       <div onClick={() => setCompanies(!companies)} className="cursor-pointer">{companies ? <IoChevronDown /> : <IoChevronUp />}</div>
    //     </div>
    //     {companies && (
    //       <div className="h-[170px] bg-white overflow-hidden flex flex-wrap px-[12px] gap-[3px] py-[10px]">
    //         {filteredCompanies.map((company: any, index: any) => (
    //           <div
    //             key={index}
    //             className={`px-[12px] py-[4px] bg-[#F2F2F2] h-[30px] border rounded-[6px] font-[300] text-[12px] leading-[20px] cursor-pointer ${
    //               selectedCompany.includes(company.name) ? "bg-red-500 text-white" : ""
    //             }`}
    //             onClick={() => handleCompanyClick(company.name)}
    //           >
    //             {company.name}
    //           </div>
    //         ))}
    //       </div>
    //     )} */}
    //   {/* </div> */}

    //   {/* <div className="p-[12px] flex justify-between">
    //     <div className="py-[4px] px-[12px] text-[#5097A4] border rounded-[6px] cursor-pointer" onClick={() => results()}>
    //       Clear Results
    //     </div>

    //     <div className="py-[4px] px-[12px] text-white bg-[#5097A4] border rounded-[6px] cursor-pointer" onClick={() => handleShowResults()}>
    //       Show Results
    //     </div>
    //   </div> */}
    // </div>
  );
};
