import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { GoBellFill } from "react-icons/go";
import { Link } from "react-router-dom";
import { GoHomeFill } from "react-icons/go";
import { FiSearch } from "react-icons/fi";
import Dropdown from "./components/Dropdown";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import BlueBox from "./components/AdminComponents/BlueBox";
import { useChangeCompanyStatusMutation } from "../../../Redux/Api/Admin/Inventory";
import ItemStatus from "./InventoryComapnyStatus";
import defaultImage from "../../../assets/item.png";
import { useAdminCompaniesQuery } from "../../../Redux/Api/Admin/Inventory";
import TableLoading from "../../../common/admin-common/TableLoading";
import TableNoData from "../../../common/admin-common/TableNoData";

interface CustomerData {
	GodownId: string;
	GodownName: string;
	createdAt: number;
	email: string;
	image: string;
	maxAge: number;
	name: string;
	phoneNumber: string;
	role: string;
	success: boolean;
	userId: string;
}

const InventoryCompany = () => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [limit, setLimit] = useState("15");
	const [showAll, setShowAll] = useState<boolean>(false);
	const [search, setSearch] = useState<string>("");

	const {
		data: ProductResponse,
		error,
		isLoading,
		refetch,
	} = useAdminCompaniesQuery({
		page: currentPage,
		limit: limit === "All" ? undefined : limit,
		search: search,
	});

	useEffect(() => {
		if (error) {
			console.error("Error fetching data:", error);
		}
	}, [error]);

	const [updateProductMutation] = useChangeCompanyStatusMutation();
	const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);

	const handleTogglePopup = (index: number) => {
		setOpenPopupIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value;
		setSearch(value);
	};

	const options = ["9", "8", "7", "All"];

	const handleSelect = (option: any) => {
		// setSelectedOption(option);
		setLimit(option === "All" ? "All" : option);
	};

	const handleStatusChange = async (
		id: string,
		action: string,
		index: number,
		currentStatus: boolean
	) => {
		try {
			const status =
				action === "publish" ? !currentStatus : currentStatus;
			await updateProductMutation({ id, status });
			refetch();
			setOpenPopupIndex(null)
		} catch (error) {
			console.log("error hai", error);

		}
	};

	const prevPage = () => {
		const nextPageNumber = currentPage - 1;
		if (nextPageNumber >= 1) {
			setCurrentPage(nextPageNumber);
		}
	};
	const nextPage = () => {
		const nextPageNumber = currentPage + 1;
		if (nextPageNumber <= totalPages) {
			setCurrentPage(nextPageNumber);
		}
	};


	const customerDataString = localStorage.getItem("customerData") || "{}";
	const customerData: CustomerData = JSON.parse(customerDataString);

	useEffect(() => { }, [currentPage, limit, search]);

	// Calculate total pages
	const totalPages = Math.ceil(
		(ProductResponse?.total || 0) / parseInt(limit)
	);

	// Calculate item range for the current page
	const startItemIndex = (currentPage - 1) * parseInt(limit) + 1;
	const endItemIndex = Math.min(
		currentPage * parseInt(limit),
		ProductResponse?.total || 0
	);



	if (error) {
		return <div>Error: {(error as Error).message}</div>;
	}

	return (

		<div className="w-full h-auto px-3 mt-[13px] py-3 mb-10">
			<div className="w-full  bg-white rounded-t-xl mb-4 pt-2">
				<div className="flex justify-between py-2 px-4">
					<div className="font-medium">Companies</div>
					<div className="flex gap-2">
						<input
							type="text"
							value={search}
							onChange={handleChange}
							className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
							placeholder="Search.."
							style={{
								boxShadow:
									"0px 0px 7.6px 0px #00000040 inset",
							}}
						/>
					</div>
				</div>
				<div className="w-full overflow-x-auto">
					<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
						<thead className="bg-white divide-y divide-gray-200">
							<tr className="border-t">
								<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]"></th>
								<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
									Image
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Company
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									description
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Products
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Status
								</th>
								<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]"></th>
							</tr>
						</thead>
						<tbody className="bg-white ">
							{isLoading ? (
								<TableLoading colCount={7} />
							) : ProductResponse?.result?.length > 0 ? (
								ProductResponse?.result?.map(
									(i: any, index: number) => (
										<tr key={i?.id}>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												<img
													src={
														i?.image ||
														defaultImage
													}
													className="w-[30px] h-[30px] rounded-full"
													alt="Product"
												/>
											</td>
											<td className="pl-4 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												<Link
													to={`/admin/company/${i?.name}`}>
													{i?.name}
												</Link>
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.description}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.productCount}
											</td>
											<ItemStatus
												key={index}
												i={i}
												index={index}
												handleStatusChange={
													handleStatusChange
												}
												openPopupIndex={
													openPopupIndex
												}
												handleTogglePopup={
													handleTogglePopup
												}
											/>
										</tr>
									)
								)
							) : (
								<TableNoData
									colCount={7}
									text={"No Data Avaiable"}
								/>
							)}
						</tbody>
					</table>
				</div>
				{/* Pagination */}
				<div className="flex flex-row justify-between p-3 m-1 border-t-2">
					<div>
						<div className="flex flex-row gap-3">
							<Dropdown
								options={options}
								onSelect={handleSelect}
							/>
							<p className="text-[#A6A8B1]"> Items per page</p>
							<p className="text-[#666666]">
								{ProductResponse?.total}
								Total Companies
							</p>{" "}
							<p className="text-[#666666]">
								Displaying{" "}
								{limit === "All"
									? "All"
									: `${startItemIndex} to ${endItemIndex}`}{" "}
								items
							</p>
						</div>
					</div>
					<div>
						<div className="flex flex-row gap-3">
							<p className="text-[#666666]">
								{currentPage} of{" "}
								{limit === "All" ? 1 : `${totalPages}`} pages
							</p>
							<div className="flex flex-row items-center gap-2 text-[#666666]">
								<span
									onClick={prevPage}
									className={`cursor-pointer ${currentPage === 1 ? "text-gray-400 " : ""}`}
								>
									<FaChevronLeft size={15} />
								</span>
								<span
									onClick={nextPage}
									className={`cursor-pointer ${currentPage === totalPages ? "text-gray-400 " : ""}`}
								>
									<FaChevronRight size={15} />
								</span>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default InventoryCompany;
