import { FaStar } from "react-icons/fa6";
import { FaStarHalfStroke } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Products } from "./types";
import icon from "../../../assets/Icon.png";
import whatsapp from "../../../assets/whatsappicon.svg";
import sheild from "../../../assets/Shield Done.png";
import defaultImage from "../../../assets/defaultImage.svg";
const ItemCard: React.FC<{
	data: Products;
}> = ({ data }) => {
	const id = data?.id;
	const navigate = useNavigate();

	const handleWhatsappClick = () => {
		const phoneNumber = "9258294692";
		const url = `https://wa.me/${phoneNumber}`;
		window.open(url, "_blank");
	};
	const handleProductClick = () => {
		navigate(`/singleproduct/${id}`, { state: { id } });
	};
	console.log("data", data);

	return (
		<div className=" flex gap-[2rem] py-[15px] border-t border-b w-full ">
			<div className="flex gap-[2rem] py-[15px]  w-full ">
				<div
					onClick={handleProductClick}
					className="cursor-pointer relative w-[170px] h-[170px] md:w-[197px] md:h-[197px] overflow-hidden">
					<img
						src={
							Array.isArray(data?.images)
								? data?.images?.length > 0
									? data?.images[0]
									: typeof data?.images === "string"
									? data?.images
									: defaultImage
								: data?.images
								? data?.images
								: defaultImage
						}
						alt="item"
						className="w-[190px] h-full object-cover"
					/>
					<div className="absolute top-0 w-full left-0 z-20 py-2 px-1 rounded-br-[100px] flex items-center bg-white gap-[10px] ">
						{data?.companyImage[0] ? (
							<>
								<img
									src={data?.companyImage[0]}
									alt="sheild"
									className="w-[18px] h-[18px] object- border rounded-full"
								/>
								<div className="flex whitespace-nowrap text-wrap overflow-hidden text-green-700 text-xs font-[500]">
									{(() => {
										const words =
											data?.companyName?.split(" ");
										return (
											words?.slice(0, 2).join(" ") +
											(words && words.length > 2
												? " ..."
												: "")
										);
									})()}
								</div>
							</>
						) : (
							<>
								<img
									src={sheild}
									alt="sheild"
									className="w-[13px] h-[15px] object-cover"
								/>
								<div className="flex whitespace-nowrap overflow-hidden text-green-700 text-[14px] font-[400]">
									Lohwalla Assured
								</div>
							</>
						)}
					</div>
				</div>

				<div className="flex w-full justify-between">
					<div className="flex flex-col gap-[8px] ">
						<div
							className="font-[500] text-[12px] sm:text-[18px] leading-[21px] cursor-pointer"
							onClick={handleProductClick}>
							{data?.productName}
						</div>

						<div className="flex flex-col  gap-[8px]">
							<div className="font-[500] text-[14px] leading-[17px] flex  items-center gap-[9px] ">
								{data?.finalPrice
									? `₹${data?.finalPrice.toFixed(2)}/${
											data?.unit
									  }`
									: "Price not available"}
							</div>

							<div className="font-400 text-[10px] sm:text-[14px] leading-[22px] text-blue-400 flex items-center gap-2">
								<span>
									{" "}
									(For Best Rate , Please Contact Us)
								</span>
								<img
									src={whatsapp}
									className="w-[22px] h-[22px] cursor-pointer"
									onClick={handleWhatsappClick}
									alt="whatsapp"
								/>
							</div>
						</div>

						{data?.rewards ? (
							<div className="flex flex-wrap items-center justify-center text-center sm:justify-start sm:text-left">
								<span className="text-xs sm:text-sm md:text-base lg:text-lg w-full sm:w-auto">
									Use Referral code to earn
								</span>
								<div className="flex items-center mt-1 sm:mt-0 sm:ml-1">
									<img
										className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
										src={icon}
										alt="Coin icon"
									/>
									<span className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold mx-1 sm:mx-2">
										{data?.rewards}
									</span>
									<span className="text-xs sm:text-sm md:text-base lg:text-lg">
										super coins
									</span>
								</div>
							</div>
						) : null}

						<div className="flex text-center text-[#007185]  items-center text-[10px] ">
							<div className="flex   pr-[12px] font-[400] text-[10px] sm:text-[14px] leading-[16px] text-[#007185]">
								<FaStar className="text-yellow-600" />{" "}
								<FaStar className="text-yellow-600" />{" "}
								<FaStar className="text-yellow-600" />{" "}
								<FaStar className="text-yellow-600" />{" "}
								<FaStarHalfStroke className="text-yellow-600" />
							</div>
							<IoIosArrowDown className="text-black" />
							<span className="text-[10px] sm:text-[14px]">
								1,084
							</span>
						</div>
					</div>

					{data.status ? (
						<button className=" flex w-fit h-fit gap-2 px-[8px] py-[6px]  p-1 rounded-[6px] bg-[#65A30D] text-[10px] sm:text-[14px] font-semibold text-white">
							Active
						</button>
					) : (
						<button className=" flex w-fit h-fit gap-2 px-[8px] py-[6px]  p-1 rounded-[6px] bg-red-500 text-[10px] sm:text-[14px] font-semibold text-white">
							InActive
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default ItemCard;
