import {
	useClaimRewardMutation,
	useGetRewardsQuery,
} from "../../../Redux/Api/Admin/Reward";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { PointsHeader } from "./pointsHeader";
import { toast } from "react-toastify";
import { useState } from "react";
import { ConfirmModal } from "../../../utils/CustomModal";
import rewardCardShape from "../../../assets/profile/rewards/rewardCardShape.png";
import { GiTwoCoins } from "react-icons/gi";

export const Rewards = () => {
	const [RewardMutation] = useClaimRewardMutation();
	const [selectedRewardId, setSelectedRewardId] = useState<string | null>(
		null
	);
	const [showPopup, setShowPopup] = useState(false);
	const { data } = useGetRewardsQuery();

	const userDetails = useSelector(
		(state: RootState) => state.Login.ContractorData
	);

	const handleRequestForReward = async (id: any) => {
		try {
			await RewardMutation({ rewardId: id }).unwrap();
			toast.success("Reward claimed successfully!", {
				className: "bg-white text-[#5097A4]",
				autoClose: 2000,
			});
		} catch (error: any) {
			console.error("Error claiming reward:", error);
			toast.error(
				error?.data?.message || "An error occurred. Please try again.",
				{
					className: "bg-white text-red-700",
					autoClose: 3000,
				}
			);
		}
	};

	const showToastWarning = (message: string) => {
		toast.warn(message, {
			className: "bg-white text-[#5097A4]",
			autoClose: 2000,
		});
	};

	const openPopup = (id: any) => {
		setSelectedRewardId(id);
		setShowPopup(true);
	};

	const closePopup = () => {
		setSelectedRewardId(null);
		setShowPopup(false);
	};

	const handleConfirmClaim = () => {
		if (selectedRewardId) {
			handleRequestForReward(selectedRewardId);
		}
		closePopup();
	};

	const styles = {
		boxShadowStyle: {
			boxShadow: "0px 0.5px 7px 0px #00000040",
		},
	};

	return (
		<section className="font-inter">
			{userDetails?.isVerified && <PointsHeader />}
			<div className="profile-container pb-10">
				<h1 className="font-[600] text-3xl leading-[43px]">Rewards</h1>
				<div className=" flex flex-col sm:flex-row flex-wrap justify-center items-center gap-10 smgap-14 mt-5">
					{data?.response?.map((i: any, index: number) => (
						<div
							key={index}
							className="cp w-full max-w-[260px] h-[370px] border-[3px] border-white rounded-xl bg-[#A6D3DB] transform transition-transform duration-300 hover:-translate-y-3"
							style={styles.boxShadowStyle}>
							<div className="h-1/2 relative flex justify-center w-full overflow-hidden">
								<img
									src={i?.image}
									alt=""
									className="mt-auto"
								/>
								<div
									className="h-[81px] w-full absolute bottom-0"
									style={{
										background:
											"linear-gradient(180deg, rgba(166, 211, 219, 0) 0%, #A6D3DB 95%)",
									}}></div>
							</div>
							<div className="h-1/2 relative overflow-hidden">
								<div
									className="h-[32px] flex justify-center items-center text-white absolute right-0 min-w-[220px] pl-2 uppercase text-[18px] font-medium"
									style={{
										backgroundImage: `url(${rewardCardShape})`,
										backgroundSize: "contain",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
									}}>
									{i?.name}
								</div>
								<div className="text-[30px] font-semibold pt-14 flex justify-center items-center mx-auto text-white ">
									{i?.rewardValue}{" "}
									<GiTwoCoins className="text-yellow-500" />
								</div>
								<div className="flex justify-center w-full mt-5 px-4">
									{i.claimPendingStatus === true ? (
										<button
											onClick={() => {
												showToastWarning(
													`Your Claim for ${i.name} is pending. Kindly wait!`
												);
											}}
											className="px-3 py-1 text-lg font-bold bg-white rounded-lg mx-auto hover:text-white hover:bg-red-600 duration-300 w-full ">
											Claim requested
										</button>
									) : userDetails ? (
										<button
											onClick={() => openPopup(i?._id)}
											className="px-3 py-1 text-lg font-bold bg-white rounded-lg mx-auto hover:text-white hover:bg-yellow-500 duration-300 w-full">
											Claim
										</button>
									) : (
										<button className="px-3 py-1 text-lg font-bold bg-white rounded-lg mx-auto hover:text-white hover:bg-[#5097A4] duration-300 w-full">
											Claim Now
										</button>
									)}
								</div>
							</div>
						</div>
					))}
					{/* {data?.response?.map((i: any, index: number) => (
						<div
							key={index}
							className="cp w-full max-w-[276px] h-[415px] border-[3px] border-white rounded-xl bg-[#A6D3DB] transform transition-transform duration-300 hover:-translate-y-3"
							style={styles.boxShadowStyle}
						>
							<div className="h-1/2 relative flex justify-center w-full overflow-hidden">
								<img src={i?.image} alt="" className="mt-auto" />
								<div
									className="h-[81px] w-full absolute bottom-0"
									style={{
										background: 'linear-gradient(180deg, rgba(166, 211, 219, 0) 0%, #A6D3DB 95%)',
									}}
								></div>
							</div>
							<div className="h-1/2 relative">
								<div
									className="h-[32px] flex justify-center items-center text-white absolute right-0 min-w-[250px] pl-2 uppercase text-[23px] font-medium"
									style={{
										backgroundImage: `url(${rewardCardShape})`,
										backgroundSize: 'contain',
										backgroundPosition: 'center',
										backgroundRepeat: 'no-repeat',
									}}
								>
									{i?.name}
								</div>
								<div className="text-[50px] font-semibold pt-10 flex justify-center items-center mx-auto text-white">
									30 <GiTwoCoins className="text-yellow-500" />
								</div>
								<div className="flex justify-center w-full mt-5">
									<button className="px-2 py-1 text-[20px] font-bold bg-white rounded-xl mx-auto">Claim the reward</button>
								</div>
							</div>
						</div>
					))} */}
				</div>
			</div>
			<ConfirmModal
				isOpen={showPopup}
				handleClose={closePopup}
				handleConfirm={handleConfirmClaim}
				text="Do you really want to claim this reward?"
				buttonText="Yes"
			/>
			{/* <div className="pb-[24px]">
				{userDetails?.isVerified && <PointsHeader />}

				<div className="md:px-[80px] px-[20px] py-[36px] flex flex-col gap-[36px]">
					<h1 className="font-[600] text-[36px] leading-[43px]">
						Rewards
					</h1>
					<div className="grid grid-cols-1 place-content-center  sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  md:gap-[36px] place-items-center">
						{data?.response?.map((i: any, index: number) => (
							<div
								className="w-full max-w-[250px]  gap-[24px] flex flex-col justify-between mx-auto "
								key={i?.name}>
								<div className="h-full">
									{
										<img
											src={
												!i?.image ? i?.image : thailand
											}
											className="w-[300px] rounded-md h-[190px] object-cover"
											alt={i?.name}
										/>
									}
								</div>

								<div className="flex flex-col gap-[12px]">
									<h2 className="font-[400] text-[16px] leading-[19px]">
										{i?.name}
									</h2>

									{i.claimPendingStatus === true ? (
										<div
											className="w-full border border-red-700 hover:bg-red-600 hover:text-white rounded-[4px] py-[10px] flex justify-center items-center font-[600] text-[16px] leading-[19px]"
											onClick={() => {
												showToastWarning(
													`Your Claim for ${i.name} is pending. Kindly wait!`
												);
											}}>
											Claim requested
										</div>
									) : userDetails ? (
										<div
											className="w-full border cursor-pointer border-[#5097A4] hover:bg-[#5097A4] hover:text-white rounded-[4px] py-[10px] flex justify-center items-center font-[600] text-[16px] leading-[19px]"
											onClick={() => openPopup(i?._id)}>
											<div className="flex gap-2 justify-center items-center">
												Claim
												<LiaCoinsSolid className="text-[#FF9900]" />
												<span>{i?.rewardValue}</span>
											</div>
										</div>
									) : (
										<div className="w-full border border-[#5097A4] hover:bg-[#5097A4] hover:text-white rounded-[4px] py-[10px] flex justify-center items-center font-[600] text-[16px] leading-[19px]">
											"Claim Now "
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			</div> */}
		</section>
	);
};
