import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  closeFilter,
  toggleCategoryFilter,
} from "../../../../Redux/reducer/RequestReducer";
import { CategoryFilter } from "./CategoryFilter";
import { RootState } from "../../../../Redux/store";
import { useApproveContractorMutation } from "../../../../Redux/Api/Contractor";
import { useCategoriesNameQuery } from "../../../../Redux/Api/NamesAPI";
import { Categories } from "../../../../types/types";
import { FaTrash } from "react-icons/fa";
import { clearCategory } from "../../../../Redux/reducer/Filter";

interface RequestFilterProps {
  id: string;
}

export const RequestFilter: React.FC<RequestFilterProps> = ({ id }) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [grade, setGrade] = useState<string>("c");
  const [body, setBody] = useState({
    category: [] as string[],
    grade: "c",
    status: false,
  });
  const [approve, setApprove] = useState<boolean>(false);
  const [categoryMap, setCategoryMap] = useState<
    { id: string; name: string }[]
  >([]);

  const { data: categoriesResponse } = useCategoriesNameQuery(
    {},
    { refetchOnMountOrArgChange: true, keepPreviousData: true }
  );
  const isCategoryOpen = useSelector(
    (state: RootState) => state.request.isCategoryFilterVisible
  );
  const selectedCategories = useSelector(
    (state: RootState) => state.selection.selectedCategories
  );
  const [mutation, { data: approvedResponse }] = useApproveContractorMutation();

  const toggleCategory = () => {
    dispatch(toggleCategoryFilter());
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const filterClose = () => {
    dispatch(closeFilter());
  };

  const handleGradeClick = (grade: string) => {
    setGrade(grade);
    setBody((prevBody) => ({ ...prevBody, grade }));
    setIsDropdownOpen(false);
  };

  // console.log(categoriesResponse, "categories");

//   const mapCategoryIdsToNames = (ids: string[]) => {
//     return ids
//       .map((id) => {
//         const category = categoriesResponse?.find(
//           (cat: Categories) => cat.id === id
//         );
//         return category ? category.id : "";
//       })
//       .filter((name) => name !== "");
// };

    
//   console.log(mapCategoryIdsToNames(selectedCategories));

  const approveContractor = () => {
    // const categoryNames = mapCategoryIdsToNames(selectedCategories);
    // console.log(categoryNames)
    setApprove(true);
    setBody((prevBody) => ({
      ...prevBody,
      category: selectedCategories,
      status: true,
    }));
  };

  useEffect(() => {
    if (approve) {
      mutation({ id, body });
      dispatch(closeFilter())
    }
  }, [approve]);

  

  useEffect(() => {
    if (categoriesResponse && selectedCategories.length > 0) {
      const selectedCategoryDetails = categoriesResponse?.data?.filter((category: Categories) =>
          selectedCategories.includes(category.id)
        )
        .map((category: Categories) => ({
          id: category.id,
          name: category.name,
        }));
      setCategoryMap(selectedCategoryDetails);
    }

    if (selectedCategories.length === 0) {
      setCategoryMap(selectedCategories);
    }
  }, [categoriesResponse, selectedCategories]);

  // console.log(categoryMap);
  // console.log(selectedCategories);

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="w-[647px] flex flex-col bg-white border rounded-[4px] shadow-lg gap-[15px]">
        <div className="flex justify-between px-[24px] py-[16px] border-b">
          <span>Approve Contractor</span>
          <RxCross2
            className="w-[30px] h-[30px] cursor-pointer"
            onClick={filterClose}
          />
        </div>

        <div className="w-full flex px-[20px] gap-[30px] relative">
          <div
            className="w-[48%] rounded-[4px] h-[56px] border flex items-center px-[12px] justify-between cursor-pointer relative"
            onClick={toggleCategory}
          >
            <span>Category</span>
            {isCategoryOpen ? <MdArrowDropUp /> : <MdOutlineArrowDropDown />}
          </div>

          {isCategoryOpen && (
            <div className="absolute top-[70px] left-[10px] right-[10px] w-auto border rounded-[4px] bg-white shadow-lg z-10">
              <CategoryFilter />
            </div>
          )}

          <div
            className="w-[48%] border rounded-[4px] h-[56px] flex items-center px-[12px] justify-between cursor-pointer relative"
            onClick={toggleDropdown}
          >
            <span>{grade}</span>
            {isDropdownOpen ? <MdArrowDropUp /> : <MdOutlineArrowDropDown />}

            {isDropdownOpen && (
              <div className="absolute top-full left-0 w-full border rounded-[4px] mt-[4px] bg-white shadow-lg z-10">
                <div
                  className="px-[12px] py-[8px] cursor-pointer hover:bg-gray-100"
                  onClick={() => handleGradeClick("A")}
                >
                  Grade A
                </div>
                <div
                  className="px-[12px] py-[8px] cursor-pointer hover:bg-gray-100"
                  onClick={() => handleGradeClick("B")}
                >
                  Grade B
                </div>
                <div
                  className="px-[12px] py-[8px] cursor-pointer hover:bg-gray-100"
                  onClick={() => handleGradeClick("C")}
                >
                  Grade C
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full h-[240px] flex flex-col">
          <div className="flex bg-[#BDBDBD]">
            <span className="w-[80%] px-[24px] py-[16px]">Category</span>
            <span className="px-[24px] py-[16px]">Action</span>
          </div>

          <div className="flex flex-col overflow-y-auto h-full">
            {categoryMap &&
              categoryMap?.length > 0 &&
              categoryMap.map((category, index) => (
                <div className="flex border" key={index}>
                  <span className="w-[80%] px-[24px] py-[16px] ">
                    {category.name}
                  </span>

                  <span className="px-[24px] py-[16px] border-l w-[20%]">
                    <FaTrash
                      className="w-[14px] h-[18px] text-[#D32F2F] cursor-pointer"
                      onClick={() => dispatch(clearCategory(category.id))}
                    />
                  </span>
                </div>
              ))}
          </div>
        </div>

        <div className="px-[24px] py-[12px] flex justify-between">
          <div className="px-[22px] py-[8px] border-[#0078FB] text-[#0078FB] border rounded-[4px] w-[45%] justify-center items-center flex" onClick={filterClose}>
            Cancel
          </div>

          <div
            className="px-[22px] py-[8px] bg-[#0078FB] text-[#FFF] border rounded-[4px] w-[45%] justify-center items-center flex"
            onClick={approveContractor}
          >
            Approve
          </div>
        </div>
      </div>
    </div>
  );
};
