// ItemStatus.jsx

import React from "react";
import { RxCross2 } from "react-icons/rx";
import { BsThreeDotsVertical } from "react-icons/bs";

const ItemStatus = ({
	i,
	index,
	openPopupIndex,
	handleStatusChange,
	handleTogglePopup,
}: any) => {

	return (
		<>
			<td className="w-[114px] relative h-[23px] whitespace-nowrap font-inter text-sm  rounded-lg text-[#FFFFFF]">
				<div
					className={`text-black  relative flex justify-between px-3 py-2 items-center border rounded-xl ${
						i.publish
							? "bg-green-100 text-green-600 border-green-600 border-1 hover:bg-green-600 hover:text-white"
							: "bg-red-100 text-red-600 border-red-600 border-1 hover:bg-red-600 hover:text-white"
					}`}>
					<span>{i.publish ? "Published" : "Unpublished"}</span>
					<span className="" onClick={() => handleTogglePopup(index)}>
						<BsThreeDotsVertical className="cursor-pointer" />
					</span>
				</div>
				{openPopupIndex === index && (
					<div className="absolute right-5 top-8  z-10 border-2  mt-2 w-32  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div
							onClick={() => {
								handleTogglePopup(index);
							}}
							className=" text-red-500 cursor-pointer absolute  top-1 right-2 rounded-full border-black z-50 text-sm ">
							<RxCross2 className=" cursor-pointer " />
						</div>
						<div className="py-4 px-3 w-full text-center">
							
							<button
								onClick={() =>
									handleStatusChange(i.id, "publish", index)
								}
								className="block px-4 py-1 text-sm w-full  text-green-500 font-medium">
								Publish
							</button>
							<div className="w-full h-[1px] border-b-[1px] border-gray-400"></div>
							<button
								onClick={() =>
									handleStatusChange(i.id, "unPublish", index)
								}
								className="block px-4 py-1 text-sm w-full text-red-500 font-medium">
								Unpublish
							</button>
						</div>
					</div>
				)}
			</td>
		</>
	);
};

export default ItemStatus;
