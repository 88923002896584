import { ChangeEvent } from "react";
type FiltersTypes = { date: string; status: string }
interface IFiltersProps {
    handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    filters: FiltersTypes;
    applyFilters: () => void,
    resetFilters: () => void
}

const QuotationTrackerFilters = ({ filters, handleChange, applyFilters, resetFilters }: IFiltersProps) => {
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className="z-10 w-56 p-3 absolute right-4 top-10 bg-white rounded-lg shadow dark:bg-gray-700"
        >
            <h6 className="mb-3 text-sm font-medium text-gray-900 dark:text-white">
                Filters
            </h6>
            <ul className="space-y-2 text-sm" aria-labelledby="filterDropdownButton">
                <li className="flex-col items-center">
                    <label htmlFor="date">Date</label>
                    <input
                        type="date"
                        name="date"
                        value={filters.date}
                        onChange={handleChange}
                        className="border-2 mt-1 border-[#5097A4] text-gray-900 text-sm rounded-lg block w-full py-1 px-2 focus:outline-[#5097A4]"
                    />
                </li>
                <li className="flex-col items-center">
                    <label htmlFor="status">Status</label>
                    <select
                        name="status"
                        value={filters.status}
                        onChange={handleChange}
                        className="border-2 mt-1 border-[#5097A4] text-gray-900 text-sm rounded-lg block w-full py-[6px] px-2 focus:outline-[#5097A4]"
                    >
                        <option value="">Select Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </li>
                <li className="flex items-center justify-end gap-2 my-2">
                    <button
                        onClick={resetFilters} // Call resetFilters on button click
                        className="px-2 py-1 rounded-lg bg-red-500 text-white text-xs font-medium"
                    >
                        Reset
                    </button>
                    <button
                        onClick={applyFilters} // Call applyFilters on button click
                        className="px-2 py-1 rounded-lg bg-[#5097A4] text-white text-xs font-medium"
                    >
                        Apply
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default QuotationTrackerFilters;
