import { forwardRef, useState } from "react";
import { useGetAdminAllContractorMessageQuery } from "../../../Redux/Api/Order";
import { Accordion } from "./Accordion ";
import DatePicker from "react-datepicker";
import { IoFilter } from "react-icons/io5";
import 'react-datepicker/dist/react-datepicker.css';
import { MdCancel } from "react-icons/md";
import useDebounce from "../../../common/hooks/useDebounce";
interface Data {
  contractorId: string;
  contractorName: string;
  createdAt: string;
  date: string; 
  message: string;
  name: string;
  _id: string;
}

const AllContractorMessage = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>()
  const [searchValue, setSearchValue] = useState<string>("");

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const { data: Reward, isLoading } = useGetAdminAllContractorMessageQuery({
    filter: debouncedSearchValue,
    date: startDate ? startDate.toLocaleDateString('en-CA') : undefined
  });
  

  const handleClearDate = () => {
    setStartDate(null);
  };

  const ButtonInput = forwardRef<HTMLButtonElement, { value?: string; onClick?: () => void; className?: string }>(
    ({ value, onClick, className }, ref) => (
      <button
        className={`flex items-center justify-center gap-2  text-black p-2 rounded-md ${className}`}
        onClick={onClick}
        ref={ref}
        style={{ minWidth: '120px' }} // Ensures the button has a fixed width
      >
        {value || (
          <span className="flex items-center">
            Filter <IoFilter className="ml-2" />
          </span>
        )}
        {
          value && <span onClick={handleClearDate} className=" h-5 w-5 "><MdCancel className="h-full w-full" /></span>
        }
      </button>
    )
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleDateChange = (date: Date | null) => {
    setStartDate(date);
  };


  return (
    <div className="flex flex-col items-center justify-between p-2">
      <div className="w-full bg-white  font-[600] text-[24px] leading-[28px] ml-1 px-5 py-2">
        Messages
      </div>
      <div className="container w-full mx-auto px-5 py-2">
        <div className="w-full my-2 border h-[65px] rounded-xl flex justify-between items-center p-3">
          <div>
            <input
              type="text"
              id="first_name"
              value={searchValue}
              onChange={handleSearchChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-[0.5px]"
              placeholder="Search.."
              style={{ boxShadow: '0px 0px 7.6px 0px #00000040 inset' }}

            />
          </div>


          <div className="flex items-center gap-2">
            <div className="relative flex items-center gap-2">
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                customInput={<ButtonInput className="flex gap-1 items-center py-2 px-3 border rounded-xl" />}
                popperClassName="custom-datepicker-popper"
                popperPlacement="bottom-end"
              />
            </div>
          </div>
        </div>
        {
          !isLoading && Reward && Reward.data && Reward.data.length > 0
            ? Reward.data.map((item: Data, index: number) => (
              <Accordion
                key={item._id}
                title={item.contractorName} 
                content={item}
                isOpen={openIndex === index}
                onToggle={() => handleToggle(index)}
              />
            ))
            : isLoading
              ? <span className="p-3">Loading..</span>
              : <span className="p-3">No data available.</span>
        }

      </div>

    </div>
  );
};

export default AllContractorMessage;
