import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useCreateRewardMutation } from "../../../../Redux/Api/Admin/Reward";
import addImage from "../../../../assets/Add.png";
import { toast } from "react-toastify";

type FormValues = {
  name: string;
  rewardA: string;
  rewardB: string;
  rewardC: string;
  status: boolean;
  image: File | null;
};

export const Coupons = ({ onClose }: any) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [createReward] = useCreateRewardMutation();
  const [couponData, setCouponData] = useState<FormValues>({
    name: "",
    rewardA: "", 
    rewardB: "", 
    rewardC: "", 
    status: true,
    image: null,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValue = name.startsWith("reward") && value !== "" ? Math.max(0, Number(value)).toString() : value;

    setCouponData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setCouponData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById("image") as HTMLInputElement;
    fileInput?.click();
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", couponData.name);
      formData.append("rewardA", couponData.rewardA);
      formData.append("rewardB", couponData.rewardB);
      formData.append("rewardC", couponData.rewardC);
      formData.append("status", couponData.status.toString());
      if (couponData.image) {
        formData.append("image", couponData.image);
      }

      const response = await createReward(formData).unwrap();
      if (response.success) {
        toast.success("Reward created successfully!", { position: "top-center" });
      }
      onClose();
    } catch (error) {
      toast.error("Error creating reward:", { position: "top-center" });
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="w-[547px] flex flex-col gap-[22px] border rounded-[8px] bg-white shadow-sm max-h-[90%] overflow-y-auto">
        <div className="flex justify-between px-[24px] pt-[16px] pb-1 text-[#A6A8AD] text-2xl font-medium">
          <span>Add Reward Points</span>
          <RxCross2
            className="w-[30px] h-[30px] cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="gap-[6px] px-[10px] flex flex-col">
          <div className="flex flex-col gap-[26px]">
            <div className="w-full bg-white py-[6px] border-[3px] border-blue-600 border-dashed rounded-[12px] flex flex-col justify-center items-center">
              <div className="flex flex-col gap-[10px] items-center justify-center text-center py-2">
                <div>
                  <img src={addImage} className="w-[55px] h-[55px] text-[#004EBA]" alt="" />
                </div>
                <div className="font-[500] text-[16px] leading-[7px] text-black">
                  Upload Your Files
                </div>
                <div
                  className="text-blue-500 font-[400] text-[16px] leading-[24px] underline cursor-pointer"
                  onClick={triggerFileInput}
                >
                  Browse
                </div>
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                />
              </div>
              {couponData.image && (
                <div className="mt-4 text-center">
                  <p className="text-gray-600 font-[500] text-[14px] leading-[24px] break-words">
                    Selected File: {couponData.image.name}
                  </p>
                  <p className="text-gray-500 text-[12px]">
                    Size: {(couponData.image.size / 1024).toFixed(2)} KB
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="mt-2">
            <input
              name="name"
              type="text"
              value={couponData.name}
              onChange={handleChange}
              className="w-full h-12 px-4 border-2 border-gray-300 rounded-xl outline-none focus:border-gray-400"
              placeholder="Enter Coupon name.."
            />
          </div>
          <div className="relative w-full mb-4 mt-3">
            <label className="absolute -top-2 left-4 px-1 bg-white text-gray-500 text-xs">
              Grade "A"
            </label>
            <input
              name="rewardA"
              type="number"
              value={couponData.rewardA}
              onChange={handleChange}
              className="w-full h-12 px-4 border-2 border-gray-300 rounded-xl outline-none focus:border-gray-400"
            />
          </div>
          <div className="relative w-full mb-4">
            <label className="absolute -top-2 left-4 px-1 bg-white text-gray-500 text-xs">
              Grade "B"
            </label>
            <input
              name="rewardB"
              type="number"
              value={couponData.rewardB}
              onChange={handleChange}
              className="w-full h-12 px-4 border-2 border-gray-300 rounded-xl outline-none focus:border-gray-400"
            />
          </div>
          <div className="relative w-full mb-4">
            <label className="absolute -top-2 left-4 px-1 bg-white text-gray-500 text-xs">
              Grade "C"
            </label>
            <input
              name="rewardC"
              type="number"
              value={couponData.rewardC}
              onChange={handleChange}
              className="w-full h-12 px-4 border-2 border-gray-300 rounded-xl outline-none focus:border-gray-400"
            />
          </div>
        </div>
        <div className="pb-3 mt-[-13px] px-[10px]">
          <button
            className="bg-blue-500 text-white px-6 py-[8px] border rounded-xl flex justify-center items-center cursor-pointer ml-auto"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
