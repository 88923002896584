import { Link, useNavigate } from "react-router-dom";
import { GoStarFill } from "react-icons/go";
import { usePriceMutation } from "../Redux/Api/priceAPI";
import { useEffect, useState } from "react";
import { useTopSellingQuery } from "../Redux/Api/top";
import { BsTelephone } from "react-icons/bs";
import defaultImage from "../assets/defaultImage.svg"

type ImageUrl = string;
type MobileCardProps = {
  name?: string;
  description?: string;
  mrpPrice?: string;
  image?: string | undefined;
  _id?: string;
  status?: boolean;
  productName?: string;
  companyName?: string;
  total?: number;
  unit?: string;
  cname?: string;
  itemCode?: string;
  data?: any;
  priceData?: any;
};

const TopSellingCard = ({ data,priceData }: MobileCardProps) => {
  const shareOnWhatsApp = () => {
    const message = "Your message here";
    const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  const handleWhatsappClick = () => {
    const phoneNumber = "9639921932";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

   const navigate = useNavigate()
     const totalPrice = priceData.finalPrice
     const unit = priceData.unit

  return (
    <div 
    className="bg-white flex flex-col justify-between rounded-lg hover:shadow-2xl hover:shadow-[#5097A4] gap-3 p-[12px] cursor-pointer border w-[16rem] h-[348px]"
      >
    
          <div className="border">
          <div className="flex w-[232px] h-[144px] justify-center group overflow-hidden mx-auto">
          <img
               onClick={() =>
                navigate(`/singleproduct/${data?.id}`, {
                  state: { id: data?.id },
                })
              }
                src={
                   data?.image  || defaultImage
                }
                alt="rect"
                className="relative md:h-[14rem] h-[11.1rem] w-[17rem] py-1 group-hover:scale-110 transition-transform duration-300"
                />
            </div>
          </div>

          <div className="flex flex-col ">
          <div
              className="font-inter text-[#111827] text-[18px] leading-[28px] my-1 overflow-hidden font-bold cursor-pointer"
              onClick={() =>
                navigate(`/singleproduct/${data?.id}`, {
                  state: { id: data?.id },
                })
              }
            >
              {data && data?.productName
                ? data?.productName
                    ?.split(" ")
                    .slice(0, window.innerWidth >= 768 ? 8 : 5)
                    .join(" ")
                : ""}
            </div>

            <div className="font-semibold cursor-pointer" onClick={() => navigate(`/singleproduct/${data?.id}`,{state : {id: data?.id}})}>
              {data.name?.substring(0,30)}</div>
             <div className="flex flex-col gap-2 ">

            <div className="font-bold flex gap-2  text-gray-500  text-[12px] leading-6  ">
              <span>₹{totalPrice.toFixed(2)}/{unit}</span>
              <span className="text-blue-400">(Negotiable)</span>
            </div>

            {/* <div className="flex  mt-6 items-center justify-between  w-full">
                {/* <div className="bg-[#2563EB] rounded-md flex flex-row items-center gap-2  p-1">
                  <p className="text-[#FFFFFF] font-roboto font-semibold text-[12px] items-center">
                    3.7
                  </p>

                  <GoStarFill className="text-white" size={13} />
                </div> */}
                {/* <p className="font-inter font-semibold  text-[0.75rem] text-semibold text-[#94A3B8] whitespace-nowrap">
                  (474) reviews
                </p> */}
                {/* <div className="p-2 bg-[#65A30D] rounded-lg text-[#F7FEE7]  font-inter font-bold text-[0.75rem]">
                  12% discount
                </div> 
              </div> */}

            </div>   
        </div>

        <div className="relative w-full h-11 group" onClick={handleWhatsappClick}>
          {/* Default state */}
          <div className="w-full h-full px-3 py-3 bg-white rounded-lg border border-slate-200 flex justify-center gap-3 items-center transition-opacity duration-300 absolute inset-0 group-hover:opacity-0">
            <div className="text-[#5097A4] flex whitespace-nowrap text-sm font-semibold font-['Inter'] leading-tight">
              Contact For Better Pricing
            </div>
            <BsTelephone className="w-4 h-4 relative text-[#5097A4]" />
          </div>
          {/* Hover state */}
          <div className="w-full h-full px-4 py-3 bg-[#5097A4] rounded-lg shadow flex items-center gap-3 justify-center transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-2xl absolute inset-0 overflow-hidden">
            <BsTelephone className="w-4 h-4 relative text-white opacity-0 transition-opacity duration-100 delay-300 group-hover:opacity-100" />
            <div className="text-white text-sm flex whitespace-nowrap font-semibold font-['Inter'] leading-tight transition-transform duration-300 transform translate-x-full group-hover:translate-x-0">
              Contact For Better Pricing
            </div>
          </div>
        </div>

   
    </div>
  );
};

export default TopSellingCard;
