import { useEffect, useState, useRef } from "react";
import {
  useItemRewardQuery,
  useUpdatedItemRewardMutation,
} from "../../../../Redux/Api/Category";
import Description from "../../../SingleProduct/Components/Description";
import icon from "../../../../assets/Icon.png";

interface Reward {
  rewardA: number;
  rewardB: number;
  rewardC: number;
}

interface RewardComponentProps {
  id: string;
}

const ItemRewardComponent = ({ id }: RewardComponentProps) => {
  const [index, setIndex] = useState<number>(2);
  const [reward, setReward] = useState<Reward>({
    rewardA: 0,
    rewardB: 0,
    rewardC: 0,
  });

  const [catRewards, setCatReward] = useState<Reward>({
    rewardA: 0,
    rewardB: 0,
    rewardC: 0,
  });

  const { data: itemResponse, refetch } = useItemRewardQuery(id);
  const [changeReward] = useUpdatedItemRewardMutation();
  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    refetch();
    if (itemResponse && itemResponse.itemRewards) {
      const {
        rewardA = 0,
        rewardB = 0,
        rewardC = 0,
      } = itemResponse.itemRewards;
      const {
        rewardA: catRewardA = 0,
        rewardB: catRewardB = 0,
        rewardC: catRewardC = 0,
      } = itemResponse.categoryRewards;

      setReward({
        rewardA,
        rewardB,
        rewardC,
      });

      setCatReward({
        rewardA: catRewardA,
        rewardB: catRewardB,
        rewardC: catRewardC,
      });
    }
  }, [itemResponse]);

  const handleRewardChange = (type: keyof Reward, value: string) => {
    const updatedReward = { ...reward, [type]: parseFloat(value) };
    setReward(updatedReward);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      if (id) {
        changeReward({ id, body: updatedReward }).then(() => refetch());
      }
    }, 1000);
  };

  return (
    <section className="bg-white">
      <div style={{ boxShadow: "0px 4px 4px 0px #00000040" }} className="p-8 w-[85%] border-4 border-[#233144]  mx-auto rounded-xl shadow-xl space-y-8 transition duration-500 bg-[#334155] mt-4 text-white">
        <h2 className="font-bold text-2xl text-center mb-6">
          Rewards for {itemResponse?.productName}
        </h2>
        <div className="font-semibold space-y-6" >
          <div className="flex items-center gap-4 justify-end">
            <p className="w-16 text-center text-white  text-[1.2rem] font-semibold border-black p-1 rounded-md">
              Item
            </p>
            <p className="text-center text-white  text-[1.2rem] font-semibold border-black p-1 rounded-mdd">
              Category
            </p>
            <p className="w-16 text-center text-white  text-[1.2rem] font-semibold border-black p-1 rounded-md">
              Total
            </p>
          </div>
          <div className="flex items-center gap-5">
            <label className="w-1/4 text-xl">Reward A</label>
            <input
              className="w-3/4 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
              type="range"
              min="0"
              max="1000"
              step="10"
              value={reward.rewardA}
              onChange={(e) => handleRewardChange("rewardA", e.target.value)}
            />
            <p className="w-16 text-center text-black bg-white border-2 border-black p-1 rounded-md shadow-md">
              {reward.rewardA}
            </p>{" "}
            X
            <p className="w-16 text-center text-black bg-white border-2 border-black p-1 rounded-md shadow-md">
              {catRewards.rewardA}
            </p>{" "}
            =
            <p className="w-16 text-center text-black bg-white border-2 border-black p-1 rounded-md shadow-md">
              {(reward.rewardA * catRewards.rewardA).toFixed(1)}
            </p>
          </div>
          <div className="flex items-center gap-5">
            <label className="w-1/4 text-xl">Reward B</label>
            <input
              className="w-3/4 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
              type="range"
              min="0"
              max="1000"
              step="10"
              value={reward.rewardB}
              onChange={(e) => handleRewardChange("rewardB", e.target.value)}
            />
            <p className="w-16 text-center text-black bg-white border-2 border-black p-1 rounded-md shadow-md">
              {reward.rewardB}
            </p>{" "}
            X
            <p className="w-16 text-center text-black bg-white border-2 border-black p-1 rounded-md shadow-md">
              {catRewards.rewardB}
            </p>{" "}
            =
            <p className="w-16 text-center text-black bg-white border-2 border-black p-1 rounded-md shadow-md">
              {(reward.rewardB * catRewards.rewardB).toFixed(1)}
            </p>
          </div>
          <div className="flex items-center gap-5">
            <label className="w-1/4 text-xl">Reward C</label>
            <input
              className="w-3/4 h-2 rounded-full appearance-none cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 slider"
              type="range"
              min="0"
              max="1000"
              step="10"
              value={reward.rewardC}
              onChange={(e) => handleRewardChange("rewardC", e.target.value)}
            />
            <p className="w-16 text-center text-black bg-white border-2 border-black p-1 rounded-md shadow-md">
              {reward.rewardC}
            </p>{" "}
            X
            <p className="w-16 text-center text-black bg-white border-2 border-black p-1 rounded-md shadow-md">
              {catRewards.rewardC}
            </p>{" "}
            =
            <p className="w-16 text-center text-black bg-white border-2 border-black p-1 rounded-md shadow-md">
              {(reward.rewardC * catRewards.rewardC).toFixed(1)}
            </p>
          </div>
        </div>
      </div>

      <div className="w-[80%] m-auto  " >
        <div className=" flex md:gap-5 flex-col md:flex-row lg:gap-10 py-1 px-3 rounded-xl mt-3  shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)]  ">
          <div className="text-2xl text-gray-500 my-3  font-medium sm:hidden">
            {itemResponse?.productName}
          </div>

          <div className="px-2 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079] flex gap-x-5">
            <img
              src={
                itemResponse?.productImage
                  ? itemResponse?.productImage[0]
                  : "https://m.media-amazon.com/images/I/51MLqxTajeL._SX679_.jpg"
              }
              alt="product image"
              className="w-[236px] h-[210px] rounded-xl"
            />
            <div className="flex flex-col space-y-5 p-2">
              <span className="font-semibold text-black  text-[1.5rem]">
                {itemResponse?.productName}
              </span>
              <span className="text-[1.2rem] font-semibold">
                Rs {itemResponse?.finalPrice.toFixed(2)}
              </span>

              <div className="space-y-5">
                <span className="flex items-center">
                  <span className="text-[1.4rem] font-semibold">A .</span>   You will earn {<img className="mx-2" src={icon} alt="icon" />}
                  <span className="text-[18px] mr-2"> {(reward.rewardA * catRewards.rewardA).toFixed(0)} super coins</span>
                </span>
                <span className="flex items-center">
                  <span className="text-[1.4rem] font-semibold">B .</span>   You will earn {<img className="mx-2" src={icon} alt="icon" />}
                  <span className="text-[18px] mr-2"> {(reward.rewardB * catRewards.rewardB).toFixed(0)} super coins</span>
                </span>
                <span className="flex items-center">
                  <span className="text-[1.4rem] font-semibold">C .</span>   You will earn {<img className="mx-2" src={icon} alt="icon" />}
                  <span className="text-[18px] mr-2"> {(reward.rewardC * catRewards.rewardC).toFixed(0)} super coins</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <Description singleProduct={itemResponse} index={index} setIndex={setIndex} />
        </div>
      </div>
    </section>
  );
};

export default ItemRewardComponent;
