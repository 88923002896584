import React, { useState, useEffect } from "react";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

interface CarouselProps {
  slides: React.ReactNode[]; 
}

const Carousel = ({ slides }: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState<number>(1); 
  const [transitioning, setTransitioning] = useState<boolean>(false);

  useEffect(() => {
    const autoSlide = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(autoSlide);
  }, []);

  useEffect(() => {
    if (transitioning) {
      const timer = setTimeout(() => {
        setTransitioning(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [transitioning]);

  const nextSlide = () => {
    if (transitioning) return;
    setTransitioning(true);
   
    setCurrentSlide((prev) => (prev === 4 ? 1 : prev + 1));
  };

  const prevSlide = () => {
    if (transitioning) return;
    setTransitioning(true);
   
    setCurrentSlide((prev) => (prev === 1 ? 4 : prev - 1));
  };

  return (
    <div className="relative overflow-hidden">
    <div
      className={`flex transition-transform duration-500 ease-in-out transform`}
      style={{ transform: `translateX(-${(currentSlide - 1) * 100}%)` }} 
    >
      {slides.map((slide, index) => ( 
        <div key={index} className="w-full flex-shrink-0">
          {slide}
        </div>
      ))}
    </div>
    <button
      className="absolute top-1/2 left-0 transform -translate-y-1/2 px-5 py-20 rounded-md text-white h-[30%] lg:bg-gradient-to-b from-[#b0ebf3] via-[#bbe9f2] to-[#E6EBF2]"
      onClick={prevSlide}
    >
      <SlArrowLeft className="font-extrabold h-[23px] w-[23px] md:h-[33px] md:w-[33px]" />
    </button>
    <button
      className="absolute top-1/2 right-0 transform -translate-y-1/2 px-5 py-20 rounded-md h-[30%] text-white lg:bg-gradient-to-b from-[#b0ebf3] via-[#bbe9f2] to-[#E6EBF2]"
      onClick={nextSlide}
    >
      <SlArrowRight className="font-extrabold h-[23px] w-[23px] md:h-[33px] md:w-[33px]" />
    </button>
  </div>
  );
};

export default Carousel;


// import React, { useState, useEffect } from "react";
// import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

// interface CarouselProps {
//   slides: React.ReactNode[]; 
// }

// const Carousel = ({ slides }: CarouselProps) => {
//   const [currentSlide, setCurrentSlide] = useState<number>(1); 
//   const [transitioning, setTransitioning] = useState<boolean>(false);

//   useEffect(() => {
//     const autoSlide = setInterval(() => {
//       nextSlide();
//     }, 5000);

//     return () => clearInterval(autoSlide);
//   }, []);

//   useEffect(() => {
//     if (transitioning) {
//       const timer = setTimeout(() => {
//         setTransitioning(false);
//       }, 500);
//       return () => clearTimeout(timer);
//     }
//   }, [transitioning]);

//   const nextSlide = () => {
//     if (transitioning) return;
//     setTransitioning(true);
   
//     setCurrentSlide((prev) => (prev === 4 ? 1 : prev + 1));
//   };

//   const prevSlide = () => {
//     if (transitioning) return;
//     setTransitioning(true);
   
//     setCurrentSlide((prev) => (prev === 1 ? 4 : prev - 1));
//   };

//   return (
    // <div className="relative overflow-hidden">
    //   <div
    //     className={`flex transition-transform duration-500 ease-in-out transform`}
    //     style={{ transform: `translateX(-${(currentSlide - 1) * 100}%)` }} 
    //   >
    //     {slides.map((slide, index) => ( 
    //       <div key={index} className="w-full flex-shrink-0">
    //         {slide}
    //       </div>
    //     ))}
    //   </div>
    //   <button
    //     className="absolute top-1/2 left-0 transform -translate-y-1/2 px-5 py-20 rounded-md text-white h-[30%] lg:bg-gradient-to-b from-[#b0ebf3] via-[#bbe9f2] to-[#E6EBF2]"
    //     onClick={prevSlide}
    //   >
    //     <SlArrowLeft className="font-extrabold h-[23px] w-[23px] md:h-[33px] md:w-[33px]" />
    //   </button>
    //   <button
    //     className="absolute top-1/2 right-0 transform -translate-y-1/2 px-5 py-20 rounded-md h-[30%] text-white lg:bg-gradient-to-b from-[#b0ebf3] via-[#bbe9f2] to-[#E6EBF2]"
    //     onClick={nextSlide}
    //   >
    //     <SlArrowRight className="font-extrabold h-[23px] w-[23px] md:h-[33px] md:w-[33px]" />
    //   </button>
    // </div>
//   );
// };

// export default Carousel;
