import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";
import { LoginResponse } from "./types";

export const SignupQuery: any = api.injectEndpoints({
	endpoints: (builder) => ({
		Signup: builder.mutation<any, any>({
			query: (formData) => {
				return {
					url: `${lhwpath}/global/contractor/create`,
					method: "POST",
					body: formData,
				};
			},
		}),

		Login: builder.mutation<LoginResponse, string>({
			query: (loginData) => {
				return {
					url: `${lhwpath}/global/contractor/login`,
					method: "POST",
					body: loginData,
				};
			},
			invalidatesTags: ["NavCount"],
		}),
	}),
});

export const { useSignupMutation, useLoginMutation } = SignupQuery;
