import { ChangeEvent, useEffect, useState } from "react";
import { useGetContractorCoinHistoryMutation } from "../../../Redux/Api/Order";
import { BiCoinStack } from "react-icons/bi";
import { RotatingLines } from "react-loader-spinner";
import useDebounce from "../../../common/hooks/useDebounce";

interface IOrderData {
	approveBy: string,
	coins: number,
	createdAt: string,
	type: string,
	_id: string,
	customerName?: string,
	orderNumber?: string,
	totalCoins?: number,
	currentCoins?: number,
	itemData?: [
		coins: number,
		combinedName: string,
		companyProductId: string[]
	]
}

const ContractorCoinHistory = () => {
	const [coins, setCoins] = useState<string>("All");
	const [orderNumber, setOrderNumber] = useState<string>("");
	const [selectedDate, setSelectedDate] = useState<string>("");
	const [mutation, { data: orderData, isLoading }] =
		useGetContractorCoinHistoryMutation();
	const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
	const [awardedBonus, setAwardedBonus] = useState<number | null>(null);

	// useEffect(() => {
	// 	if (coins) {
	// 		mutation({ condition: coins.toLowerCase() })
	// 			.unwrap()
	// 			.catch((error: any) => {
	// 				console.error(
	// 					"Error fetching contractor coin history:",
	// 					error
	// 				);
	// 			});
	// 	}
	// }, [coins, mutation]);

	const debounceDate = useDebounce(selectedDate, 300)
	const debounceOrderNumber = useDebounce(orderNumber, 500)
	useEffect(() => {
		if (coins || debounceDate || debounceOrderNumber) {
			mutation({
				condition: coins?.toLowerCase(),
				...(debounceDate && { date: debounceDate }),
				...(debounceOrderNumber && { orderNumber: debounceOrderNumber }),
			})
				.unwrap()
				.catch((error: any) => {
					console.error("Error fetching contractor coin history:", error);
				});
		}
	}, [coins, debounceDate, debounceOrderNumber, mutation]);

	const toggleExpanded = (index: number) => {
		setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const handleCoinApi = (selectedData: string) => {
		setCoins(selectedData);
		mutation({ condition: selectedData.toLowerCase() });
	};

	const handleAwardBonus = (bonus: number) => {
		setAwardedBonus(bonus);
		setTimeout(() => setAwardedBonus(null), 2000);
	};

	const getNoDataMessage = () => {
		if (coins === "All") return "No coins allocated yet !";
		if (coins === "Credited") return "No coins credited yet !";
		if (coins === "Debited") return "No coins debited yet !";
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = e.target;
		if (name === "selectedDate") {
			setSelectedDate(value);
		} else if (name === "orderNumber") {
			setOrderNumber(value);
		}
	};

	// const filterOrderData = orderData?.data?.filter((item: IOrderData) => {
	// 	const createdAtDate = item.createdAt.split(' ')[0]; 
	// 	const isOrderNumberMatch = item.orderNumber?.toLowerCase().includes(orderNumber?.toLowerCase() || '');
	// 	const isDateMatch = !selectedDate || createdAtDate === selectedDate;
	
	// 	return orderNumber ? isOrderNumberMatch && isDateMatch : isDateMatch;
	// });

	const resetFilters = () => [
		setSelectedDate(""),
		setOrderNumber(""),
	]




	return (
		<div className="profile-container">

			<div
				className="px-2 py-1 flex items-center border w-full md:w-fit rounded-lg mb-4"
				style={{
					boxShadow: "0px 0.5px 5px 0px #00000040",
				}}>
				{["All", "Credited", "Debited"].map((condition) => (
					<span
						key={condition}
						className={`py-1 sm:px-10 font-semibold w-full text-center text-[17px] cursor-pointer rounded-lg transition-transform duration-400 ${coins === condition
							? "text-white bg-[#5097A4] transform scale-110"
							: "text-gray-600"
							}`}
						onClick={() => handleCoinApi(condition)}>
						{condition}
					</span>
				))}
			</div>


			<div className=" flex flex-col sm:flex-row gap-2 items-center justify-end mb-4">

				<div className="w-full  sm:max-w-[200px]">
					<input
						type="text"
						placeholder="Order Number.."
						name="orderNumber"
						value={orderNumber}
						onChange={handleInputChange}
						className=" w-full  bg-transparent placeholder:text-slate-400 text-slate-400 text-sm border-2 border-slate-400 rounded-xl px-3 py-2 transition duration-300  focus:outline-none focus:border-[#5097A4] hover:border-[#5097A4] shadow-sm focus:shadow-sm"
					/>
				</div>
				<div className="w-full sm:max-w-[200px]">
					<input
						type="date"
						name="selectedDate"
						value={selectedDate}
						onChange={handleInputChange}
						className=" w-full cursor-pointer bg-transparent placeholder:text-slate-400 text-slate-400 text-sm border-2 border-slate-400 rounded-xl px-3 py-2 transition duration-300  focus:outline-none focus:border-[#5097A4] hover:border-[#5097A4] shadow-sm focus:shadow-md"
					/>
				</div>
				<button onClick={resetFilters} className=" w-full sm:w-fit px-3 py-2 bg-[#5097A4] rounded-xl text-white  font-medium">Reset</button>


			</div>
			<section className="min-h-screen ">
				{isLoading ? (
					<div className="flex items-center justify-center h-[200px]">
						<RotatingLines
							strokeColor="#5097A4" // Customize the spinner color
							strokeWidth="5"
							animationDuration="0.75"
							width="70"
							visible={true}
						/>
					</div>
				) : orderData?.data?.length > 0 ? (
					orderData?.data?.map((item: IOrderData, index: number) => {
						const isExpanded = expandedIndex === index;
						return (
							<div key={index}>
								{item.customerName ? (
									<div className="">
										<div
											className="relative w-full cursor-pointer justify-between text-[#7D7D7D] h-auto rounded-xl bg-white p-4 font-medium space-y-1 box-border"
											style={{
												boxShadow:
													"0px 4px 4px 0px #00000040",
											}}
											onClick={() =>
												toggleExpanded(index)
											}>
											<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center pb-1">
												<div>
													<div className="text-lg font-semibold text-gray-800 cursor-pointer">
														Order Number:{" "}
														<div
															className="text-blue-600"
															style={{
																display:
																	"inline",
															}}>
															{item.orderNumber}
														</div>
													</div>
													<p className="text-[#888888]">
														Customer Name:{" "}
														{item?.customerName}
													</p>
												</div>
												<div className="flex items-center space-x-3 ">
													<div className="text-lg font-semibold">
														{item?.type ===
															"add" ? (
															<span className="text-[#00CB4A]">
																+
																{
																	item?.totalCoins
																}
															</span>
														) : (
															<span className="text-[#FF0000]">
																{
																	item?.totalCoins
																}
															</span>
														)}
													</div>
													<BiCoinStack className="h-10 w-10 text-[#FF9900]" />
												</div>
											</div>

											<div className="mt-1 flex flex-col sm:flex-row sm:justify-between text-sm text-gray-500">
												<div>
													<span className="font-semibold">
														Date:{" "}
													</span>
													{
														item?.createdAt.split(
															" "
														)[0]
													}{" "}
													{
														item?.createdAt.split(
															" "
														)[1]
													}
												</div>
												<div>
													<span className="font-semibold text-orange-500">
														{item?.currentCoins}
													</span>
												</div>
											</div>
										</div>
										<div
											className={`transition-all duration-500 ease-in-out transform flex flex-col h-auto items-center mb-3 ${isExpanded
												? "scale-100 opacity-100 max-h-screen"
												: "scale-95 opacity-0 max-h-0"
												}`}
											style={{ transformOrigin: "top" }}>
											<div className="p-2 bg-[#F1F1F1] w-[95%] rounded-b-xl h-full shadow-lg">
												<div className="flex items-start gap-2.5 w-full">
													<div className="flex flex-col gap-1 w-full">
														<div className="mt-2 space-y-4">
															{item?.itemData?.map(
																(
																	subItem: any,
																	subIndex: number
																) => (
																	<div
																		key={
																			subItem.combinedName
																		}
																		className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between items-center p-4 bg-white rounded-lg transition-all"
																		style={{
																			boxShadow:
																				"1px 2px 4px 0px #00000040 inset",
																		}}>
																		<div className="text-md font-semibold text-gray-700">
																			{subIndex +
																				1}
																			.{" "}
																			{
																				subItem.combinedName
																			}
																		</div>
																		<div className="flex items-center space-x-2 text-lg font-semibold text-yellow-500">
																			<BiCoinStack className="h-7 w-7 text-[#FF9900]" />
																			<span>
																				{
																					subItem.coins
																				}
																			</span>
																		</div>
																	</div>
																)
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div
										className={`w-full justify-between text-[#7D7D7D] relative h-auto rounded-xl bg-white p-4 font-medium space-y-1 box-border mb-3 ${awardedBonus === item.coins
											? "bg-green-50 "
											: ""
											}`}
										style={{
											boxShadow:
												"0px 4px 4px 0px #00000040",
										}}
										onMouseEnter={() =>
											handleAwardBonus(index)
										}>
										<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
											<div>
												<h3 className="text-lg font-semibold text-gray-700">
													Bonus Coins{" "}
													{item?.type === "add"
														? "Awarded"
														: "Deducted"}
												</h3>
											</div>
											<div className="flex items-center space-x-3">
												<div className="text-lg font-semibold">
													{item?.type === "add" ? (
														<span className="text-green-600">
															+{item?.coins}
														</span>
													) : (
														<span className="text-red-500">
															-{item?.coins}
														</span>
													)}
												</div>
												<BiCoinStack className="h-10 w-10 text-[#FF9900]" />
											</div>
										</div>

										<div className="mt-4 flex flex-col sm:flex-row sm:justify-between text-sm text-gray-500">
											<div>
												<span className="font-semibold">
													Date:{" "}
												</span>
												{item?.createdAt.split(" ")[0]}{" "}
												{item?.createdAt.split(" ")[1]}
											</div>
											<div>
												<span className="font-semibold text-orange-500">
													{item?.currentCoins}
												</span>
											</div>
										</div>
										{awardedBonus === index && (
											<div
												className={`absolute inset-0 bg-opacity-75 flex items-center justify-center rounded-lg text-center font-semibold ${item.type === "add"
													? "bg-green-200"
													: "bg-red-200"
													}`}>
												{item.type === "add" ? (
													<span className="text-green-600 font-bold px-2">
														{" "}
														Bonus Awarded!
													</span>
												) : (
													<span className="text-red-500 font-bold px-2">
														{" "}
														Bonus Removed!
													</span>
												)}
											</div>
										)}
									</div>
								)}
							</div>
						);
					})
				) : (
					<div
						className="p-4 mb-4 text-sm text-red-500 rounded-xl bg-red-50 border border-red-400 font-normal w-fit mt-10 mx-auto"
						role="alert">
						<span className="font-semibold mr-2">
							{getNoDataMessage()}
						</span>
					</div>
				)}
			</section>
		</div>
	);
};

export default ContractorCoinHistory;
