import { BiSolidCoinStack } from "react-icons/bi";
import { RiGift2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";

export const PointsHeader = () => {
  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  const styles = {
    boxShadowStyle: {
      boxShadow: "0px 0.5px 7px 0px #00000040",
    },
  };

  return (
    <>
      <div className="py-6 md:px-[30px] px-[20px] md:p-8 bg-gradient-to-r from-blue-300 to-blue-100 border-white border-b-[4px]"
        style={styles.boxShadowStyle}
      >
        <div className="flex flex-col md:flex-row  justify-between items-start md:items-center gap-4 md:gap-8 ">
          <div className="flex flex-col gap-2 ">
            <div className="font-bold text-2xl leading-8 text-gray-800">
              {userDetails?.name || "Harsh Pal"}
            </div>
            <span className="font-medium text-lg leading-6 text-gray-600">
              Grade: {userDetails?.contractorGrade?.toUpperCase()}
            </span>
          </div>
          <div className="flex flex-col  sm:flex-row gap-6 sm:gap-8 items-center     w-full    sm:w-fit">
            {/* Available Points */}
            <div className="flex flex-col gap-2 py-2 items-center">
              <div className="font-medium text-sm/6 md:text-lg leading-6 text-gray-700">
                Available Points
              </div>
              <div className="flex items-center gap-2 py-1 px-4 bg-white border rounded-full shadow-sm  min-w-[130px]">
                <BiSolidCoinStack className="text-yellow-500 w-8 h-6" />
                <span className="font-semibold text-sm/6 md:text-xl text-gray-800">
                  {userDetails?.totalReward || "0"}
                </span>
              </div>
            </div>

            {/* Redeem Points */}
            <div className="flex flex-col gap-2 py-2 items-center">
              <div className="font-medium text-sm/6 md:text-lg leading-6 text-gray-700">
                Redeem Points
              </div>
              <div className="flex items-center gap-2 py-1 px-4 bg-white border rounded-full shadow-sm min-w-[130px]">
                <RiGift2Fill className="text-yellow-500 w-8 h-6" />
                <span className="font-semibold text-sm/6 md:text-xl text-gray-800">
                  {userDetails?.totalReward || "0"}
                </span>
              </div>
            </div>

            {/* This Month's Points */}
            <div className="flex flex-col gap-2 py-2 items-center">
              <div className="font-medium text-sm/6 md:text-lg leading-6 text-gray-700">
                This Month's Points
              </div>
              <div className="flex items-center gap-2 py-1 px-4 bg-white border rounded-full shadow-sm min-w-[130px]">
                <BiSolidCoinStack className="text-yellow-500 w-8 h-6" />
                <span className="font-semibold text-sm/6 md:text-xl text-gray-800">
                  0
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* <div className="py-6 md:px-[30px] px-[20px] md:p-8 bg-gradient-to-r from-blue-300 to-blue-100 ">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 md:gap-8">
          <div className="flex flex-col gap-2 ">
            <div className="font-bold text-2xl leading-8 text-gray-800">
              {userDetails?.name || "Harsh Pal"}
            </div>
            <span className="font-medium text-lg leading-6 text-gray-600">
              Grade: {userDetails?.contractorGrade?.toUpperCase()}
            </span>
          </div>

          <div className="flex flex-col  sm:flex-row gap-6 sm:gap-8 items-center     w-full    sm:w-fit">
            <div className="flex flex-col gap-2 py-2 items-center">
              <div className="font-medium text-lg leading-6 text-gray-700">
                Available Points
              </div>
              <div className="flex items-center gap-2 py-1 px-4 bg-white border rounded-full shadow-sm  min-w-[130px]">
                <BiSolidCoinStack className="text-yellow-500 w-8 h-6" />
                <span className="font-semibold text-xl text-gray-800">
                  {userDetails?.totalReward || "0"}
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-2 py-2 items-center">
              <div className="font-medium text-lg leading-6 text-gray-700">
                Redeem Points
              </div>
              <div className="flex items-center gap-2 py-1 px-4 bg-white border rounded-full shadow-sm min-w-[130px]">
                <RiGift2Fill className="text-yellow-500 w-8 h-6" />
                <span className="font-semibold text-xl text-gray-800">
                  {userDetails?.totalReward || "0"}
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-2 py-2 items-center">
              <div className="font-medium text-lg leading-6 text-gray-700">
                This Month's Points
              </div>
              <div className="flex items-center gap-2 py-1 px-4 bg-white border rounded-full shadow-sm min-w-[130px]">
                <BiSolidCoinStack className="text-yellow-500 w-8 h-6" />
                <span className="font-semibold text-xl text-gray-800">
                  0
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
