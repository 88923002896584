import { useEffect, useState } from "react";
import chatbot from "../../assets/chatbot.jpg"
import { useLocation } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";  
import { MdPhone } from "react-icons/md";

const WhatsappIcon = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const location = useLocation()
  const [hideIcon,setHiddenIcon] = useState(false)

   useEffect(() => {
    if(location.pathname.startsWith("/admin")){
      setHiddenIcon(true)
    } else {
       setHiddenIcon(false)
    }
   },[location])

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const phoneNumber = '9258294692';
  const handleDial = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleWhatsappClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <div
      className={`fixed bottom-[4.76rem] right-4  sm:bottom-[7.76rem] md:bottom-[2.5rem] sm:right-6 z-50 flex justify-center items-center w-[70px] h-[70px]  rounded-[29px] bg-transparent ${hideIcon ? "hidden" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setShowTooltip(!showTooltip)}
    >
      <img src={chatbot} alt="chatbot" className="w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] rounded-[100px]" />
      {/* <BsWhatsapp className="w-[38px] h-[38px]" /> */}
      {showTooltip && (
        <div className="absolute bottom-2 md:bottom-1  -left-[245px] bg-green-100 flex flex-col gap-[5px] text-black px-2 py-1 md:py-3 rounded-md">
          <div 
          className="cursor-pointer flex nowrap whitespace-nowrap items-center gap-[10px]"
           onClick={handleWhatsappClick} >
            <span className="">Connect Through WhatsApp</span>
             <FaWhatsapp className="w-[20px] h-[20px] text-green-500" />
            </div>

            <div 
            className="cursor-pointer flex nowrap whitespace-nowrap items-center pt-1 border-t border-t-slate-400 gap-[10px]" 
              onClick={handleDial}>
                <span className="">Connect Through Call </span>
          <MdPhone className="text-turkishBlue-default" />
           </div>
        </div>
      )}
    </div>
  );
};

export default WhatsappIcon;
