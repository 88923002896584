import { ChangeEvent, useEffect, useState } from "react";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { LuPencilLine } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import Dropdown from "../Inventory/components/Dropdown";
import { usePublishedContractorMutation } from "../../../Redux/Api/Contractor";
import {
  useDeleteRewardMutation,
  useEditRewardMutation,
  useGetAdminRewardsQuery,
} from "../../../Redux/Api/Admin/Reward";
import { Rewards } from "../../../Redux/Api/types";
import { Coupons } from "./components/Coupons";
import { RewardChange } from "./components/Filter/RewardChange";
import * as XLSX from "xlsx";
import { MdDelete } from "react-icons/md";
import { UploadImage } from "./components/uploadImage";
import { LiaEditSolid } from "react-icons/lia";
import TableLoading from "../../../common/admin-common/TableLoading";
import TableNoData from "../../../common/admin-common/TableNoData";
import { exportToExcel } from "../../../utils/exportToExcel";
import ConfirmModel from "../../../utils/ConfirmModel";
import { toast } from "react-toastify";

interface RewardStates {
  [key: number]: string;
}

export const Reward = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [limit, setLimit] = useState("20");
  const [page, setPage] = useState<any>(1);
  const [imageId, setImageId] = useState<any>("");
  const [totalPages, setTotalPages] = useState(1);
  const [fetchContractors, { data, isLoading }] = usePublishedContractorMutation();
  const { data: Reward } = useGetAdminRewardsQuery();
  const [isCouponVisible, setIsCouponVisible] = useState<boolean>(false);
  const [isImageUpload, setIsImageUpload] = useState<boolean>(false);
  const [rewardStates, setRewardStates] = useState<RewardStates>({});
  const [editReward] = useEditRewardMutation();
  const [deleteRewarddMutation] = useDeleteRewardMutation();
  const [editableNameIndex, setEditableNameIndex] = useState<number | null>(
    null
  );
  const [newName, setNewName] = useState<string>("");
  const [search, setSearch] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const handleDeleteClick = (id: string) => {
    setSelectedItemId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItemId(null);
  };

  const handleConfirm = async (isConfirmed: boolean) => {
    if (isConfirmed && selectedItemId !== null) {
      await deleteReward(selectedItemId);
    }
    handleCloseModal();
  };


  const handleSelect = (option: any) => {
    setSelectedOption(option);
    setLimit(option);
  };

  const options = ["9", "8", "7"];

  const handleShowCoupon = () => {
    setIsCouponVisible(true);
  };

  const handleCloseCoupon = () => {
    setIsCouponVisible(false);
  };
  const handleCloseImageUpload = () => {
    setIsImageUpload(false);
  };

  const handleRewardChange = (rowIndex: number, grade: string) => {
    setRewardStates((prevState: RewardStates) => ({
      ...prevState,
      [rowIndex]: grade,
    }));
  };

  const handleCloseRewardChange = (rowIndex: number) => {
    setRewardStates((prevState: RewardStates) => ({
      ...prevState,
      [rowIndex]: "",
    }));
  };

  const handleStatus = async (status: boolean, id: string) => {
    const body = { status: status };
    try {
      await editReward({ id, body }); 
      toast.success("Reward status updated successfully!", { position: "top-center" });
    } catch (error) {
      toast.error("Error updating reward status:", { position: "top-center" });
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchContractors({ limit, page });
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching contractors:", error);
      }
    };

    fetchData();
  }, [page, limit, fetchContractors]);

  // const prevPage = () => {
  //   if (page > 1) {
  //     setPage(page - 1);
  //   }
  // };

  // const nextPage = () => {
  //   if (page < totalPages) {
  //     setPage(page + 1);
  //   }
  // };

  const deleteReward = async (id: string) => {
    try {
      await deleteRewarddMutation(id).unwrap();
      toast.success(
        "Reward deleted successfully", { position: "top-center" }
      )
    } catch (error) {
      console.error("Error deleting contractor:", error);
    }
  };



  const handleNameClick = (index: number, currentName: string) => {
    setEditableNameIndex(index);
    setNewName(currentName);
  };

  const saveName = async (id: string) => {
    const body = { name: newName };
    try {
      await editReward({ id, body });
      toast.success("Reward name updated successfully!", { position: "top-center" });
    } catch (error) {
      toast.error("Error updating reward name:", { position: "top-center" });
    } finally {
      setEditableNameIndex(null);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.toLowerCase())

  }

  const filtersData = Reward?.response?.filter((item: any) => item?.name?.toLowerCase().includes(search))

  const resetAll = (): void => {
    setSearch("")
  }


  const exportData = () => {
    const filteredData = (filtersData || []).map((item: any) => ({
      "Coupon Name": item?.name,
      "Grade A": item?.points?.rewardA,
      "Grade B": item?.points?.rewardB,
      "Grade C": item?.points?.rewardC,
      "Status": item.status ? "Active" : "Inactive",
    }));

    exportToExcel(filteredData, "RewardData");
  };

  return (
    <>
      <div className="w-full h-auto px-3 mt-3 pb-3">

        {/* Upload Image Modal */}
        {isImageUpload && (
          <UploadImage
            onClose={handleCloseImageUpload}
            id={imageId}
          />
        )}

        {/* Confirm Delete */}
        <ConfirmModel
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
        />

        <div className="w-full   bg-white rounded-t-xl mb-4 pt-2 z-[-1px]">
          {/* Filters Start */}
          <div className="flex justify-between py-2 px-4">
            <div className="flex items-center gap-2">
              <input
                type="text"
                onChange={handleSearchChange}
                value={search}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
                placeholder="Search.."
                style={{
                  boxShadow:
                    "0px 0px 7.6px 0px #00000040 inset",
                }}
              />
            </div>
            <div className="flex gap-2">
              <button
                onClick={resetAll}
                className="flex gap-1 items-center text-sm py-2 px-3 border border-red-700 text-red-700 rounded-xl bg-red-100 hover:text-white  hover:bg-red-500 duration-150 ">
                Reset
              </button>
              <button
                onClick={handleShowCoupon}
                className="flex gap-1 items-center text-sm py-2 px-3 border border-blue-700 text-blue-700 rounded-xl bg-blue-100 hover:text-white  hover:bg-blue-700 duration-150 ">
                Add Coupons
              </button>
              <button
                onClick={exportData}
                className="flex gap-1 items-center text-sm py-2 px-3 border border-[#00CB4A] text-[#00CB4A] rounded-xl bg-green-100 hover:text-white  hover:bg-green-500 duration-150 ">
                Excel{" "}
                <RiFileExcel2Fill className="w-[20px] h-[20px]" />
              </button>
            </div>
          </div>
           {/* Filters End */}
           
          {/* Add Coupon Modal */}
          {isCouponVisible && <Coupons onClose={handleCloseCoupon} />}

          {/* Table Start */}
          <div className="w-full overflow-x-auto px-3 pb-32 ">
            <table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
              <thead className="bg-white divide-y divide-gray-200">
                <tr className="border-t border-b border-gray-300">
                  <th className="px-6 py-3 text-center text-sm font-inter text-[#1E293B] w-[220px]">
                    Images
                  </th>
                  <th className="px-6 py-3 text-center text-sm font-inter text-[#1E293B]">
                    Coupon Name
                  </th>
                  <th className="px-6 py-3 text-center text-sm font-inter text-[#1E293B] whitespace-nowrap">
                    Reward
                  </th>
                  <th className="px-6 py-3 text-center  text-sm font-inter text-[#1E293B]">
                    Status
                  </th>
                  <th className="px-6 py-3 text-center  text-sm font-inter text-[#1E293B]">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white ">
                {
                  isLoading ? <tr>
                    <TableLoading colCount={5} />
                  </tr> : !isLoading && filtersData?.length > 0 ? filtersData?.map(
                    (i: Rewards, index: number) => (
                      <tr
                        key={index}
                        className="shadow-md mb-6 space-y-4 rounded-xl   ">
                        <td className="p-3 w-[240px] relative">
                          <div>
                            <img
                              src={i?.image}
                              className="rounded-md h-[140px] w-[190px] object-cover"
                              alt={i?.name}
                            />

                          </div>
                          <button onClick={() => {
                            setIsImageUpload(true);
                            setImageId(i?._id);
                          }} className="absolute bottom-3 right-3 text-xl"><LiaEditSolid /></button>
                        </td>
                        <td className=" whitespace-nowrap font-inter text-sm text-[#6E7079]  transition-all duration-300 ease-in-out">
                          {editableNameIndex === index ? (
                            <div className="h-full flex flex-col justify-center items-center gap-1">
                              <input
                                value={newName}
                                onChange={
                                  handleNameChange
                                }
                                className="max-w-[120px] border px-3 py-1 border-gray-300 outline-none  rounded-lg"
                              />
                              <span
                                className="text-white font-bold px-3 text-center text-sm py-1 bg-[#334155] rounded-md w-[120px] cursor-pointer transition-all duration-300"
                                onClick={() =>
                                  saveName(i._id)
                                }>
                                Save
                              </span>
                            </div>
                          ) : (
                            <span className="flex gap-1 items-center justify-center transition-all duration-300 ">
                              <span className="text-[#939393]">{i?.name}</span>{" "}
                              <LiaEditSolid
                                className="text-[#334155] cursor-pointer transition-transform duration-300"
                                onClick={() =>
                                  handleNameClick(
                                    index,
                                    i?.name
                                  )
                                }
                              />
                            </span>
                          )}
                        </td>
                        <td className=" whitespace-nowrap font-inter text-sm text-[#6E7079]">
                          <div className="relative">
                            <div className="flex gap-[12px]">
                              <div className="flex flex-col ">
                                <span className="text-[#939393] font-medium">
                                  Grade A
                                </span>
                                <span className="text-amber-500 flex gap-[23px] items-center relative">
                                  {
                                    i?.points
                                      ?.rewardA
                                  }{" "}
                                  <LiaEditSolid
                                    className="cursor-pointer text-black"
                                    onClick={() =>
                                      handleRewardChange(
                                        index,
                                        "A"
                                      )
                                    }
                                  />
                                  {rewardStates[
                                    index
                                  ] === "A" && (
                                      <div
                                        className="absolute top-[20px] left-[-60px] z-20 "
                                        style={{
                                          transform:
                                            "translateX(-50%)",
                                        }}>
                                        <RewardChange
                                          grade={
                                            "a"
                                          }
                                          id={
                                            i?._id
                                          }
                                          handleClose={() =>
                                            handleCloseRewardChange(
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[#939393] font-medium">
                                  Grade B
                                </span>
                                <span className="text-amber-500 flex gap-[23px] items-center relative">
                                  {
                                    i?.points
                                      ?.rewardB
                                  }{" "}
                                  <LiaEditSolid
                                    className="cursor-pointer text-black"
                                    onClick={() =>
                                      handleRewardChange(
                                        index,
                                        "B"
                                      )
                                    }
                                  />
                                  {rewardStates[
                                    index
                                  ] === "B" && (
                                      <div
                                        className="absolute top-[20px] left-[-60px]  z-20 "
                                        style={{
                                          transform:
                                            "translateX(-50%)",
                                        }}>
                                        <RewardChange
                                          grade={
                                            "b"
                                          }
                                          id={
                                            i?._id
                                          }
                                          handleClose={() =>
                                            handleCloseRewardChange(
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                </span>
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[#939393] font-medium">
                                  Grade C
                                </span>
                                <span className="text-amber-500 flex gap-[23px] items-center relative">
                                  {
                                    i?.points
                                      ?.rewardC
                                  }{" "}
                                  <LiaEditSolid
                                    className="cursor-pointer text-black"
                                    onClick={() =>
                                      handleRewardChange(
                                        index,
                                        "C"
                                      )
                                    }
                                  />
                                  {rewardStates[
                                    index
                                  ] === "C" && (
                                      <div
                                        className="absolute top-[20px] left-[-60px]  z-20 "
                                        style={{
                                          transform:
                                            "translateX(-50%)",
                                        }}>
                                        <RewardChange
                                          grade={
                                            "c"
                                          }
                                          id={
                                            i?._id
                                          }
                                          handleClose={() =>
                                            handleCloseRewardChange(
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="font-inter text-sm text-[#6E7079]">
                          <span
                            className={`${i.status
                              ? "bg-green-100 border-green-600 text-green-600 hover:bg-green-600 hover:text-white"
                              : "bg-red-100 border-red-600 text-red-600 hover:bg-red-600 hover:text-white"
                              } border  px-3 py-2  cursor-pointer text-[12px] leading-[14px] rounded-xl`}
                            onClick={() =>
                              handleStatus(
                                !i?.status,
                                i?._id
                              )
                            }>
                            {i.status
                              ? "Active"
                              : "Inactive"}
                          </span>
                        </td>

                        <td className=" font-inter text-2xl  w-[10%] cursor-pointer text-red-600">
                          <span
                            onClick={() => handleDeleteClick(i?._id)}
                          >
                            <MdDelete />

                          </span>
                        </td>
                      </tr>
                    )
                  ) : <TableNoData colCount={5} text={"Data Not Available"} />
                }

              </tbody>
            </table>
          </div>
              {/* Table End */}
          
          {/* Pagination Start */}
          {/* <div className="flex flex-row justify-between p-2 m-1 border-t-2 ">
            <div>
              <div className="flex flex-row gap-3">
                <Dropdown
                  options={options}
                  onSelect={handleSelect}
                />
                <p className="text-[#A6A8B1]">Items Per Page</p>
                <p>Categories</p>
              </div>
            </div>
            <div>
              <div className="flex flex-row gap-3">
                <Dropdown
                  options={options}
                  onSelect={handleSelect}
                />
                <p className="text-[#A6A8B1]">of 44 pages</p>
                <div className="flex flex-row items-center gap-2 text-[#666666]">
                  <span>
                    <FaChevronLeft size={15} />
                  </span>
                  <span>
                    <FaChevronRight size={15} />
                  </span>
                </div>
              </div>
            </div>
          </div> */}
            {/* Pagination End */}
        </div>
      </div>



    </>
  );
};



