import { useCallback, useEffect, useState } from "react";
import { useGetContractorCategoryQuery } from "../Redux/Api/Contractor";
import CategoryRewardCard from "../screens/Profile/Components/CategoryRewardCard";
import { CombinedState } from "../types/api-types";
import { useSearchFiltersMutation } from "../Redux/Api/ProductAPI";
import { RingLoader } from "react-spinners";
import usePageMeta from "./hooks/usePageMeta";
import { chawlaispat } from "../Redux/apiRoutes";
import img1 from "../assets/Ellipse 1937.png";
import img2 from "../assets/Ellipse 1938.png";
import img3 from "../assets/Ellipse 1939.png";
import reward from "../assets/rewardassgin.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import { FaAngleDown } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import AssignedRewardCard from "./AssignedRewardCard";
import { RiArrowDropRightLine, RiArrowLeftSLine } from "react-icons/ri";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { debounce } from "lodash";

export const AssignedRewardss = () => {
	const { data: categoryData, isLoading: isCategoryLoading } =
		useGetContractorCategoryQuery(undefined, {
			refetchOnMountOrArgChange: true,
			refetchOnFocus: true,
			refetchOnReconnect: true,
		});

	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
	const [mobileSelectedCategories, setMobileSelectedCategories] = useState<string[]>([]);
	const [isAllSelected, setIsAllSelected] = useState<boolean>(true);

	const [searchFilters, { data: SearchProductsResponse, isLoading: isProductLoading }] = useSearchFiltersMutation();
	const [isFetching, setIsFetching] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setPerPageLimit] = useState(6);
	const [combinedState, setCombinedState] = useState<CombinedState>({
		search: null,
		company: [],
		category: categoryData?.catgeories?.map((item: any) => item._id) || [],
		limit: itemsPerPage,
		page: currentPage,
	});
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
	const handleResize = () => {
		setIsMobile(window.innerWidth < 640);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (isMobile) {
			setPerPageLimit(10);
			setIsAllSelected(true);
			handleApplyFilters();
			setMobileSelectedCategories([]);
		} else {
			setPerPageLimit(6);
			handleClick("All");
		}
	}, [isMobile]);

	const toggleDropdown = (): void => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (categoryData) {
			setCombinedState((prevState) => ({
				...prevState,
				category:
					categoryData?.catgeories?.map((item: any) => item._id) ||
					[],
			}));
		}
	}, [categoryData]);

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		if (!categoryData || combinedState?.category?.length === 0) return;

	// 		try {
	// 			const payload = {
	// 				page: currentPage,
	// 				combinedState: {
	// 					...combinedState,
	// 					limit: itemsPerPage,
	// 				},
	// 			};

	// 			await searchFilters(payload);
	// 		} catch (error) {
	// 			console.error("Error found", error);
	// 		}
	// 	};

	// 	fetchData();
	// }, [combinedState, searchFilters, currentPage, itemsPerPage, categoryData]);
	const debouncedFetchData = useCallback(
		debounce(async (payload) => {
		  setIsFetching(true); // Start fetching when the API call is made
		  try {
			await searchFilters(payload);
		  } catch (error) {
			console.error("Error found", error);
		  } finally {
			setIsFetching(false); // Stop fetching after the API call is complete
		  }
		}, 300), // Set debounce delay as per your need (300ms is commonly used)
		[]
	  );
	
	  useEffect(() => {
		// Run the debounced function when the combinedState changes
		if (!categoryData || combinedState?.category?.length === 0) return;
	
		const payload = {
		  page: currentPage,
		  combinedState: {
			...combinedState,
			limit: itemsPerPage,
		  },
		};
	
		// Use the debounced function
		debouncedFetchData(payload);
	
		// Cleanup the debounce on unmount
		return () => {
		  debouncedFetchData.cancel();
		};
	  }, [combinedState, currentPage, itemsPerPage, categoryData, debouncedFetchData]);

	// handle desktop filters

	const handleClick = (id: string) => {
		if (id === "All") {
			setSelectedCategory(null);
			setIsAllSelected(true);
			setCombinedState((prevState) => ({
				...prevState,
				category:
					categoryData?.catgeories?.map((item: any) => item?._id) ||
					[],
			}));
		} else {
			setSelectedCategory(id);
			setIsAllSelected(false);
			setCombinedState((prevState) => ({
				...prevState,
				category: [id],
			}));
		}
		setCurrentPage(1);
	};

	// handle mobile filters

	const handleMobileCheckboxChange = (id: string) => {
		if (id === "All") {
			setMobileSelectedCategories([]);
			setIsAllSelected(true);
		} else {
			setMobileSelectedCategories((prev) => {
				if (prev.includes(id)) {
					return prev.filter((category) => category !== id);
				} else {
					return [...prev, id];
				}
			});
			setIsAllSelected(false);
		}
	};

	const handleApplyFilters = () => {
		if (isAllSelected) {
			setCombinedState((prevState) => ({
				...prevState,
				category:
					categoryData?.catgeories?.map((item: any) => item?._id) ||
					[],
			}));
		} else {
			setCombinedState((prevState) => ({
				...prevState,
				category: mobileSelectedCategories,
			}));
		}
		setIsOpen(false);
		setCurrentPage(1);
	};

	// reset mobile filters
	const resetMobileFilters = () => {
		setMobileSelectedCategories([]);
		setIsAllSelected(true);
		setCombinedState((prevState) => ({
			...prevState,
			category:
				categoryData?.catgeories?.map((item: any) => item._id) || [],
			search: null,
			company: [],
		}));
		setPerPageLimit(10);
		setCurrentPage(1);
		setIsOpen(false);
	};

	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
		setCombinedState((prevState) => ({
			...prevState,
			page: newPage,
		}));
	};

	const totalPages = Math.ceil(
		(SearchProductsResponse?.total || 0) / itemsPerPage
	);

	const userDetails = useSelector(
		(state: RootState) => state.Login.ContractorData
	);

	const title =
		"Assigned Rewards |  Building Material Supplier | Chawla Ispat ";
	const description = "description";
	const baseUrl = chawlaispat;

	usePageMeta(title, description, baseUrl);

	if (isCategoryLoading || isProductLoading) {
		return (
			<div className="flex justify-center h-[50vh] items-center ">
				<RingLoader color="#5097A4" size={200} />
			</div>
		);
	}

	const styles = {
		boxShadow1: {
			boxShadow: "0px 4px 6.2px 0px #00000040",
		},
		boxShadowStyle: {
			boxShadow: "0px 0.5px 7px 0px #00000040",
		},
	};

	const isLoadingState = isProductLoading || isFetching;
	return (
		<div className="font-inter bg-white">
			{!userDetails ? (
				<div className="container mx-auto h-auto lg:p-3">
					<div className="w-full grid grid-cols-1 lg:grid-cols-2">
						<div className="w-full relative ">
							<div className="relative mt-4">
								<img
									src={reward}
									alt=""
									className="p-4 lg:p-0 relative z-30 w-full"
								/>
								<img
									src={img2}
									alt=""
									className="hidden lg:block absolute bottom-[-15px] left-0 z-10 w-44 xl:w-auto"
								/>
								<img
									src={img3}
									alt=""
									className="hidden lg:block absolute bottom-0 right-0 z-20 w-44 xl:w-auto"
								/>
							</div>
						</div>
						<div className="w-full h-[200px] relative lg:h-auto">
							<img
								src={img1}
								alt=""
								className="relative top-[-51.5px] z-[-1px] hidden lg:block"
							/>
							<div className="w-[95%] mx-auto p-3 lg:absolute top-[100px] xl:top-[150px] right-3 text-lg lg:text-xl xl:text-2xl lg:text-end lg:pr-4 2xl:right-14 font-bold lg:text-white">
								<p className="">
									Join with your account to unlock
								</p>{" "}
								<p>the rewards page. Please sign up or</p>{" "}
								<p className="mb-5">log in to continue.</p>
								<Link
									to="/signup"
									className="bg-[#FEBD69] text-black rounded-lg underline py-2 px-3 text-[14px]">
									Signup / Login{" "}
								</Link>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="py-12 w-11/12 m-auto">
					<div className="flex justify-between">
						<div className="font-[700] text-[32px] leading-[38px]">
							Assigned Rewards
						</div>
					</div>
					{/*  desktop filters */}
					<div className=" hidden sm:flex gap-[24px] mt-10 mb-4  rounded-xl py-4 px-3 max-w-fit border-4 border-[#5097A4] font-inter" style={styles.boxShadowStyle}>
						<span className="font-[600] text-[16px] leading-[20px]">
							Categories:
						</span>

						<div className="flex  gap-[12px] overflow-x-auto font-[600] text-[16px] leading-[20px] overflow-y-hidden scrollbar-customXy text-gray-500 ">
							{categoryData?.catgeories.length > 0 && (
								<div key="all">
									<span
										className={`mr-4 cursor-pointer px-2 py-1 rounded ${isAllSelected ? "text-blue-300" : "text-gray-500"
											}`}
										onClick={() => handleClick("All")}>
										All
									</span>
								</div>
							)}
							{categoryData?.catgeories?.length > 0 ? (
								categoryData?.catgeories?.map(
									(item: { _id: string; name: string }) => (
										<div
											key={item?._id}
											className="whitespace-nowrap ">
											<span
												className={`mr-2 cursor-pointer px-2 py-1 rounded ${selectedCategory ===
													item._id
													? "text-blue-300"
													: combinedState.category?.includes(
														item._id
													)
														? ""
														: "bg-transparent"
													}`}
												onClick={() =>
													handleClick(item._id)
												}>
												{item.name}
											</span>
										</div>
									)
								)
							) : (
								<p>No categories available</p>
							)}
						</div>
					</div>
					{/* mobile filters */}
					<div className="flex sm:hidden gap-[24px] mt-10 mb-4">
						<span className="relative">
							<span
								className="font-[600] text-[16px] leading-[20px] flex items-center gap-1 relative cursor-pointer"
								onClick={toggleDropdown}>
								Categories:
								<FaAngleDown
									className={`transition-transform duration-300 ${isOpen ? "transform rotate-180" : ""
										}`}
								/>
								<span className="text-sm space-x-2 w-[200px] overflow-auto text-nowrap">
									{" "}
									{categoryData?.catgeories.length > 0 &&
										isAllSelected && "All"}
								</span>
							</span>

							{isOpen && (
								<div className="z-30 absolute top-8 right-1 bg-white rounded-lg shadow-lg border w-64 transition-transform duration-300">
									<span
										className="py-1 text-[25px] flex justify-end pr-3 mt-1 cursor-pointer relative"
										onClick={toggleDropdown}>
										<RxCross2 />
									</span>
									<ul className="h-48 w-56 px-3 rounded-lg pb-3 pt-1 overflow-y-auto text-sm text-gray-700 border-2 mb-1 mx-auto">
										{categoryData?.catgeories.length >
											0 && (
												<li>
													<div className="flex items-center ps-2 rounded">
														<input
															id="checkbox-item-13"
															type="checkbox"
															// defaultValue=""
															className="w-5 h-5 text-[#5097A4] rounded"
															checked={isAllSelected}
															onChange={() => {
																setIsAllSelected(
																	!isAllSelected
																);
																setMobileSelectedCategories(
																	[]
																);
															}}
														/>
														<label
															htmlFor="checkbox-item-13"
															className="w-full py-2 ms-2 text-sm font-medium text-gray-900">
															All
														</label>
													</div>
												</li>
											)}

										{categoryData?.catgeories?.length > 0
											? categoryData?.catgeories?.map(
												(item: {
													_id: string;
													name: string;
												}) => (
													<li>
														<div className="flex items-center ps-2 rounded">
															<input
																id="checkbox-item-13"
																type="checkbox"
																defaultValue=""
																className="w-4 h-4 text-[#5097A4] rounded"
																checked={mobileSelectedCategories.includes(
																	item._id
																)}
																onChange={() =>
																	handleMobileCheckboxChange(
																		item._id
																	)
																}
															/>
															<label
																htmlFor="checkbox-item-13"
																className="w-full py-2 ms-2 text-xs font-medium text-gray-900">
																{item?.name}
															</label>
														</div>
													</li>
												)
											)
											: <div className="h-full w-full flex justify-center items-center p-2"><span className="text-xs text-red-400  font-bold">No categories available</span></div>}
									</ul>
									<div className="flex w-full h-auto justify-center">
										<button
											onClick={handleApplyFilters}
											className="flex items-center bg-[#5097A4] px-4 py-1 text-white rounded-md mx-auto my-2 text-[13px]">
											Apply
										</button>
										<button
											onClick={resetMobileFilters}
											className="flex items-center bg-red-400 px-4 py-1 text-white rounded-md mx-auto my-2 text-[13px]">
											Reset
										</button>
									</div>
								</div>
							)}
						</span>
					</div>


					<div>
						{isLoadingState ? (
							<div className="flex justify-center">
								<RingLoader color="#5097A4" size={50} />
							</div>
						) : categoryData?.catgeories.length > 0 &&
							SearchProductsResponse?.products?.length > 0 ? (
							<>
								{SearchProductsResponse.products.map(
									(product: any) => (
										<div key={product?.id} className="mb-4">
											<AssignedRewardCard
												product={product}
											/>
										</div>
									)
								)}

								<div className="flex justify-center my-4 text-[#5097A4] text-sm font-medium items-center mt-3">
									<button
										onClick={() =>
											handlePageChange(currentPage - 1)
										}
										disabled={currentPage === 1}
										className="px-4 py-2 ml-2   disabled:text-gray-300 flex items-center">
										<RiArrowLeftSLine className="h-8 w-8" />	Previous
									</button>
									<span className=" flex  items-center justify-center space-x-3 text-gray-700">
										<span>Page</span> <span className="px-2 py-1 bg-gray-200 rounded-md">{currentPage} </span> <span>of</span> <span className="px-2 py-1 bg-gray-200 rounded-md">{totalPages}</span>
									</span>
									<button
										onClick={() =>
											handlePageChange(currentPage + 1)
										}
										disabled={currentPage === totalPages}
										className="px-4 py-2 ml-2   disabled:text-gray-300 flex items-center">
										Next <MdOutlineKeyboardArrowRight className="h-8 w-8" />
									</button>
								</div>
							</>
						) : (
							<div className="w-full flex items-center flex-wrap justify-center gap-10 my-10 md:my-6">
								<div className="grid gap-4 w-60">
									<svg
										className="mx-auto"
										xmlns="http://www.w3.org/2000/svg"
										width={116}
										height={121}
										viewBox="0 0 116 121"
										fill="none">
										<path
											d="M0.206909 63.57C0.206909 31.7659 25.987 6.12817 57.6487 6.12817C89.2631 6.12817 115.079 31.7541 115.079 63.57C115.079 77.0648 110.43 89.4805 102.627 99.2755C91.8719 112.853 75.4363 121 57.6487 121C39.7426 121 23.4018 112.794 12.6582 99.2755C4.85538 89.4805 0.206909 77.0648 0.206909 63.57Z"
											fill="#5097A4"
										/>
										<path
											d="M72.7942 0.600875L72.7942 0.600762L72.7836 0.599331C72.3256 0.537722 71.8622 0.5 71.3948 0.5H22.1643C17.1256 0.5 13.0403 4.56385 13.0403 9.58544V107.286C13.0403 112.308 17.1256 116.372 22.1643 116.372H93.1214C98.1725 116.372 102.245 112.308 102.245 107.286V29.4482C102.245 28.7591 102.17 28.0815 102.019 27.4162L102.019 27.416C101.615 25.6459 100.67 24.0014 99.2941 22.7574C99.2939 22.7572 99.2937 22.757 99.2934 22.7568L77.5462 2.89705C77.5461 2.89692 77.5459 2.89679 77.5458 2.89665C76.2103 1.66765 74.5591 0.876968 72.7942 0.600875Z"
											fill="white"
											stroke="#5097A4"
										/>
										<circle
											cx="60.2069"
											cy={61}
											r="21.0256"
											fill="white"
										/>
										<path
											d="M74.6786 46.1412L74.6783 46.1409C66.5737 38.0485 53.4531 38.0481 45.36 46.1412C37.2552 54.2341 37.2551 67.3666 45.3597 75.4596C53.4529 83.5649 66.5739 83.5645 74.6786 75.4599C82.7716 67.3669 82.7716 54.2342 74.6786 46.1412ZM79.4694 41.3508C90.2101 52.0918 90.2101 69.5093 79.4694 80.2502C68.7166 90.9914 51.3104 90.9915 40.5576 80.2504C29.8166 69.5095 29.8166 52.0916 40.5576 41.3506C51.3104 30.6096 68.7166 30.6097 79.4694 41.3508Z"
											stroke="#5097A4"
										/>
										<path
											d="M83.2471 89.5237L76.8609 83.1309C78.9391 81.5058 80.8156 79.6106 82.345 77.6546L88.7306 84.0468L83.2471 89.5237Z"
											stroke="#5097A4"
										/>
										<path
											d="M104.591 94.4971L104.59 94.4969L92.7346 82.653C92.7342 82.6525 92.7337 82.652 92.7332 82.6515C91.6965 81.6018 90.0076 81.6058 88.9629 82.6505L89.3089 82.9965L88.9629 82.6505L81.8573 89.7561C80.8213 90.7921 80.8248 92.4783 81.8549 93.5229L81.8573 93.5253L93.7157 105.384C96.713 108.381 101.593 108.381 104.591 105.384C107.6 102.375 107.6 97.5062 104.591 94.4971Z"
											fill="#5097A4"
											stroke="#5097A4"
										/>
										<path
											d="M62.5493 65.6714C62.0645 65.6714 61.6626 65.2694 61.6626 64.7729C61.6626 62.7866 58.6595 62.7866 58.6595 64.7729C58.6595 65.2694 58.2576 65.6714 57.761 65.6714C57.2762 65.6714 56.8743 65.2694 56.8743 64.7729C56.8743 60.422 63.4478 60.4338 63.4478 64.7729C63.4478 65.2694 63.0458 65.6714 62.5493 65.6714Z"
											fill="#5097A4"
										/>
										<path
											d="M70.1752 58.0694H66.4628C65.9662 58.0694 65.5642 57.6675 65.5642 57.1709C65.5642 56.6862 65.9662 56.2842 66.4628 56.2842H70.1752C70.6717 56.2842 71.0737 56.6862 71.0737 57.1709C71.0737 57.6675 70.6717 58.0694 70.1752 58.0694Z"
											fill="#5097A4"
										/>
										<path
											d="M53.8596 58.0693H50.1472C49.6506 58.0693 49.2487 57.6673 49.2487 57.1708C49.2487 56.686 49.6506 56.2841 50.1472 56.2841H53.8596C54.3443 56.2841 54.7463 56.686 54.7463 57.1708C54.7463 57.6673 54.3443 58.0693 53.8596 58.0693Z"
											fill="#5097A4"
										/>
										<rect
											x="28.9248"
											y="16.3846"
											width="30.7692"
											height="2.05128"
											rx="1.02564"
											fill="#5097A4"
										/>
										<rect
											x="28.9248"
											y="100.487"
											width="41.0256"
											height="4.10256"
											rx="2.05128"
											fill="#5097A4"
										/>
										<rect
											x="28.9248"
											y="22.5385"
											width="10.2564"
											height="2.05128"
											rx="1.02564"
											fill="#5097A4"
										/>
										<circle
											cx="42.2582"
											cy="23.5641"
											r="1.53846"
											fill="#5097A4"
										/>
									</svg>

									<div>
										<h2 className="text-center text-black text-base font-semibold leading-relaxed pb-1">
											No category has been assigned to
											your account yet.
										</h2>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};



// import { useEffect, useState } from "react";
// import { useGetContractorCategoryQuery } from "../Redux/Api/Contractor";
// import CategoryRewardCard from "../screens/Profile/Components/CategoryRewardCard";
// import { CombinedState } from "../types/api-types";
// import { useSearchFiltersMutation } from "../Redux/Api/ProductAPI";
// import { RingLoader } from "react-spinners";
// import usePageMeta from "./hooks/usePageMeta";
// import { chawlaispat } from "../Redux/apiRoutes";
// import img1 from "../assets/Ellipse 1937.png";
// import img2 from "../assets/Ellipse 1938.png";
// import img3 from "../assets/Ellipse 1939.png";
// import reward from "../assets/rewardassgin.png";
// import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { RootState } from "../Redux/store";
// import { FaAngleDown } from "react-icons/fa";
// import { RxCross2 } from "react-icons/rx";

// export const AssignedRewardss = () => {
// 	const { data: categoryData, isLoading: isCategoryLoading } =
// 		useGetContractorCategoryQuery(undefined, {
// 			refetchOnMountOrArgChange: true,
// 			refetchOnFocus: true,
// 			refetchOnReconnect: true,
// 		});

// 	const [selectedCategory, setSelectedCategory] = useState<string | null>(
// 		null
// 	);
// 	const [mobileSelectedCategories, setMobileSelectedCategories] = useState<
// 		string[]
// 	>([]);
// 	const [isAllSelected, setIsAllSelected] = useState<boolean>(true);

// 	const [
// 		searchFilters,
// 		{ data: SearchProductsResponse, isLoading: isProductLoading },
// 	] = useSearchFiltersMutation();

// 	const [currentPage, setCurrentPage] = useState(1);
// 	const [itemsPerPage, setPerPageLimit] = useState(6);
// 	const [combinedState, setCombinedState] = useState<CombinedState>({
// 		search: null,
// 		company: [],
// 		category: categoryData?.catgeories?.map((item: any) => item._id) || [],
// 		limit: itemsPerPage,
// 		page: currentPage,
// 	});
// 	const [isOpen, setIsOpen] = useState<boolean>(false);
// 	const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
// 	const handleResize = () => {
// 		setIsMobile(window.innerWidth < 640);
// 	};

// 	useEffect(() => {
// 		window.addEventListener("resize", handleResize);
// 		return () => {
// 			window.removeEventListener("resize", handleResize);
// 		};
// 	}, []);

// 	useEffect(() => {
// 		if (isMobile) {
// 			setPerPageLimit(10);
// 			setIsAllSelected(true);
// 			handleApplyFilters();
// 			setMobileSelectedCategories([]);
// 		} else {
// 			setPerPageLimit(6);
// 			handleClick("All");
// 		}
// 	}, [isMobile]);

// 	const toggleDropdown = (): void => {
// 		setIsOpen(!isOpen);
// 	};

// 	useEffect(() => {
// 		if (categoryData) {
// 			setCombinedState((prevState) => ({
// 				...prevState,
// 				category:
// 					categoryData?.catgeories?.map((item: any) => item._id) ||
// 					[],
// 			}));
// 		}
// 	}, [categoryData]);

// 	useEffect(() => {
// 		const fetchData = async () => {
// 			if (!categoryData || combinedState?.category?.length === 0) return;

// 			try {
// 				const payload = {
// 					page: currentPage,
// 					combinedState: {
// 						...combinedState,
// 						limit: itemsPerPage,
// 					},
// 				};

// 				await searchFilters(payload);
// 			} catch (error) {
// 				console.error("Error found", error);
// 			}
// 		};

// 		fetchData();
// 	}, [combinedState, searchFilters, currentPage, itemsPerPage, categoryData]);

// 	// handle desktop filters

// 	const handleClick = (id: string) => {
// 		if (id === "All") {
// 			setSelectedCategory(null);
// 			setIsAllSelected(true);
// 			setCombinedState((prevState) => ({
// 				...prevState,
// 				category:
// 					categoryData?.catgeories?.map((item: any) => item?._id) ||
// 					[],
// 			}));
// 		} else {
// 			setSelectedCategory(id);
// 			setIsAllSelected(false);
// 			setCombinedState((prevState) => ({
// 				...prevState,
// 				category: [id],
// 			}));
// 		}
// 		setCurrentPage(1);
// 	};

// 	// handle mobile filters

// 	const handleMobileCheckboxChange = (id: string) => {
// 		if (id === "All") {
// 			setMobileSelectedCategories([]);
// 			setIsAllSelected(true);
// 		} else {
// 			setMobileSelectedCategories((prev) => {
// 				if (prev.includes(id)) {
// 					return prev.filter((category) => category !== id);
// 				} else {
// 					return [...prev, id];
// 				}
// 			});
// 			setIsAllSelected(false);
// 		}
// 	};

// 	const handleApplyFilters = () => {
// 		if (isAllSelected) {
// 			setCombinedState((prevState) => ({
// 				...prevState,
// 				category:
// 					categoryData?.catgeories?.map((item: any) => item?._id) ||
// 					[],
// 			}));
// 		} else {
// 			setCombinedState((prevState) => ({
// 				...prevState,
// 				category: mobileSelectedCategories,
// 			}));
// 		}
// 		setIsOpen(false);
// 		setCurrentPage(1);
// 	};

// 	// reset mobile filters
// 	const resetMobileFilters = () => {
// 		setMobileSelectedCategories([]);
// 		setIsAllSelected(true);
// 		setCombinedState((prevState) => ({
// 			...prevState,
// 			category:
// 				categoryData?.catgeories?.map((item: any) => item._id) || [],
// 			search: null,
// 			company: [],
// 		}));
// 		setPerPageLimit(10);
// 		setCurrentPage(1);
// 		setIsOpen(false);
// 	};

// 	const handlePageChange = (newPage: number) => {
// 		setCurrentPage(newPage);
// 		setCombinedState((prevState) => ({
// 			...prevState,
// 			page: newPage,
// 		}));
// 	};

// 	const totalPages = Math.ceil(
// 		(SearchProductsResponse?.total || 0) / itemsPerPage
// 	);

// 	const userDetails = useSelector(
// 		(state: RootState) => state.Login.ContractorData
// 	);

// 	const title =
// 		"Assigned Rewards |  Building Material Supplier | Chawla Ispat ";
// 	const description = "description";
// 	const baseUrl = chawlaispat;

// 	usePageMeta(title, description, baseUrl);

// 	if (isCategoryLoading || isProductLoading) {
// 		return (
// 			<div className="flex justify-center h-[50vh] items-center ">
// 				<RingLoader color="#5097A4" size={200} />
// 			</div>
// 		);
// 	}

// 	return (
// 		<div className="font-inter bg-white">
// 			{!userDetails ? (
// 				<div className="container mx-auto h-auto lg:p-3">
// 					<div className="w-full grid grid-cols-1 lg:grid-cols-2">
// 						<div className="w-full relative ">
// 							<div className="relative mt-4">
// 								<img
// 									src={reward}
// 									alt=""
// 									className="p-4 lg:p-0 relative z-30 w-full"
// 								/>
// 								<img
// 									src={img2}
// 									alt=""
// 									className="hidden lg:block absolute bottom-[-15px] left-0 z-10 w-44 xl:w-auto"
// 								/>
// 								<img
// 									src={img3}
// 									alt=""
// 									className="hidden lg:block absolute bottom-0 right-0 z-20 w-44 xl:w-auto"
// 								/>
// 							</div>
// 						</div>
// 						<div className="w-full h-[200px] relative lg:h-auto">
// 							<img
// 								src={img1}
// 								alt=""
// 								className="relative top-[-51.5px] z-[-1px] hidden lg:block"
// 							/>
// 							<div className="w-[95%] mx-auto p-3 lg:absolute top-[100px] xl:top-[150px] right-3 text-lg lg:text-xl xl:text-2xl lg:text-end lg:pr-4 2xl:right-14 font-bold lg:text-white">
// 								<p className="">
// 									Join with your account to unlock
// 								</p>{" "}
// 								<p>the rewards page. Please sign up or</p>{" "}
// 								<p className="mb-5">log in to continue.</p>
// 								<Link
// 									to="/signup"
// 									className="bg-[#FEBD69] text-black rounded-lg underline py-2 px-3 text-[14px]">
// 									Signup / Login{" "}
// 								</Link>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			) : (
// 				<div className="py-12 w-11/12 m-auto">
// 					<div className="flex justify-between">
// 						<div className="font-[700] text-[32px] leading-[38px]">
// 							Assigned Rewards
// 						</div>
// 					</div>
// 					{/*  desktop filters */}
// 					<div className=" hidden sm:flex gap-[24px] mt-10 mb-4">
// 						<span className="font-[600] text-[16px] leading-[20px]">
// 							Categories:
// 						</span>

// 						<div className="flex gap-[12px] overflow-x-auto font-[600] text-[16px] leading-[20px]">
// 							{categoryData?.catgeories.length > 0 && (
// 								<div key="all">
// 									<span
// 										className={`mr-4 cursor-pointer px-2 py-1 rounded ${isAllSelected ? "text-blue-300" : ""
// 											}`}
// 										onClick={() => handleClick("All")}>
// 										All
// 									</span>
// 								</div>
// 							)}
// 							{categoryData?.catgeories?.length > 0 ? (
// 								categoryData?.catgeories?.map(
// 									(item: { _id: string; name: string }) => (
// 										<div
// 											key={item?._id}
// 											className="whitespace-nowrap ">
// 											<span
// 												className={`mr-2 cursor-pointer px-2 py-1 rounded ${selectedCategory ===
// 													item._id
// 													? "text-blue-300"
// 													: combinedState.category?.includes(
// 														item._id
// 													)
// 														? ""
// 														: "bg-transparent"
// 													}`}
// 												onClick={() =>
// 													handleClick(item._id)
// 												}>
// 												{item.name}
// 											</span>
// 										</div>
// 									)
// 								)
// 							) : (
// 								<p>No categories available</p>
// 							)}
// 						</div>
// 					</div>
// 					{/* mobile filters */}
// 					<div className="flex sm:hidden gap-[24px] mt-10 mb-4">
// 						<span className="relative">
// 							<span
// 								className="font-[600] text-[16px] leading-[20px] flex items-center gap-1 relative cursor-pointer"
// 								onClick={toggleDropdown}>
// 								Categories:
// 								<FaAngleDown
// 									className={`transition-transform duration-300 ${isOpen ? "transform rotate-180" : ""
// 										}`}
// 								/>
// 								<span className="text-sm space-x-2 w-[200px] overflow-auto text-nowrap">
// 									{" "}
// 									{categoryData?.catgeories.length > 0 &&
// 										isAllSelected && "All"}
// 								</span>
// 							</span>

// 							{isOpen && (
// 								<div className="z-30 absolute top-8 right-1 bg-white rounded-lg shadow-lg border w-64 transition-transform duration-300">
// 									<span
// 										className="py-1 text-[25px] flex justify-end pr-3 mt-1 cursor-pointer relative"
// 										onClick={toggleDropdown}>
// 										<RxCross2 />
// 									</span>
// 									<ul className="h-48 w-56 px-3 rounded-lg pb-3 pt-1 overflow-y-auto text-sm text-gray-700 border-2 mb-1 mx-auto">
// 										{categoryData?.catgeories.length >
// 											0 && (
// 												<li>
// 													<div className="flex items-center ps-2 rounded">
// 														<input
// 															id="checkbox-item-13"
// 															type="checkbox"
// 															// defaultValue=""
// 															className="w-5 h-5 text-[#5097A4] rounded"
// 															checked={isAllSelected}
// 															onChange={() => {
// 																setIsAllSelected(
// 																	!isAllSelected
// 																);
// 																setMobileSelectedCategories(
// 																	[]
// 																);
// 															}}
// 														/>
// 														<label
// 															htmlFor="checkbox-item-13"
// 															className="w-full py-2 ms-2 text-sm font-medium text-gray-900">
// 															All
// 														</label>
// 													</div>
// 												</li>
// 											)}

// 										{categoryData?.catgeories?.length > 0
// 											? categoryData?.catgeories?.map(
// 												(item: {
// 													_id: string;
// 													name: string;
// 												}) => (
// 													<li>
// 														<div className="flex items-center ps-2 rounded">
// 															<input
// 																id="checkbox-item-13"
// 																type="checkbox"
// 																defaultValue=""
// 																className="w-4 h-4 text-[#5097A4] rounded"
// 																checked={mobileSelectedCategories.includes(
// 																	item._id
// 																)}
// 																onChange={() =>
// 																	handleMobileCheckboxChange(
// 																		item._id
// 																	)
// 																}
// 															/>
// 															<label
// 																htmlFor="checkbox-item-13"
// 																className="w-full py-2 ms-2 text-xs font-medium text-gray-900">
// 																{item?.name}
// 															</label>
// 														</div>
// 													</li>
// 												)
// 											)
// 											: <div className="h-full w-full flex justify-center items-center p-2"><span className="text-xs text-red-400  font-bold">No categories available</span></div>}
// 									</ul>
// 									<div className="flex w-full h-auto justify-center">
// 										<button
// 											onClick={handleApplyFilters}
// 											className="flex items-center bg-[#5097A4] px-4 py-1 text-white rounded-md mx-auto my-2 text-[13px]">
// 											Apply
// 										</button>
// 										<button
// 											onClick={resetMobileFilters}
// 											className="flex items-center bg-red-400 px-4 py-1 text-white rounded-md mx-auto my-2 text-[13px]">
// 											Reset
// 										</button>
// 									</div>
// 								</div>
// 							)}
// 						</span>
// 					</div>


// 					<div>
// 						{isProductLoading ? (
// 							<div className="flex justify-center">
// 								<RingLoader color="#5097A4" size={50} />
// 							</div>
// 						) : categoryData?.catgeories.length > 0 &&
// 							SearchProductsResponse?.products?.length > 0 ? (
// 							<>
// 								{SearchProductsResponse.products.map(
// 									(product: any) => (
// 										<div key={product?.id} className="mb-4">
// 											<CategoryRewardCard
// 												product={product}
// 											/>
// 										</div>
// 									)
// 								)}
// 								<div className="flex justify-center my-4">
// 									<button
// 										onClick={() =>
// 											handlePageChange(currentPage - 1)
// 										}
// 										disabled={currentPage === 1}
// 										className="px-4 py-2 mr-2 bg-blue-500 text-white rounded disabled:bg-gray-300">
// 										Previous
// 									</button>
// 									<span className="px-4 py-2">
// 										Page {currentPage} of {totalPages}
// 									</span>
// 									<button
// 										onClick={() =>
// 											handlePageChange(currentPage + 1)
// 										}
// 										disabled={currentPage === totalPages}
// 										className="px-4 py-2 ml-2 bg-blue-500 text-white rounded disabled:bg-gray-300">
// 										Next
// 									</button>
// 								</div>
// 							</>
// 						) : (
// 							<div className="w-full flex items-center flex-wrap justify-center gap-10 my-10 md:my-6">
// 								<div className="grid gap-4 w-60">
// 									<svg
// 										className="mx-auto"
// 										xmlns="http://www.w3.org/2000/svg"
// 										width={116}
// 										height={121}
// 										viewBox="0 0 116 121"
// 										fill="none">
// 										<path
// 											d="M0.206909 63.57C0.206909 31.7659 25.987 6.12817 57.6487 6.12817C89.2631 6.12817 115.079 31.7541 115.079 63.57C115.079 77.0648 110.43 89.4805 102.627 99.2755C91.8719 112.853 75.4363 121 57.6487 121C39.7426 121 23.4018 112.794 12.6582 99.2755C4.85538 89.4805 0.206909 77.0648 0.206909 63.57Z"
// 											fill="#5097A4"
// 										/>
// 										<path
// 											d="M72.7942 0.600875L72.7942 0.600762L72.7836 0.599331C72.3256 0.537722 71.8622 0.5 71.3948 0.5H22.1643C17.1256 0.5 13.0403 4.56385 13.0403 9.58544V107.286C13.0403 112.308 17.1256 116.372 22.1643 116.372H93.1214C98.1725 116.372 102.245 112.308 102.245 107.286V29.4482C102.245 28.7591 102.17 28.0815 102.019 27.4162L102.019 27.416C101.615 25.6459 100.67 24.0014 99.2941 22.7574C99.2939 22.7572 99.2937 22.757 99.2934 22.7568L77.5462 2.89705C77.5461 2.89692 77.5459 2.89679 77.5458 2.89665C76.2103 1.66765 74.5591 0.876968 72.7942 0.600875Z"
// 											fill="white"
// 											stroke="#5097A4"
// 										/>
// 										<circle
// 											cx="60.2069"
// 											cy={61}
// 											r="21.0256"
// 											fill="white"
// 										/>
// 										<path
// 											d="M74.6786 46.1412L74.6783 46.1409C66.5737 38.0485 53.4531 38.0481 45.36 46.1412C37.2552 54.2341 37.2551 67.3666 45.3597 75.4596C53.4529 83.5649 66.5739 83.5645 74.6786 75.4599C82.7716 67.3669 82.7716 54.2342 74.6786 46.1412ZM79.4694 41.3508C90.2101 52.0918 90.2101 69.5093 79.4694 80.2502C68.7166 90.9914 51.3104 90.9915 40.5576 80.2504C29.8166 69.5095 29.8166 52.0916 40.5576 41.3506C51.3104 30.6096 68.7166 30.6097 79.4694 41.3508Z"
// 											stroke="#5097A4"
// 										/>
// 										<path
// 											d="M83.2471 89.5237L76.8609 83.1309C78.9391 81.5058 80.8156 79.6106 82.345 77.6546L88.7306 84.0468L83.2471 89.5237Z"
// 											stroke="#5097A4"
// 										/>
// 										<path
// 											d="M104.591 94.4971L104.59 94.4969L92.7346 82.653C92.7342 82.6525 92.7337 82.652 92.7332 82.6515C91.6965 81.6018 90.0076 81.6058 88.9629 82.6505L89.3089 82.9965L88.9629 82.6505L81.8573 89.7561C80.8213 90.7921 80.8248 92.4783 81.8549 93.5229L81.8573 93.5253L93.7157 105.384C96.713 108.381 101.593 108.381 104.591 105.384C107.6 102.375 107.6 97.5062 104.591 94.4971Z"
// 											fill="#5097A4"
// 											stroke="#5097A4"
// 										/>
// 										<path
// 											d="M62.5493 65.6714C62.0645 65.6714 61.6626 65.2694 61.6626 64.7729C61.6626 62.7866 58.6595 62.7866 58.6595 64.7729C58.6595 65.2694 58.2576 65.6714 57.761 65.6714C57.2762 65.6714 56.8743 65.2694 56.8743 64.7729C56.8743 60.422 63.4478 60.4338 63.4478 64.7729C63.4478 65.2694 63.0458 65.6714 62.5493 65.6714Z"
// 											fill="#5097A4"
// 										/>
// 										<path
// 											d="M70.1752 58.0694H66.4628C65.9662 58.0694 65.5642 57.6675 65.5642 57.1709C65.5642 56.6862 65.9662 56.2842 66.4628 56.2842H70.1752C70.6717 56.2842 71.0737 56.6862 71.0737 57.1709C71.0737 57.6675 70.6717 58.0694 70.1752 58.0694Z"
// 											fill="#5097A4"
// 										/>
// 										<path
// 											d="M53.8596 58.0693H50.1472C49.6506 58.0693 49.2487 57.6673 49.2487 57.1708C49.2487 56.686 49.6506 56.2841 50.1472 56.2841H53.8596C54.3443 56.2841 54.7463 56.686 54.7463 57.1708C54.7463 57.6673 54.3443 58.0693 53.8596 58.0693Z"
// 											fill="#5097A4"
// 										/>
// 										<rect
// 											x="28.9248"
// 											y="16.3846"
// 											width="30.7692"
// 											height="2.05128"
// 											rx="1.02564"
// 											fill="#5097A4"
// 										/>
// 										<rect
// 											x="28.9248"
// 											y="100.487"
// 											width="41.0256"
// 											height="4.10256"
// 											rx="2.05128"
// 											fill="#5097A4"
// 										/>
// 										<rect
// 											x="28.9248"
// 											y="22.5385"
// 											width="10.2564"
// 											height="2.05128"
// 											rx="1.02564"
// 											fill="#5097A4"
// 										/>
// 										<circle
// 											cx="42.2582"
// 											cy="23.5641"
// 											r="1.53846"
// 											fill="#5097A4"
// 										/>
// 									</svg>

// 									<div>
// 										<h2 className="text-center text-black text-base font-semibold leading-relaxed pb-1">
// 											No category has been assigned to
// 											your account yet.
// 										</h2>
// 									</div>
// 								</div>
// 							</div>
// 						)}
// 					</div>
// 				</div>
// 			)}
// 		</div>
// 	);
// };
