import { useMemo, useRef } from "react";
import * as d3 from "d3";

type DataItem = {
  name: string;
  value: number;
};
type DonutChartProps = {
  width: number;
  height: number;
  data: DataItem[];
};

const MARGIN_X = 155;
const MARGIN_Y = 60;
const INFLEXION_PADDING = 25;

const colors = [
  // "#e0ac2b",
  "#69b3a2",
  // "#9a6fb0",
  "#e85252",
  // "#a53253",
];

export const DonutChart = ({ width, height, data }: DonutChartProps) => {
  const ref = useRef<SVGSVGElement | null>(null); 
  const radius = Math.min(width - 1.7 * MARGIN_X, height - 1 * MARGIN_Y) / 0.9;
  const innerRadius = radius / 3;

  const pie = useMemo(() => {
    const pieGenerator = d3.pie<any, DataItem>().value((d) => d.value);
    return pieGenerator(data);
  }, [data]);

  const arcGenerator = d3.arc();

  const shapes = pie.map((grp, i) => {
    const sliceInfo = {
      innerRadius,
      outerRadius: radius,
      startAngle: grp.startAngle,
      endAngle: grp.endAngle,
    };
    const centroid = arcGenerator.centroid(sliceInfo);
    const slicePath = arcGenerator(sliceInfo) ?? undefined;

    const inflexionInfo = {
      innerRadius: radius + INFLEXION_PADDING,
      outerRadius: radius + INFLEXION_PADDING,
      startAngle: grp.startAngle,
      endAngle: grp.endAngle,
    };
    const inflexionPoint = arcGenerator.centroid(inflexionInfo);

    const isRightLabel = inflexionPoint[0] > 0;
    const labelPosX = inflexionPoint[0] + 10 * (isRightLabel ? 1 : -1);
    const textAnchor = isRightLabel ? "start" : "end";
    const label = grp.data.name + " (" + grp.value + ")";

    return (
      <g
        key={i}
        className="slice"
        onMouseEnter={() => {
          if (ref.current) {
            ref.current.classList.add("hasHighlight")
          }
        }}
        onMouseLeave={() => {
          if (ref.current) {
            ref.current.classList.remove("hasHighlight");
          }
        }}
      >
        <path d={slicePath} fill={colors[i]} />
        <circle cx={centroid[0]} cy={centroid[1]} r={3} />
        <line
          x1={centroid[0]}
          y1={centroid[1]}
          x2={inflexionPoint[0]}
          y2={inflexionPoint[1]}
          stroke={"#9a6fb0"}
          fill={"#9a6fb0"}
          textLength={3}
        />
        <line
          x1={inflexionPoint[0]}
          y1={inflexionPoint[1]}
          x2={labelPosX}
          y2={inflexionPoint[1]}
          stroke={"blue"}
          fill={"blue"}
        />
        <text
          x={labelPosX + (isRightLabel ? 2 : -2)}
          y={inflexionPoint[1]}
          textAnchor={textAnchor}
          dominantBaseline="middle"
          fontSize={12}
        >
          {label}
        </text>
      </g>
    );
  });

  return (
    <div className="container flex justify-center ">
      <svg ref={ref}  width={width+60} height={height}>
        <g transform={`translate(${width / 1.72}, ${height / 2})`}>{shapes}</g>
      </svg>
    </div>
  );
};
