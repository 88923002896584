import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginResponse } from '../Redux/reducer/LoginReducer';
import Cookies from 'js-cookie';
import { useLogoutApiMutation } from '../Redux/Api/Contractor';
import { useEffect } from 'react';
import { api } from '../Redux/Api/rtkAPI';
// Import your API slice here

const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [LogoutCall, { data: logoutData, isError }] = useLogoutApiMutation();

    useEffect(() => {
        if (logoutData && logoutData?.success) {
            localStorage.removeItem('jwt');
            Cookies.remove('jwt');

            dispatch(LoginResponse(null));
            dispatch(api.util.invalidateTags(["Contractor", "NavCount"]));

            // navigate('/signup');
        }

        if (isError) {
            console.error("Logout failed");
        }
    }, [logoutData, isError, dispatch, navigate]);

    const handleLogout = async () => {
        try {
            await LogoutCall().unwrap().then(()=>{
                    document.cookie.split(";").forEach((cookie) => {
                        const name = cookie.split("=")[0].trim();
                        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                    });
            }).then(()=>{
                // document.location.reload()
            })

            // if (result.success) {
            //     localStorage.removeItem('jwt');
            //     Cookies.remove('jwt');

            //     dispatch(LoginResponse(null));
            //     dispatch(api.util.invalidateTags(["Contractor", "NavCount"]));

            //     navigate('/signup');
            // }
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    return { handleLogout };
};

export default useLogout;
