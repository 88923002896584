import Sliders from "./Slidder";
import { Certificates } from "./Certificates";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";


import OurClients from "./components/OurClients";
import AboutSection from "./components/AboutSection";

const AboutUs = () => {
  // const [isFlipped, setIsFlipped] = useState<boolean>(false);
  // const navigate = useNavigate();

  // const handleMouseEnter = () => {
  //   setIsFlipped(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsFlipped(false);
  // };

  const title =
    "About Us | Building Material Supplier | Chawla Ispat";
  const description =
    "Learn more about Chawla Ispat and our mission, values, and services.";
  const baseUrl = chawlaispat;

  usePageMeta(title, description, baseUrl);

  return (
    <div className="font-inter bg-white mb-4">
      <div className="flex flex-col gap-[36px] w-[90%] mx-auto  ">
        <div className="px-auto  py-[40px] flex flex-col items-center gap-[20px] ">
          <div className="flex flex-col gap-[24px] items-center my-2 sm:my-4">
            <h1
              className="font-[700]  text-[40px] leading-[38px] text-[#5097A4]"
              style={{
                textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}>
              About Us
            </h1>
            <h2 className="font-[500] text-[16px]  lg:text-[22px]  text-slate-600 w-full lg:w-[80%] mx-auto text-center ">
              We offer instant online quotes, payment and ordering
              for high-quality, high-, custom metal parts –
              straight from our factory.
            </h2>
          </div>
          <AboutSection />
          <OurClients />
        </div>


        {/* <div className="px-auto  py-[40px] flex flex-col items-center gap-[48px] ">
          <div className="flex flex-col gap-[24px] items-center">
            <h1 className="font-[700]  text-[32px] leading-[38px] ">
              About Us
            </h1>
            <h2 className="font-[500]  text-[16px] leading-[19px] text-slate-600 ">
              We offer instant online quotes, payment and ordering
              for high-quality, high-, custom metal parts –
              straight from our factory.
            </h2>
          </div>
        </div> */}


        {/* <div className="hidden w-full md:flex flex-col gap-[48px]">
          <h1 className="font-[700] text-[38px] md:text-[48px]  leading-[58px]">
            Our Clients
          </h1>

          <div className="flex flex-col sm:flex-row sm:flex-wrap justify-between gap-[23px]">
            <div className="group w-[338px] md:w-[397px] h-[360px]  rounded-[8px] justify-center">
              <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                <div className="absolute inset-0">
                  <img
                    src={tata}
                    alt="tata"
                    className="opacity-100 group-hover:opacity-0 border rounded-[8px]"
                  />
                  <div className="px-[25px] py-[30px] flex flex-col gap-[8px] opacity-100 group-hover:opacity-0">
                    <span className="font-[600] text-[28px] leading-[33px]">
                      Tata
                    </span>
                    <p className="font-[400] text-[12px] leading-[16px]">
                      "We have been working with the
                      Chawla Auto Components since its
                      launch and have been very impressed
                      with the quality of the fabrication,
                      the lead times and ease of use of
                      the platform.""
                    </p>
                  </div>
                </div>
                <div className="absolute inset-0 h-full w-full rounded-xl bg-[#5097A4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                  <p className="font-[500] text-[22px] leading-[27px] flex  items-center text-white px-[15px] py-[15px] h-full ">
                    "We have been working with the Chawla
                    Auto Components since its launch and
                    have been very impressed with the
                    quality of the fabrication, the lead
                    times and ease of use of the platform."{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="group w-[338px] md:w-[397px] h-[360px]  rounded-[8px] justify-center">
              <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                <div className="absolute inset-0">
                  <img
                    src={haier}
                    alt="haier"
                    className="opacity-100 group-hover:opacity-0 border rounded-[8px]"
                  />
                  <div className="px-[25px] py-[30px] flex flex-col gap-[8px] opacity-100 group-hover:opacity-0">
                    <span className="font-[600] text-[28px] leading-[33px]">
                      Haeir
                    </span>
                    <p className="font-[400] text-[12px] leading-[16px]">
                      "It's amazing you can engineer any
                      special items for us so quickly it
                      is even faster than writing a few
                      words on a piece of paper! Thank you
                      for your support!"{" "}
                    </p>
                  </div>
                </div>
                <div className="absolute inset-0 h-full w-full rounded-xl bg-[#5097A4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                  <p className="font-[500] text-[22px] leading-[27px] flex  items-center text-white px-[15px] py-[15px] h-full  ">
                    "It's amazing you can engineer any
                    special items for us so quickly it is
                    even faster than writing a few words on
                    a piece of paper! Thank you for your
                    support!"{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="group w-[338px] md:w-[397px] h-[360px]  rounded-[8px] justify-center">
              <div className="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                <div className="absolute inset-0">
                  <img
                    src={voltas}
                    alt="voltas"
                    className="opacity-100 group-hover:opacity-0 border rounded-[8px]"
                  />
                  <div className="px-[25px] py-[30px] flex flex-col gap-[8px] opacity-100 group-hover:opacity-0">
                    <span className="font-[600] text-[28px] leading-[33px]">
                      Voltas
                    </span>
                    <p className="font-[400] text-[12px] leading-[16px]">
                      "Best contract manufacturing in
                      India, professional and diligent
                      management. Feel safe and taken care
                      bt it's CEO's experience in business
                      and integrity. Chawla Auto
                      Components is a secured bet."{" "}
                    </p>
                  </div>
                </div>
                <div className="absolute inset-0 h-full w-full rounded-xl bg-[#5097A4] px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
                  <p className="font-[500] text-[22px] leading-[27px] flex justify-center  items-center text-white h-full   ">
                    "Best contract manufacturing in India,
                    professional and diligent management.
                    Feel safe and taken care bt it's CEO's
                    experience in business and integrity.
                    Chawla Auto Components is a secured
                    bet."{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* 
      <div className="my-[120px] mx-auto md:px-[104px] w-4/5 md:w-full flex justify-center items-center ">
        <Sliders />
      </div> */}

      {/* <div className="mx-auto px-[10px] md:w-full flex justify-center md:px-[104px]">
        <Certificates />
      
      </div> */}
      {/* <Certificates/> */}
      <div className="my-[30px] md:my-[50px] mx-auto px-[30px] w-full md:w-full flex justify-center items-center ">
        <Sliders />
      </div>
      <div className="mx-auto px-[10px] md:w-full flex justify-center ">
        <Certificates />
      </div>
    </div>
  );
};

export default AboutUs;
