import NoResult from "./Noresult";
import { useNavigate } from "react-router-dom";
import { SearchBoxProps } from "./types";
import { useDispatch } from "react-redux";
import { clearSubResults, setSubSearch } from "../../Redux/reducer/Filter";
import { MdOutlineSearch } from "react-icons/md";
import { GoArrowUpLeft } from "react-icons/go";

const SearchBox = ({
  searchResults,
  isLoading,
  close,
  search,
  onGlassClick,
}: SearchBoxProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (name: string) => {
    navigate("/subItems");
    dispatch(clearSubResults());
    dispatch(setSubSearch(name));
    close();
  };

  const handleItemClick = (index: number, name: string) => {
    if ([0, 1, 2].includes(index)) {
      onGlassClick();
    } else if (index >= 3) {
      handleClick(name);
    }
  };

  const renderIcon = (index: number) => {
    return index >= 3 ? (
      <GoArrowUpLeft fontSize="1.10rem" color="black" />
    ) : (
      <MdOutlineSearch fontSize="1.10rem" color="#5097A4" />
    );
  };

  const highlightSearchTerm = (text: string, searchTerm: string) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    let matchCount = 0;

    return text.split(regex).map((part, index) => {
      if (part.toLowerCase() === searchTerm.toLowerCase() && matchCount < 3) {
        matchCount++;
        return (
          <span key={index} className="bg-[#5097A4] text-white">
            {part}
          </span>
        );
      }
      return <span key={index} className="text-black">{part}</span>;
    });
  };

  return (
    <>
      <div className="z-50 bg-white w-full flex flex-col gap-4 border px-4 py-2">
        <div className="w-full flex flex-col gap-4">
          <div className="w-full h-40 overflow-auto">
            <div className="w-full flex flex-col overflow-y-auto font-bold text-white">
              {search && (
                <div
                  className="hover:bg-gray-100 cursor-pointer text-xs px-2 py-1 rounded-md mr-3 border mb-[2px]"
                  onClick={onGlassClick}>
                  <div className="flex items-center justify-between">
                    <div className="flex-1">
                      <span className="font-inter font-normal leading-5 tracking-wider">
                        <span className=" font-bold  bg-[#5097A4] text-white tracking-wider">
                           {search}
                        </span>
                      </span>
                    </div>
                    <MdOutlineSearch
                      fontSize="1.10rem"
                      color="#5097A4"
                    />
                  </div>
                </div>
              )}

              {searchResults?.length ? (
                <ul className="space-y-1">
                  {searchResults.map(
                    (searchResult, index) => (
                      <div
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer text-xs px-2 py-1 rounded-md mr-3 transition-all duration-200"
                        onClick={() =>
                          handleItemClick(
                            index,
                            searchResult?.name
                          )
                        }>
                        <div className="flex items-center justify-between">
                          <div className="flex-1">
                            <span className="font-inter font-normal text-xs leading-5 tracking-wide">
                              {[0, 1, 2].includes(
                                index
                              )
                                ? highlightSearchTerm(
                                  searchResult.itemName ||
                                  "",
                                  search
                                )
                                :
                                <span className="text-black">{searchResult.name
                                }</span>
                              }
                            </span>
                          </div>
                          {renderIcon(index)}
                        </div>
                      </div>
                    )
                  )}
                </ul>
              ) : isLoading ? (
                <div className="flex items-center justify-center h-full pt-10">
                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                <NoResult />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBox;

// import { useState, useEffect } from "react";

// import { SearchResult } from "../../types/types";
// import CategoriesCard from "./CategoriesCard";
// import NoResult from "./Noresult";
// import { useNavigate } from "react-router-dom";
// import { SearchBoxProps } from "./types";
// import { useDispatch } from "react-redux";
// import { clearSubResults, setSubSearch } from "../../Redux/reducer/Filter";

// const SearchBox = ({ searchResults, isLoading,close }: SearchBoxProps) => {
//   const [showSearchResult, setShowSearchResult] = useState(true);
//   const navigate = useNavigate();
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//   const dispatch = useDispatch()

//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };
//    window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleClick = (name: string) => {
//       navigate("/subItems")
//       dispatch(clearSubResults())
//       dispatch(setSubSearch(name))

//       close()
//   }

//   return (
//     <>
//       <div className="z-50 bg-white w-full flex flex-col gap-4 border px-4 py-2">
//         <div className="w-full flex flex-col gap-4">
//           <div className="w-full h-40 overflow-auto">
//             <div className="w-full flex flex-col overflow-y-auto">

//               {searchResults?.length ? (
//                 <ul>
//                   {searchResults.map((searchResult, index) => (
//                     <div key={index} className="hover:bg-gray-100 cursor-pointer" onClick={() => handleClick(searchResult?.name)}>
//                       <div className="flex items-center">
//                         <div className="flex-1">
//                           <span className="font-inter font-normal text-xs leading-5">{searchResult?.name}</span>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </ul>
//               ) : isLoading ? (
//                 <div className="flex items-center justify-center h-full pt-20">
//                   <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
//                 </div>
//               ) : (
//                 <NoResult />
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SearchBox;
