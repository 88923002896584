import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

const DeletedCheck: React.FC = () => {
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);

  // Check if the user is marked as deleted
  if (userDetails && userDetails) {
    return <Outlet />; // Render the component specified in the route if deleted is true
  }

  return <Navigate to="/" replace />; // Redirect to home if not deleted
};

export default DeletedCheck;
